import React from "react";
import { useDrag } from "react-dnd";
import ItemTypes from "./ItemTypes";
import { BoxLable } from "./Tote";
import { styled } from "styled-components";

const SmallBoxContainer = styled.div`
cursor: move;
width: 50px;
height: 20px;
background-color: #3498db;
margin: 2px;
display: flex;
align-items: center;
justify-content: center;
`

const SmallBox = ({smallBox}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.SMALL_BOX,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  return (
    <SmallBoxContainer
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <BoxLable>{smallBox}</BoxLable>
    </SmallBoxContainer>
  );
}

export default SmallBox;
