import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert'
import { InputField } from '../../component/common/Input/Input.jsx'
import { SelectField } from '../../component/common/Input/Select'
import { PrimaryButton } from '../../component/common/Button/Button.jsx'
import { AuthLayout } from '../../component/common/Layout/AuthLayout.jsx'
import { ResponseModal } from '../../component/common/Modal/ResponseModal.jsx'
import { register } from '../../features/auth/authAction'
import { languages } from '../../constants/languageConstants.js'
import { Loader } from '../common/Loader/Loader'
import './login.scss';
import { urls } from 'utils/urls'

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalProps, setModalProps] = useState(null)
    const methods = useForm();
    const [registerError, setRegisterError] = useState(null);
    const [formErrors, setFormErrors] = useState(null);
    const { userRegisterError, isRegistered, isRegisterUserLoading } = useSelector(
        (state) => state?.auth
    )

    useEffect(() => {
        if (userRegisterError) {
            if (typeof (userRegisterError) === 'string') {
                setRegisterError(userRegisterError);
            }
            else {
                setFormErrors(userRegisterError)
            }
        }
    }, [userRegisterError]);

    useEffect(() => {
        if (isRegistered) {
            methods.reset();
            setModalProps(
                {
                    title: 'Successfully registered!',
                    message: 'Please login to continue.',
                    open: true
                }
            )

            const redirectTimer = setTimeout(() => {
                navigate(urls?.LOGIN_URL); // redirect to login
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        };

    }, [isRegistered]);

    const onSubmit = (data) => {
        setFormErrors(null)
        setRegisterError(null)
        data.activated = true
        dispatch(register(data));
    }

    return (
        <AuthLayout>
            <Grid item xs={6} className='loginForm'>
                <div className='loginFormmWrap'>
                    <Box>
                        <h1>Register</h1>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <InputField name="login" placeholder={'Username'} formErrors={formErrors} maxLength={50} />
                                <InputField name="email" type={"email"} placeholder={'Email'} formErrors={formErrors} maxLength={254} />
                                <InputField name="password" type="password" placeholder={'Password'} formErrors={formErrors} maxLength={60} />
                                <SelectField name="language" data-testid='language-select' placeholder={'Select Language'} formErrors={formErrors} options={languages} />
                                <div className="forgetLink">
                                    <span className="reg-link">Already have account? <a href='/login'>Login</a></span>
                                    {/* <span className='fp-link'><a href='/forget'>Forgot Password?</a></span> */}
                                </div>
                                <div className='btnModule'>
                                    <PrimaryButton type="submit" label={'Register'} data-testid='register-btn' />
                                </div>
                            </form>
                            {registerError ? <><br/><Alert severity="error">{registerError}</Alert></> : null}
                        </FormProvider>
                        <div className='loginSupport'>
                            <p>If you are having trouble please contact<br></br>
                                <a href="mailto:support@koorier.ca">support@koorier.ca</a>
                            </p>
                        </div>
                    </Box>
                </div>
            </Grid>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {isRegisterUserLoading ? <Loader isLoading={isRegisterUserLoading} /> : null}
        </AuthLayout>
    )
}