import * as React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import {sortDataByRowNumber} from 'utils/manifestHelper'
import 'component/dataTable/dataTable.scss'

const Title = styled(Typography)`
    &&{
        font-family: "degular", sans-serif;
        font-size:1rem;
        font-weight: 400;
    }
`

const ErrorList = ({ errorCount }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' , margin: '1rem'
    }}>
            {errorCount?.map((error, index) => (
                <Title key={index} variant="body1">
                    &bull; {`${error.Message} - ${error.ErrorCount} record`}
                </Title>
            ))}
        </div>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManifestUploadErrorDialog({ isOpen, errorDetails,  handlePopUpClose}) {
    const [open, setOpen] = React.useState(isOpen);
    const sortedData = sortDataByRowNumber(errorDetails?.detail)

    const handleClose = () => {
        setOpen(false);
        handlePopUpClose()
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative'}}>
                    <Toolbar sx={{ height: '40px !important'}}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" dataTestId ='manifest-upload-error-summary'>
                            Manifest Upload Error Summary
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ErrorList errorCount={errorDetails?.errorCount} />
                <TableContainer component={Paper} className='dataTable' sx={{ maxHeight: '78vh' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '10%' }} className="table-head-cell" dataTestId ='tracking-number'>Tracking Number</TableCell>
                                <TableCell style={{ width: '10%' }} className="table-head-cell" dataTestId ='row-number'>Row Number</TableCell>
                                <TableCell style={{ width: 'auto' }} className="table-head-cell" dataTestId ='error-details'>Error</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((item, index) => (
                                <React.Fragment key={index}>
                                    <TableRow key={item?.TrackingNumber} dataTestId ={`row-${index}`}>
                                        <TableCell className="table-row-cell" dataTestId ={`tracking-number-${index}`}>{item?.TrackingNumber}</TableCell>
                                        <TableCell className="table-row-cell" dataTestId ={`row-number-${index}`}>{item?.RowNumber}</TableCell>
                                        <TableCell className="table-row-cell" dataTestId ={`error-details-${index}`}>{item?.Message}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Dialog>
        </React.Fragment>
    );
}