import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { firebaseConfig, vapidKey } from 'config/firebase';
import { appendMessage } from 'features/messaging/messageAction';

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const initializeFirebaseMessaging = (dispatch) => {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            getFCMToken();
            setupMessageListener(dispatch);
        } else {
            console.log('Notification permission denied.');
        }
    });
};

export const getFCMToken = () => {
    getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
        if (currentToken) {
            localStorage.setItem('fcmToken', currentToken)
        } else {
            console.log('No FCM token available.');
        }
    }).catch((err) => {
        console.error('Error getting FCM token:', err);
    });
};

export const setupMessageListener = (dispatch) => {
    onMessage(messaging, (payload) => {
        dispatch(appendMessage(payload?.notification))
    });

    // Listen for messages from the service worker
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data && event.data.type === 'BACKGROUND_NOTIFICATION') {
                dispatch(appendMessage(event?.data?.payload?.notification));
            }
        });
    }
};

export default messaging;
