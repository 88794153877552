export const routeStatusEnum = {
    ROUTE_CREATED: 'ROUTE_CREATED',
    ROUTE_ASSIGNED: 'ROUTE_ASSIGNED',
    DRIVER_ACCEPTED: 'DRIVER_ACCEPTED',
    DRIVER_DECLINED: 'DRIVER_DECLINED',
    ROUTE_SCANNED: 'ROUTE_SCANNED',
    ROUTE_LOADED: 'ROUTE_LOADED',
    ROUTE_STARTED: 'ROUTE_STARTED',
    ROUTE_COMPLETE: 'ROUTE_COMPLETE',
    ROUTE_HALTED: 'ROUTE_HALTED',
    ROUTE_BREAKDOWN: 'ROUTE_BREAKDOWN',
    RESCUE_ROUTE_SUCCESS: 'RESCUE_ROUTE_SUCCESS',
    ROUTE_REJECTED: 'ROUTE_REJECTED',
    SYSTEM_ROUTE_REJECTED: 'SYSTEM_ROUTE_REJECTED',
    ROUTE_ON_BREAK: 'ROUTE_ON_BREAK',
    ROUTE_TERMINATED: 'ROUTE_TERMINATED',
    ROUTE_PAUSED:'ROUTE_PAUSED',
    RESCUE_ROUTE_LOADED : 'RESCUE_ROUTE_LOADED'
}

export const batchStatusEnum = {
    BATCH_SYSTEM_CREATED: 'BATCH_SYSTEM_CREATED',
    BATCH_MANUAL_CREATED: 'BATCH_MANUAL_CREATED',
    BATCH_SUPERVISOR_ACCEPTED: 'BATCH_SUPERVISOR_ACCEPTED',
    BATCH_SUPERVISOR_REJECTED: 'BATCH_SUPERVISOR_REJECTED',
    ROUTE_CREATION_INPROGRESS: 'ROUTE_CREATION_INPROGRESS',
    SEQUENCING_FAILED: 'SEQUENCING_FAILED',
    SEQUENCING_INPROGRESS: 'SEQUENCING_INPROGRESS',
    SYSTEM_AUTO_REJECTED: 'SYSTEM_AUTO_REJECTED'
}

export const routeStopStatusEnum = {
    ROUTE_STOP_SYSTEM_CREATED :'ROUTE_STOP_SYSTEM_CREATED',
    RESCUE_STOP_CREATED:'RESCUE_STOP_CREATED',
    ROUTE_STOP_APPROACHING :'ROUTE_STOP_APPROACHING',
    ROUTE_STOP_SERVICE:'ROUTE_STOP_SERVICE',
    ROUTE_STOP_SERVED:'ROUTE_STOP_SERVED',
    RESCUE_STOP_SERVED:'RESCUE_STOP_SERVED',
    ROUTE_STOP_SKIPPED: 'ROUTE_STOP_SKIPPED',
    ROUTE_STOP_CANCELLED: 'ROUTE_STOP_CANCELLED',
    ROUTE_STOP_BREAKDOWN: 'ROUTE_STOP_BREAKDOWN'
}

export const terminateRouteReason = {
    PARCEL_DEL_UNSC_ATT_MISSORT: 'PARCEL_DEL_UNSC_ATT_MISSORT',
    PARCEL_DEL_UNSC_ATT_CANCEL:'PARCEL_DEL_UNSC_ATT_CANCEL',
    PARCEL_DEL_UNSC_ATT_TIME:'PARCEL_DEL_UNSC_ATT_TIME'
}

export const routeStatus = [
    {
        label: 'Route Created',
        value: routeStatusEnum?.ROUTE_CREATED
    },
    {
        label: 'Route Assigned',
        value: routeStatusEnum?.ROUTE_ASSIGNED
    },
    {
        label: 'Driver Accepted',
        value: routeStatusEnum?.DRIVER_ACCEPTED
    },
    {
        label: 'Driver Declined',
        value: routeStatusEnum?.DRIVER_DECLINED
    },
    {
        label: 'Route Scanned',
        value: routeStatusEnum?.ROUTE_SCANNED
    },
    {
        label: 'Route Loaded',
        value: routeStatusEnum?.ROUTE_LOADED
    },
    {
        label: 'Route Started',
        value: routeStatusEnum?.ROUTE_STARTED
    },
    {
        label: 'Route Complete',
        value: routeStatusEnum?.ROUTE_COMPLETE
    },
    {
        label: 'Route Halted',
        value: routeStatusEnum?.ROUTE_HALTED
    },
    {
        label: 'Route Breakdown',
        value: routeStatusEnum?.ROUTE_BREAKDOWN
    },
    {
        label: 'Rescue Route Success',
        value: routeStatusEnum?.RESCUE_ROUTE_SUCCESS
    },
    {
        label: 'System Route Rejected',
        value: routeStatusEnum?.SYSTEM_ROUTE_REJECTED
    },
    {
        label: 'Route on break',
        value: routeStatusEnum?.ROUTE_ON_BREAK
    },
    {
        label: 'Route Terminated',
        value : routeStatusEnum?.ROUTE_TERMINATED
    },
    {
        label: 'Route Rejected',
        value: routeStatusEnum?.ROUTE_REJECTED
    },
    {
        label: 'Route Paused',
        value: routeStatusEnum?.ROUTE_PAUSED
    },
    {
        label: 'Rescue Route Loaded',
        value: routeStatusEnum?.RESCUE_ROUTE_LOADED
    },
]

export const batchStatus = [
    {
        label: 'Batch System Created',
        value: batchStatusEnum?.BATCH_SYSTEM_CREATED
    },
    {
        label: 'Batch Manual Created',
        value: batchStatusEnum?.BATCH_MANUAL_CREATED
    },
    {
        label: 'Batch Supervisor Accepted',
        value: batchStatusEnum?.BATCH_SUPERVISOR_ACCEPTED
    },
    {
        label: 'Batch Supervisor Rejected',
        value: batchStatusEnum?.BATCH_SUPERVISOR_REJECTED
    },
    {
        label: 'Route Creation InProgress',
        value: batchStatusEnum?.ROUTE_CREATION_INPROGRESS
    },
    {
        label: 'System Auto Rejected',
        value: batchStatusEnum?.SYSTEM_AUTO_REJECTED
    },
    {
        label: 'Sequencing Failed',
        value: batchStatusEnum?.SEQUENCING_FAILED
    },
    {
        label: 'Sequencing InProgress',
        value: batchStatusEnum?.SEQUENCING_INPROGRESS
    }
]

export const routeStopStatus = [
    {
        label: 'System Created',
        value: routeStopStatusEnum?.ROUTE_STOP_SYSTEM_CREATED
    },
    {
        label: 'Rescue Stop Created',
        value: routeStopStatusEnum?.RESCUE_STOP_CREATED
    },
    {
        label: 'Approaching',
        value: routeStopStatusEnum?.ROUTE_STOP_APPROACHING
    },
    {
        label: 'Service',
        value: routeStopStatusEnum?.ROUTE_STOP_SERVICE
    },
    {
        label: 'Served',
        value: routeStopStatusEnum?.ROUTE_STOP_SERVED
    },
    {
        label: 'Rescue Stop Served',
        value: routeStopStatusEnum?.RESCUE_STOP_SERVED
    },
    {
        label: 'Skipped',
        value: routeStopStatusEnum?.ROUTE_STOP_SKIPPED
    },
    {
        label: 'Breakdown',
        value: routeStopStatusEnum?.ROUTE_STOP_BREAKDOWN
    }
]

export const routeTerminateStatus = [
    {
        label : "Could Not Attempt - Route Cancelled",
        value : terminateRouteReason?.PARCEL_DEL_UNSC_ATT_CANCEL
    },
    {
        label: "Could Not Attempt - Missorted",
        value : terminateRouteReason?.PARCEL_DEL_UNSC_ATT_MISSORT
    },
    {
        label: "Could Not Attempt - Route Service Time Expired",
        value : terminateRouteReason?.PARCEL_DEL_UNSC_ATT_TIME
    }
]

export const routeStatusToNotAllowedToReject = [routeStatusEnum?.ROUTE_STARTED, routeStatusEnum?.ROUTE_COMPLETE, routeStatusEnum?.ROUTE_HALTED, routeStatusEnum?.ROUTE_BREAKDOWN, routeStatusEnum?.ROUTE_SCANNED, routeStatusEnum?.ROUTE_LOADED, routeStatusEnum?.ROUTE_ASSIGNED, routeStatusEnum?.RESCUE_ROUTE_SUCCESS, routeStatusEnum?.ROUTE_REJECTED, routeStatusEnum?.SYSTEM_ROUTE_REJECTED, routeStatusEnum?.ROUTE_TERMINATED, routeStatusEnum?.ROUTE_ON_BREAK,routeStatusEnum?.RESCUE_ROUTE_LOADED,routeStatusEnum?.ROUTE_PAUSED]

export const sosRoutes = [routeStatusEnum?.ROUTE_HALTED, routeStatusEnum?.ROUTE_BREAKDOWN]

export const allowRouteShortage = [routeStatusEnum?.ROUTE_CREATED, routeStatusEnum?.ROUTE_ASSIGNED]