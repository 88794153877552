import { useState, useEffect } from 'react';

const useFieldChange = (initialValues = {}) => {
    const [formValues, setFormValues] = useState(initialValues);

    // Update form values when initialValues changes
    useEffect(() => {
        if (initialValues) {
            setFormValues(initialValues);
        }
    }, [initialValues]);

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    return [formValues, setFormValues, handleFieldChange];
};

export default useFieldChange;
