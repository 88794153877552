export const getPackagesWithErrorCount = (data) => {
    const count = data?.PARSE_ERROR_MANIFEST_STREET_PERFECT?.length || 0
    return count
}

export const getPackagesStatusCount = (data) => {
    let count = 0
    Object.entries(data)?.forEach(([key, value]) => {
        count += value
    })
    return count
}

export const getPhysicalArrivalStatus = (count, totalCount) => {
    return count === totalCount ? 'Arrived' : count === 0 ? 'Yet To Arrive' : count < totalCount ? 'Partially Arrived' : 'Not Arrived'
}

export const getRoutingStatus = (count, totalCount) => {
    return count === totalCount ? 'Routed' : count === 0 ? 'Yet To Be routed' : count < totalCount ? 'Partially Routed' : 'Not Routed'
}

export const getStatusStyle = (status) => {
    if (status === 'Routed') {
        return {
            backgroundColor: `#888888`
        }
    }
    return null
}

export const sortDataByRowNumber = (data) => {
    data.sort((a, b) => {
        const rowA = parseInt(a?.RowNumber?.replace('Row ', ''), 10);
        const rowB = parseInt(b?.RowNumber?.replace('Row ', ''), 10);
        return rowA - rowB;
    })
    return data
}
