import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import { getCurrentDate } from 'utils/common'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class DailyRoasterService {
      getDailyRoasters(formData) {
        const date = getCurrentDate()
        const endpoint = formData?.id ? `${apiEndpoint}/api/daily-roasters/${formData?.id}` : `${apiEndpoint}/api/daily-roasters?startDate=${date}`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: formData
        });
    }

    addRoaster(formData) {
        const endpoint = `${apiEndpoint}/api/daily-roasters/duty/add`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    updateRoaster(formData) {
        const {routeId, driverId, ...rest} = formData
        const endpoint = `${apiEndpoint}/api/daily-roasters/${routeId}/driver/${driverId}`;
        return api.put(endpoint, null, {
            headers: getHeader(),
            params: rest
        });
    }

    getDailyRoasterRoutes(driverId) {
        const endpoint = `${apiEndpoint}/api/daily-routes/created/${driverId}`; 
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }
}
