import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ChartWrapper } from './Common';

const TableChart = ({ data, title, link, icon, columns, marginBottom }) => {
    return (
        <div>
            <ChartWrapper title={title} link={link} icon={icon} marginBottom={marginBottom}/>
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCell key={column} style={{ backgroundColor: '#f5f5f5', fontWeight: 700 }}
                                >{column}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, index) => (
                            <TableRow key={index}>
                                {columns.map((column) => (
                                    <TableCell key={column}>{row[column]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TableChart;
