import { createSlice } from '@reduxjs/toolkit';
import { fetchUsers, addUser, fetchUserDetails, updateUser, fetchDataCenters, fetchTenants, updateProfile, fetchProfile } from './userManagementAction'
import { logout } from 'features/auth/authAction'

const initialState = {
    users: [],
    loading: false,
    error: null,
    addUserSuccess: false,
    userDetails: null,
    dataCenters: [],
    tenants: [],
    userCount: 0,
    addedUser: null,
    profileDetails: null
}
// Redux slice for dashboard
const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        resetUserData: (state) => {
            state.addUserSuccess = false;
            state.userDetails = null;
            state.error = null
            state.addedUser = null
            // state.profileDetails = null
        }
    },
    extraReducers: (builder) => {
        builder
            // Users Slice
            .addCase(fetchUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.users = action?.payload?.users || []
                state.userCount = action?.payload?.totalCount
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // add user Slice
            .addCase(addUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.addUserSuccess = true
                state.addedUser = action?.payload
            })
            .addCase(addUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // fetch userdetails
            .addCase(fetchUserDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.userDetails = action?.payload
            })
            .addCase(fetchUserDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // update user Slice
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.addUserSuccess = true
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // data center slice
            .addCase(fetchDataCenters.pending, (state) => {
                // state.loading = true;
                state.error = null;
            })
            .addCase(fetchDataCenters.fulfilled, (state, action) => {
                // state.loading = false;
                state.error = null;
                state.dataCenters = action?.payload
            })
            .addCase(fetchDataCenters.rejected, (state, action) => {
                // state.loading = false;
                state.error = action?.payload;
            })

            // tenants slice
            .addCase(fetchTenants.pending, (state) => {
                // state.loading = true;
                state.error = null;
            })
            .addCase(fetchTenants.fulfilled, (state, action) => {
                // state.loading = false;
                state.error = null;
                state.tenants = action?.payload
            })
            .addCase(fetchTenants.rejected, (state, action) => {
                // state.loading = false;
                state.error = action?.payload;
            })

            // Update Profile
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProfile.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.addUserSuccess = true
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // Fetch Profile
            .addCase(fetchProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.profileDetails = action?.payload
            })
            .addCase(fetchProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })
    }
});

export const { resetUserData } = userManagementSlice.actions;
export default userManagementSlice.reducer;
