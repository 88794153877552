export const roleConstantEnum = {
    ROLE_ADMIN : 'ROLE_ADMIN',
    ROLE_DRIVER: 'ROLE_DRIVER',
    ROLE_CUSTOMER : 'ROLE_CUSTOMER',
    ROLE_CSR: 'ROLE_CSR',
    ROLE_SUPERVISOR : 'ROLE_SUPERVISOR',
    ROLE_PFSC: 'ROLE_PFSC',
    ROLE_RSC: 'ROLE_RSC'
}

export const roles = [
    {
        label: 'Admin',
        value: roleConstantEnum?.ROLE_ADMIN
    },
    {
        label: 'Driver',
        value: roleConstantEnum?.ROLE_DRIVER
    },
    {
        label: 'Client',
        value: roleConstantEnum?.ROLE_CUSTOMER
    },
    {
        label: 'CSR',
        value: roleConstantEnum?.ROLE_CSR
    },
    {
        label: 'Supervisor',
        value: roleConstantEnum?.ROLE_SUPERVISOR
    },
    {
        label: 'Pathfinder',
        value: roleConstantEnum?.ROLE_PFSC
    },
    {
        label: 'RingScanner',
        value: roleConstantEnum?.ROLE_RSC
    }
]