import { createSlice } from '@reduxjs/toolkit';
import { fetchDashboardData, fetchPackageDetailsData } from './dashboardAction'
import { logout } from 'features/auth/authAction'

const initialState = {
    packageDetails: [],
    data: null,
    loading: false,
    error: null,
}
// Redux slice for dashboard
const dashbboardSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Dashboard Slice
            .addCase(fetchDashboardData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDashboardData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.data = action?.payload
            })
            .addCase(fetchDashboardData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Package Details Slice
            .addCase(fetchPackageDetailsData.pending, (state) => {
                state.packageDetailsLoading = true;
                state.error = null;
            })
            .addCase(fetchPackageDetailsData.fulfilled, (state, action) => {
                state.packageDetailsLoading = false;
                state.packageDetails = action.payload
            })
            .addCase(fetchPackageDetailsData.rejected, (state, action) => {
                state.packageDetailsLoading = false;
                state.error = action.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })
    }
});

export default dashbboardSlice.reducer;
