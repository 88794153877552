import React from 'react';
import { styled } from 'styled-components'
import { Container } from '@mui/material';
import CardComponent from './CardComponent';
import './Cards.css'

const ContainerWrapper = styled.div`
    display: flex; 
    gap: 1rem;
    @media (max-width: 768px) {
      flex-direction: column;
  }
`;


const ContainerComponent = ({cardData}) => {
  return (
    <Container maxWidth="xl" className='cont'>
      <ContainerWrapper>
      {cardData.map((card, index) => (
        <CardComponent key={index} {...card} />
      ))}
      </ContainerWrapper>
    </Container>
  );
};

export default ContainerComponent;
