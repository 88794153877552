import { jsPDF } from 'jspdf';
import ExcelJS from 'exceljs';

//PDF export
export const generatePDF = (columns, data, exportFileName, isPrint = false) => {
    const tableColumns = columns.filter(column => !!column.title && column?.accessor !== 'action')
    const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [(tableColumns?.length * 28) + 20, 297] // Use A4 height (297 mm) for landscape orientation
    })
    
    doc.autoTable({
        head: [tableColumns.map(column => column.title)],
        body: data.map(row => tableColumns.map(column => column?.render && column?.render(row))),
    });

    if (isPrint) {
        // Save the PDF content as a Blob
        const pdfBlob = doc.output('blob');

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open the print dialog for the PDF content
        const printWindow = window.open(pdfUrl);
        if (printWindow) {
            printWindow.onload = () => {
                printWindow.print();
            };
        }
    }
    else {
        doc.save(`${exportFileName}.pdf`)
    }
};


//Excel export 
export const exportToExcel = (columns, data, exportFileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const tableColumns = columns.filter(column => !!column.title && column?.accessor !== 'action')

    // Define column headers
    const headers = tableColumns.map((column) => column.title);
    worksheet.addRow(headers);

    // Add data rows
    data?.forEach((item) => {
        const row = tableColumns.map((column) => {
            const value = column?.render(item);
            return value;
        });
        worksheet.addRow(row);
    });

    // Create a blob for the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = exportFileName + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
    });
};

//Export to CSV
export const exportToCSV = (columns, data, exportFileName) => {
    const csvData = [];

    // Prepare the CSV header row
    const headerRow = columns
        .filter((column) => !!column.title && column.accessor !== 'action')
        .map((column) => column.title);
    csvData.push(headerRow);

    // Prepare the data rows
    data.forEach((item) => {
        const dataRow = columns
            .filter((column) => !!column.title && column.accessor !== 'action')
            .map((column) => {
                const value = column.render(item);
                // Check if the value contains a comma, and if it does, wrap it in double quotes
                const formattedValue = value && typeof value === 'string' && value?.includes(',') ? `"${value}"` : value;
                return formattedValue !== null ? String(formattedValue) : '--'; // Convert values to strings and handle null/undefined values
            });
        csvData.push(dataRow);
    });

    // Create a CSV content string
    const csvContent = csvData.map((row) => row.join(',')).join('\n');

    // Create a blob for the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = exportFileName + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
};
