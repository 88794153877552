import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class ReportingServices {
    getPackageSummary(formData) {
        const endpoint = `${apiEndpoint}/api/report/package`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }
}





