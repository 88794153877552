import React, { useState } from "react";
import SmallBox from "./SmallBox";
import styled from 'styled-components';

const Box = styled.div`
display: flex;
align-items: center;
justify-content: center;
background-color: lightgray;
margin: 0px 10px 0px 0px;
padding: 5px;
flex-direction: column;
`;

export const BoxLable = styled.span`
font-family: "degular", sans-serif;
letter-spacing: 0px;
opacity: 1;
font-size: 10px;
font-weight: 700;
color: ${props => props?.color || '#1A1A1A'};
cursor: pointer;
`

const Tote = ({ largeBox,largeBoxLabel }) => {
  const [showSmallBoxes, setShowSmallBoxes] = useState(true); // Initial state is true (small boxes are visible)

  const toggleSmallBoxes = () => {
    setShowSmallBoxes((prevState) => !prevState); // Toggle the state
  };

  return <Box>
    {showSmallBoxes && largeBox?.map((smallBox, index) => {
      return (
        <SmallBox key={index} smallBox={smallBox} />
      )
    })}
    <BoxLable color='#5D48FF' onClick={toggleSmallBoxes}>{largeBoxLabel}</BoxLable>
  </Box>;
}

export default Tote;
