export const packagesInBatchEnum = {
    SEQUENCED: 'SEQUENCED',
    SHORTAGE_ONE: 'SHORTAGE_ONE',
    SHORTAGE_TWO: 'SHORTAGE_TWO',
    SHORTAGE_THREE: 'SHORTAGE_THREE',
    ARCHIVE: 'ARCHIVE'
}

export const packageSuccessFullDeliveredEnum = {
    PARCEL_DEL_SUCC_SD: 'PARCEL_DEL_SUCC_SD',
    PARCEL_DEL_SUCC_SD_SIDE: 'PARCEL_DEL_SUCC_SD_SIDE',
    PARCEL_DEL_SUCC_SD_REAR: 'PARCEL_DEL_SUCC_SD_REAR',
    PARCEL_DEL_SUCC_SD_LOCKER: 'PARCEL_DEL_SUCC_SD_LOCKER',
    PARCEL_DEL_SUCC_CSGNE: 'PARCEL_DEL_SUCC_CSGNE',
    PARCEL_DEL_SUCC_CSGNE_ADULT: 'PARCEL_DEL_SUCC_CSGNE_ADULT',
    PARCEL_DEL_SUCC_CSGNE_TP: 'PARCEL_DEL_SUCC_CSGNE_TP',
    PARCEL_DEL_SUCC_CSGNE_SEC: 'PARCEL_DEL_SUCC_CSGNE_SEC',
    PARCEL_DEL_SUCC_CSGNE_CRG: 'PARCEL_DEL_SUCC_CSGNE_CRG',
    PARCEL_DEL_SUCC_CSGNE_REC: 'PARCEL_DEL_SUCC_CSGNE_REC',
}

export const packageUnSuccessFullDeliveryEnum = {
    //Status applied by supervisor, admin for unsuccessfull delivery
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_VL: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_VL',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_THEFT: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_THEFT',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_OTHER: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_OTHER',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ID: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ID',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ENTR: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ENTR',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_NAV: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_NAV',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_OTHER: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_OTHER',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_CODE: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_CODE',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_PRK: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_PRK',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_UNSAFE: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_UNSAFE',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_ENVMT: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_ENVMT',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_OTHER: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_OTHER',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_SIG: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_SIG',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_PARCEL: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_PARCEL',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_ID: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_ID',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_ADDRS: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_ADDRS',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_OTHER: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_OTHER',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_SIG: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_SIG',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_PARCEL: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_PARCEL',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_DAMAGED: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_DAMAGED',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_OTHER: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_OTHER',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_ADULT: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_ADULT',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_DAMAGED: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_DAMAGED',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_OTHER: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_OTHER',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_ENVMT: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_ENVMT',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_TIME: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_TIME',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_CANCEL: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_CANCEL',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_MISSORT: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_MISSORT',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_BLCK: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_BLCK',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ADDRS: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ADDRS',

    //Status applied by driver for unsuccessfull delivery
    PARCEL_DEL_UNSC_SD_VL: 'PARCEL_DEL_UNSC_SD_VL',
    PARCEL_DEL_UNSC_SD_THEFT: 'PARCEL_DEL_UNSC_SD_THEFT',
    PARCEL_DEL_UNSC_SD_OTHER: 'PARCEL_DEL_UNSC_SD_OTHER',
    PARCEL_DEL_UNSC_LOC_ID: 'PARCEL_DEL_UNSC_LOC_ID',
    PARCEL_DEL_UNSC_LOC_ENTR: 'PARCEL_DEL_UNSC_LOC_ENTR',
    PARCEL_DEL_UNSC_LOC_NAV: 'PARCEL_DEL_UNSC_LOC_NAV',
    PARCEL_DEL_UNSC_LOC_OTHER: 'PARCEL_DEL_UNSC_LOC_OTHER',
    PARCEL_DEL_UNSC_ACC_CODE: 'PARCEL_DEL_UNSC_ACC_CODE',
    PARCEL_DEL_UNSC_ACC_PRK: 'PARCEL_DEL_UNSC_ACC_PRK',
    PARCEL_DEL_UNSC_ACC_UNSAFE: 'PARCEL_DEL_UNSC_ACC_UNSAFE',
    PARCEL_DEL_UNSC_ACC_ENVMT: 'PARCEL_DEL_UNSC_ACC_ENVMT',
    PARCEL_DEL_UNSC_ACC_OTHER: 'PARCEL_DEL_UNSC_ACC_OTHER',
    PARCEL_DEL_UNSC_REJ_RSC_SIG: 'PARCEL_DEL_UNSC_REJ_RSC_SIG',
    PARCEL_DEL_UNSC_REJ_RSC_PARCEL: 'PARCEL_DEL_UNSC_REJ_RSC_PARCEL',
    PARCEL_DEL_UNSC_REJ_RSC_ID: 'PARCEL_DEL_UNSC_REJ_RSC_ID',
    PARCEL_DEL_UNSC_REJ_RSC_ADDRS: 'PARCEL_DEL_UNSC_REJ_RSC_ADDRS',
    PARCEL_DEL_UNSC_REJ_RSC_OTHER: 'PARCEL_DEL_UNSC_REJ_RSC_OTHER',
    PARCEL_DEL_UNSC_REJ_TP_SIG: 'PARCEL_DEL_UNSC_REJ_TP_SIG',
    PARCEL_DEL_UNSC_REJ_TP_PARCEL: 'PARCEL_DEL_UNSC_REJ_TP_PARCEL',
    PARCEL_DEL_UNSC_REJ_TP_DAMAGED: 'PARCEL_DEL_UNSC_REJ_TP_DAMAGED',
    PARCEL_DEL_UNSC_REJ_TP_OTHER: 'PARCEL_DEL_UNSC_REJ_TP_OTHER',
    PARCEL_DEL_UNSC_REJ_CSGNE_ADULT: 'PARCEL_DEL_UNSC_REJ_CSGNE_ADULT',
    PARCEL_DEL_UNSC_REJ_CSGNE_DAMAGED: 'PARCEL_DEL_UNSC_REJ_CSGNE_DAMAGED',
    PARCEL_DEL_UNSC_REJ_CSGNE_OTHER: 'PARCEL_DEL_UNSC_REJ_CSGNE_OTHER',
    PARCEL_DEL_UNSC_ATT_ENVMT: 'PARCEL_DEL_UNSC_ATT_ENVMT',
    PARCEL_DEL_UNSC_ATT_TIME: 'PARCEL_DEL_UNSC_ATT_TIME',
    PARCEL_DEL_UNSC_ATT_MISSORT: 'PARCEL_DEL_UNSC_ATT_MISSORT',
    PARCEL_DEL_UNSC_ATT_CANCEL: 'PARCEL_DEL_UNSC_ATT_CANCEL',
    PARCEL_DEL_UNSC_ACC_BLCK: 'PARCEL_DEL_UNSC_ACC_BLCK',
    PARCEL_DEL_UNSC_LOC_ADDRS: 'PARCEL_DEL_UNSC_LOC_ADDRS',
}

export const packagesInRouteEnum = {
    TOTE_ASSIGNED: 'TOTE_ASSIGNED',
    SCAN_AT_ROUTE_STOP: 'SCAN_AT_ROUTE_STOP',
    SCAN_AT_RESCUE_STOP: 'SCAN_AT_RESCUE_STOP',
    DAMAGED: 'DAMAGED',
    PACKAGE_MISSING: 'PACKAGE_MISSING',
    FINAL_DELIVERY_SCAN: 'FINAL_DELIVERY_SCAN',
    ...packageSuccessFullDeliveredEnum,
    ...packageUnSuccessFullDeliveryEnum
}

export const packagesInInductionEnum = {
    PARSE_ERROR_MANIFEST_CLIENT: 'PARSE_ERROR_MANIFEST_CLIENT',
    PARSE_ERROR_MANIFEST_STREET_PERFECT: 'PARSE_ERROR_MANIFEST_STREET_PERFECT',
    PARSE_ERROR_MANIFEST_STREET_PERFECT_MISMATCH: 'PARSE_ERROR_MANIFEST_STREET_PERFECT_MISMATCH',
    DUPLICATE_MANIFEST_CLIENT: 'DUPLICATE_MANIFEST_CLIENT',
    DUPLICATE_MANIFEST_STREET_PERFECT: 'DUPLICATE_MANIFEST_STREET_PERFECT',
    PROCESSED_STREET_PERFECT_MARKED_BAD: 'PROCESSED_STREET_PERFECT_MARKED_BAD',
    PROCESSED_STREET_PERFECT_NOT_CORRECTED: 'PROCESSED_STREET_PERFECT_NOT_CORRECTED',
    PROCESSED_STREET_PERFECT_CORRECTED: 'PROCESSED_STREET_PERFECT_CORRECTED',
    PROCESSED_MANUAL_CORRECTED: 'PROCESSED_MANUAL_CORRECTED',
    LAT_LNG_RESOLVE_SUCCESS_FROM_HERE: 'LAT_LNG_RESOLVE_SUCCESS_FROM_HERE',
    LAT_LNG_RESOLVE_FAILED: 'LAT_LNG_RESOLVE_FAILED',
    LAT_LNG_RESOLVE_SUCCESS_FROM_MN_TABLE: 'LAT_LNG_RESOLVE_SUCCESS_FROM_MN_TABLE',
    LAT_LNG_RESOLVE_SUCCESS_FROM_LC_TABLE: 'LAT_LNG_RESOLVE_SUCCESS_FROM_LC_TABLE',
    LAT_LNG_MANUALLY_CORRECTED: 'LAT_LNG_MANUALLY_CORRECTED',
    LAT_LON_RESOLVE_FAILED_FROM_HERE: 'LAT_LON_RESOLVE_FAILED_FROM_HERE',
    LOADED: 'LOADED',
    PACKAGE_SCANNED_BY_PATHFINDER: 'PACKAGE_SCANNED_BY_PATHFINDER',
    SCANNED_BY_RINGSCANNER: 'SCANNED_BY_RINGSCANNER',
    CORRECT_ROLLER_CAGE_ID_SCANNED: 'CORRECT_ROLLER_CAGE_ID_SCANNED',
    INCORRECT_ROLLER_CAGE_ID_SCANNED: 'INCORRECT_ROLLER_CAGE_ID_SCANNED',
    HighPriority_true: 'HighPriority_true',
    HighPriority_false: 'HighPriority_false',
    SLA_true: 'SLA_true',
    SLA_false: 'SLA_false'
}

export const packagesRTDTEnum = {
    PARCEL_RTDT: 'PARCEL_RTDT',
    RTS_T1_ORDER_MAX_ATT: 'RTS_T1_ORDER_MAX_ATT',
    RTERMINAL_INVTGN: 'RTERMINAL_INVTGN',
    RTERMINAL_FDD: 'RTERMINAL_FDD',
    RTERMINAL_ATT_INVALID: 'RTERMINAL_ATT_INVALID',
    RTERMINAL_RTS_T2: 'RTERMINAL_RTS_T2',
    RTS_T1_ORDER_CANCELED_CLIENT: 'RTS_T1_ORDER_CANCELED_CLIENT',
    RTS_T1_ORDER_CANCELED_CUSTOMER: 'RTS_T1_ORDER_CANCELED_CUSTOMER',
    ORDER_REBATCH_READY: 'ORDER_REBATCH_READY',
    ORDER_BATCH_READY: 'ORDER_BATCH_READY',
}

export const packageStatusEnum = {
    ...packagesInInductionEnum,
    ...packagesInBatchEnum,
    ...packagesInRouteEnum,
    ...packagesRTDTEnum,

    //other
    OVEREDGE: 'OVEREDGE',
    OVERSIZE_NOT_SCANNED: 'OVERSIZE_NOT_SCANNED',
    OVERSIZE_SCANNED: 'OVERSIZE_SCANNED',
    //RETURNED: 'RETURNED',
    CANCELLED: 'CANCELLED',
    //NOT_APPLICABLE: 'NOT_APPLICABLE',
    ON_HOLD_MANIFEST: 'ON_HOLD_MANIFEST',
    ON_HOLD_REMEDIATION: 'ON_HOLD_REMEDIATION',
    OUT_OF_SERVICE_AREA: 'OUT_OF_SERVICE_AREA',
    REATTEMPT: 'REATTEMPT',
    ARCHIVE: 'ARCHIVE',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_MISSORT: 'RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_MISSORT'
}

export const unscessfullDeliveryAttemptStatusByDriver = [

    {
        label: 'Returns Terminal - Could Not Attempt - Missort',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_MISSORT
    },
    {
        label: 'Returns Termial - Safe Drop - Vulnerable Location',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_VL
    },
    {
        label: 'Returns Terminal - Safe Drop - High Theft Probability',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_THEFT
    },
    {
        label: 'Returns Terminal - Safe Drop - Other',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_OTHER
    },
    {
        label: 'Returns Terminal - Could Not Locate - Missing Unit/Apt/Suite Details',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ID
    },
    {
        label: 'Returns Terminal - Could Not Locate - Could Not Find Entrance',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ENTR
    },
    {
        label: 'Returns Terminal - Could Not Locate - Bad Navigation Instructions',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_NAV
    },
    {
        label: 'Returns Terminal - Could Not Locate - Other',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_OTHER
    },
    {
        label: 'Returns Terminal - Could Not Locate - Bad Address Information',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ADDRS
    },
    {
        label: 'Returns Terminal - Could Not Access - Blocked Entrance',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_BLCK
    },
    {
        label: 'Returns Terminal - Could Not Access - Missing Buzzer or Call Box Information',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_CODE
    },
    {
        label: 'Returns Terminal - Could Not Access - Parking Unavailable',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_PRK
    },
    {
        label: 'Returns Terminal - Could Not Access - Unsafe Road Conditions',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_UNSAFE
    },
    {
        label: 'Returns Terminal - Could Not Access - Unsafe Environmental Conditions',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_ENVMT
    },
    {
        label: 'Returns Terminal - Rejected - Receptionist/Security/Concierge Refused Signature',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_SIG
    },
    {
        label: 'Returns Terminal - Rejected - Receptionist/Security/Concierge Cannot Find Unit/Apt/Suite Details',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_ID
    },
    {
        label: 'Returns Terminal - Rejected - Receptionist/Security/Concierge Cannot Confirm Customer Details',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_ADDRS
    },
    {
        label: 'Returns Terminal - Rejected - Receptionist/Security/Concierge Other',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_OTHER
    },
    {
        label: 'Returns Terminal - Rejected - Third Party Refused Signature',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_SIG
    },
    {
        label: 'Returns Terminal - Rejected - Third Party Refused Parcel',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_PARCEL
    },
    {
        label: 'Returns Terminal - Rejected - Third Party Declared Parcel Damage',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_DAMAGED
    },
    {
        label: 'Returns Terminal - Rejected - Third Party Other',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_OTHER
    },
    {
        label: 'Returns Terminal - Rejected - Consignee Adult Signature Required',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_ADULT
    },
    {
        label: 'Returns Terminal - Rejected - Consignee Declared Parcel Damage',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_DAMAGED
    },
    {
        label: 'Returns Terminal - Rejected - Consignee Refused Parcel',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_OTHER
    },
    {
        label: 'Returns Terminal - Could Not Attempt - Poor Weather Conditions',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_ENVMT
    },
    {
        label: 'Returns Terminal - Could Not Attempt - Route Service Time Expired',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_TIME
    },
    {
        label: 'Returns Terminal - Could Not Attempt - Route Cancelled',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_CANCEL
    },
    {
        label: 'Returns Terminal - Could Not Access - Other',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_OTHER
    },

]

export const unscessfullDeliveryAttemptStatus = [
    {
        label: 'Safe Drop - Vulnerable Location',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_SD_VL
    },
    {
        label: 'Safe Drop - High Theft Probability',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_SD_THEFT
    },
    {
        label: 'Safe Drop - Other',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_SD_OTHER
    },
    {
        label: 'Could Not Locate - Missing Unit/Apt/Suite Details',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_LOC_ID
    },
    {
        label: 'Could Not Locate - Could Not Find Entrance',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_LOC_ENTR
    },
    {
        label: 'Could Not Locate - Bad Navigation Instructions',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_LOC_NAV
    },
    {
        label: 'Could Not Locate - Bad Address Information',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_LOC_ADDRS
    },
    {
        label: 'Could Not Locate - Other',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_LOC_OTHER
    },
    {
        label: 'Could Not Access - Missing Buzzer or Call Box Information',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ACC_CODE
    },
    {
        label: 'Could Not Access - Parking Unavailable',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ACC_PRK
    },
    {
        label: 'Could Not Access - Unsafe Road Conditions',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ACC_UNSAFE
    },
    {
        label: 'Could Not Access - Unsafe Environmental Conditions',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ACC_ENVMT
    },
    {
        label: 'Could Not Access - Blocked Entrance',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ACC_BLCK
    },
    {
        label: 'Could Not Access - Other',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ACC_OTHER
    },
    {
        label: 'Rejected - Receptionist/Security/Concierge Refused Signature',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_RSC_SIG
    },
    {
        label: 'Rejected - Receptionist/Security/Concierge Refused Parcel',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_RSC_PARCEL
    },
    {
        label: 'Rejected - Receptionist/Security/Concierge Cannot Find Unit/Apt/Suite Details',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_RSC_ID
    },
    {
        label: 'Rejected - Receptionist/Security/Concierge Cannot Confirm Customer Details',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_RSC_ADDRS
    },
    {
        label: 'Rejected - Receptionist/Security/Concierge Other',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_RSC_OTHER
    },
    {
        label: 'Rejected - Third Party Refused Signature',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_TP_SIG
    },
    {
        label: 'Rejected - Third Party Refused Parcel',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_TP_PARCEL
    },
    {
        label: 'Rejected - Third Party Declared Parcel Damage',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_TP_DAMAGED
    },
    {
        label: 'Rejected - Third Party Other',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_TP_OTHER
    },
    {
        label: 'Rejected - Consignee Adult Signature Required',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_CSGNE_ADULT
    },
    {
        label: 'Rejected - Consignee Declared Parcel Damage',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_CSGNE_DAMAGED
    },
    {
        label: 'Rejected - Consignee Refused Parcel',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_REJ_CSGNE_OTHER
    },
    {
        label: 'Could Not Attempt - Poor Weather Conditions',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ATT_ENVMT
    },
    {
        label: 'Could Not Attempt - Route Service Time Expired',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ATT_TIME
    },
    {
        label: 'Could Not Attempt - Route Cancelled',
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ATT_CANCEL
    },
    {
        label: "Could Not Attempt - Missorted",
        value: packageStatusEnum?.PARCEL_DEL_UNSC_ATT_MISSORT
    },
    {
        label: 'Parcel Missing',
        value: packageStatusEnum?.PACKAGE_MISSING
    },
]

export const remediationStatus = [
    {
        label: "Parcel Under Investigation",
        value: packageStatusEnum?.RTERMINAL_INVTGN
    },
    {
        label: "On Hold Remediation",
        value: packageStatusEnum?.ON_HOLD_REMEDIATION
    },
    {
        label: "ARCHIVE",
        value: packageStatusEnum?.ARCHIVE
    },
    {
        label: "Out of Service Area",
        value: packageStatusEnum?.OUT_OF_SERVICE_AREA
    },
    {
        label: 'Damaged',
        value: packageStatusEnum?.DAMAGED
    },
]

export const parcelWithErrorStatus = [
    {
        label: 'Parse Error Manifest Client',
        value: packageStatusEnum?.PARSE_ERROR_MANIFEST_CLIENT
    },
    {
        label: 'Parse Error Street Perfect',
        value: packageStatusEnum?.PARSE_ERROR_MANIFEST_STREET_PERFECT
    },
    {
        label: 'Parse Error Manifest Street Perfect Mismatch',
        value: packageStatusEnum?.PARSE_ERROR_MANIFEST_STREET_PERFECT_MISMATCH
    },
    {
        label: 'Duplicate Manifest',
        value: packageStatusEnum?.DUPLICATE_MANIFEST_CLIENT
    },
    {
        label: 'Duplicate Street Perfect',
        value: packageStatusEnum?.DUPLICATE_MANIFEST_STREET_PERFECT
    },
    {
        label: 'Bad Street Perfect',
        value: packageStatusEnum?.PROCESSED_STREET_PERFECT_MARKED_BAD
    },
    {
        label: 'Processed Street Perfect',
        value: packageStatusEnum?.PROCESSED_STREET_PERFECT_NOT_CORRECTED
    },
    {
        label: 'Resolve Failed from HERE map',
        value: packageStatusEnum?.LAT_LON_RESOLVE_FAILED_FROM_HERE
    },
]

export const remediationPackagestatus = [
    {
        label: 'Ready To Batch',
        value: packageStatusEnum?.ORDER_BATCH_READY
    },
    {
        label: 'Parcel eligible for re-batching',
        value: packageStatusEnum?.ORDER_REBATCH_READY
    },
    {
        label: 'Staged For RTS -> Maximum service attempts reached',
        value: packageStatusEnum?.RTS_T1_ORDER_MAX_ATT
    },
    {
        label: 'Staged For RTS -> Canceled per client request',
        value: packageStatusEnum?.RTS_T1_ORDER_CANCELED_CLIENT
    },
    {
        label: 'Staged For RTS -> Canceled per customer request',
        value: packageStatusEnum?.RTS_T1_ORDER_CANCELED_CUSTOMER
    }
]

export const rtsAllowedStatuses = [
    {
        label: 'Ready To Batch',
        value: packageStatusEnum?.ORDER_BATCH_READY
    },
    {
        label: 'Parcel eligible for re-batching',
        value: packageStatusEnum?.ORDER_REBATCH_READY
    }
]

export const packageStatus = [
    {
        label: 'Parcel returned to delivery terminal',
        value: packageStatusEnum?.PARCEL_RTDT
    },
    {
        label: 'GPS Location Resolved Manually',
        value: packageStatusEnum?.LAT_LNG_MANUALLY_CORRECTED
    },
    {
        label: 'Staged For RTS -> Maximum service attempts reached',
        value: packageStatusEnum?.RTS_T1_ORDER_MAX_ATT
    },
    {
        label: 'Order assigned Future Delivery Date',
        value: packageStatusEnum?.RTERMINAL_FDD
    },
    {
        label: 'Returned to Sender',
        value: packageStatusEnum?.RTERMINAL_RTS_T2
    },
    {
        label: 'Staged For RTS -> Canceled as per client request',
        value: packageStatusEnum?.RTS_T1_ORDER_CANCELED_CLIENT
    },
    {
        label: 'Staged For RTS -> Canceled as per customer request',
        value: packageStatusEnum?.RTS_T1_ORDER_CANCELED_CUSTOMER
    },
    {
        label: 'Street Perfect Corrected',
        value: packageStatusEnum?.PROCESSED_STREET_PERFECT_CORRECTED
    },
    {
        label: 'Manual Corrected',
        value: packageStatusEnum?.PROCESSED_MANUAL_CORRECTED
    },
    {
        label: 'GPS Location Resolved From Here',
        value: packageStatusEnum?.LAT_LNG_RESOLVE_SUCCESS_FROM_HERE
    },
    {
        label: 'Address geocoding was successful From Muncipality Table',
        value: packageStatusEnum?.LAT_LNG_RESOLVE_SUCCESS_FROM_MN_TABLE
    },
    {
        label: 'GPS Location Resolved From Local Table',
        value: packageStatusEnum?.LAT_LNG_RESOLVE_SUCCESS_FROM_LC_TABLE
    },
    {
        label: 'Resolve Failed',
        value: packageStatusEnum?.LAT_LNG_RESOLVE_FAILED
    },
    {
        label: 'Overedge',
        value: packageStatusEnum?.OVEREDGE
    },
    {
        label: 'Oversize Not Scanned',
        value: packageStatusEnum?.OVERSIZE_NOT_SCANNED
    },
    {
        label: 'Oversize Scanned',
        value: packageStatusEnum?.OVERSIZE_SCANNED
    },
    {
        label: 'Loaded',
        value: packageStatusEnum?.LOADED
    },
    {
        label: 'Sequenced',
        value: packageStatusEnum?.SEQUENCED
    },
    {
        label: 'Tote Assigned',
        value: packageStatusEnum?.TOTE_ASSIGNED
    },
    {
        label: 'Scan At Route Stop',
        value: packageStatusEnum?.SCAN_AT_ROUTE_STOP
    },
    {
        label: 'Scan At Rescue Stop',
        value: packageStatusEnum?.SCAN_AT_RESCUE_STOP
    },
    {
        label: 'Final Delivery Scan',
        value: packageStatusEnum?.FINAL_DELIVERY_SCAN
    },
    // {
    //     label: 'Returned',
    //     value: packageStatusEnum?.RETURNED
    // },
    {
        label: 'Cancelled',
        value: packageStatusEnum?.CANCELLED
    },
    {
        label: 'Safe Dropped in Locker',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_SD_LOCKER
    },
    {
        label: 'Signed by Consignee (Adult)',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_ADULT
    },
    {
        label: 'Safe Dropped',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_SD
    },
    {
        label: 'Signed by Consignee',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE
    },
    // {
    //     label: 'Not Applicable',
    //     value: packageStatusEnum?.NOT_APPLICABLE
    // },
    {
        label: 'Safe Dropped at Side Door',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_SD_SIDE
    },
    {
        label: 'Safe Dropped at Rear Door',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_SD_REAR
    },
    {
        label: 'Signed by Third Party',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_TP
    },
    {
        label: 'Signed by Security',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_SEC
    },
    {
        label: 'Signed by Concierge',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_CRG
    },
    {
        label: 'Signed by Receptionist',
        value: packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_REC
    },
    {
        label: 'Shortage 1',
        value: packageStatusEnum?.SHORTAGE_ONE
    },
    {
        label: 'Shortage 2',
        value: packageStatusEnum?.SHORTAGE_TWO
    },
    {
        label: "On Hold Manifest",
        value: packageStatusEnum?.ON_HOLD_MANIFEST
    },
    {
        label: 'Reattempt',
        value: packageStatusEnum?.REATTEMPT
    },
    ...parcelWithErrorStatus,
    ...remediationStatus,
    ...unscessfullDeliveryAttemptStatusByDriver,
    ...unscessfullDeliveryAttemptStatus,
    ...rtsAllowedStatuses
]

export const packagesBeforeBatchStatus = [
    packageStatusEnum?.ORDER_BATCH_READY, packageStatusEnum?.SEQUENCED,
    packageStatusEnum?.CANCELLED, packageStatusEnum?.PARSE_ERROR_MANIFEST_CLIENT, packageStatusEnum?.PARSE_ERROR_MANIFEST_STREET_PERFECT,
    packageStatusEnum?.PARSE_ERROR_MANIFEST_STREET_PERFECT_MISMATCH, packageStatusEnum?.DUPLICATE_MANIFEST_CLIENT,
    packageStatusEnum?.DUPLICATE_MANIFEST_STREET_PERFECT, packageStatusEnum?.PROCESSED_STREET_PERFECT_MARKED_BAD,
    packageStatusEnum?.PROCESSED_STREET_PERFECT_NOT_CORRECTED, packageStatusEnum?.PROCESSED_STREET_PERFECT_CORRECTED, packageStatusEnum?.PROCESSED_MANUAL_CORRECTED]

export const returnPackagesStatus = [
    {
        label: 'Staged For RTS -> Maximum service attempts reached',
        value: packageStatusEnum?.RTS_T1_ORDER_MAX_ATT
    },
    {
        label: 'Parcel Under Investigation',
        value: packageStatusEnum?.RTERMINAL_INVTGN
    },
    {
        label: 'Order assigned Future Delivery Date',
        value: packageStatusEnum?.RTERMINAL_FDD
    },
    {
        label: 'Returned to Sender',
        value: packageStatusEnum?.RTERMINAL_RTS_T2
    },
    {
        label: 'Staged For RTS -> Canceled per client request',
        value: packageStatusEnum?.RTS_T1_ORDER_CANCELED_CLIENT
    },
    {
        label: 'Staged For RTS -> Canceled per customer request',
        value: packageStatusEnum?.RTS_T1_ORDER_CANCELED_CUSTOMER
    },
    {
        label: 'Invalid Delivery Attempt',
        value: packageStatusEnum?.RTERMINAL_ATT_INVALID
    },
]

export const returnTerminalPackageStatus = [
    ...returnPackagesStatus,
    {
        label: 'Parcel eligible for re-batching',
        value: packageStatusEnum?.ORDER_REBATCH_READY
    }
]

export const returnTerminalPackageStatusFilterOptions = [
    ...returnPackagesStatus,
    ...unscessfullDeliveryAttemptStatus,
    {
        label: 'Parcel returned to delivery terminal',
        value: packageStatusEnum?.PARCEL_RTDT
    },
    {
        label: 'Parcel eligible for re-batching',
        value: packageStatusEnum?.ORDER_REBATCH_READY
    },
    {
        label: 'Damaged',
        value: packageStatusEnum?.DAMAGED
    },
]

