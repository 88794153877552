import React from 'react';

const SearchProductIcon = (fill) => (
<svg id="search-product-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24.233" fill={fill}>
  <path id="Path_226" data-name="Path 226" d="M15.941,5.845a.293.293,0,0,1,.33.26l.052,7.215a.248.248,0,0,1-.12.225L13.256,15.3a.247.247,0,0,1-.15.05.226.226,0,0,1-.04,0L5.42,14.5a.249.249,0,0,1-.236-.249V6.913h0a.248.248,0,0,1,.148-.235L9.168,4.992h0A.248.248,0,0,1,9.3,4.974l6.641.871ZM10.573,0a10.575,10.575,0,0,1,8.851,16.359L24,21.347l-3.157,2.886-4.413-4.856A10.574,10.574,0,1,1,10.573,0Zm6.64,3.933a9.382,9.382,0,1,0,2.754,6.64,9.369,9.369,0,0,0-2.754-6.64Zm-3.768,4.02v6.535l2.293-1.3L15.689,6.6,13.444,7.952Zm-.765,6.606V8.022L9.6,7.695,9.49,10.811l-1.145-.78L7.2,10.679l.236-3.259L5.86,7.2V13.85l6.819.709Zm-.337-8.685L10.026,7.149l3.128.4,2.1-1.293-2.916-.382Zm-4.51,1,2.243-1.293-.769-.1L6.428,6.69l1.4.179Z"/>
</svg>
);

export default SearchProductIcon;





