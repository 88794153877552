export const vehicleStatusConstant = [
    {
        label: 'Available for Assignment',
        value: 'AVAILABLE_FOR_ASSIGNMENT'
    },
    {
        label: 'Standby',
        value: 'STAND_BY'
    },
    {
        label : 'Assigned to Driver',
        value: 'ASSIGNED_TO_DRIVER'
    }
]