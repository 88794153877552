import React from 'react';

const HomeIcon = (fill) => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 19.099" fill={fill || 'black'}>
  <path id="home" d="M110.463,264v7.145a.966.966,0,0,1-.953.953h-5.716v-5.716H99.983V272.1H94.267a.966.966,0,0,1-.953-.953V264a.2.2,0,0,1,.007-.045.2.2,0,0,0,.007-.045l8.56-7.056,8.56,7.056A.207.207,0,0,1,110.463,264Zm3.32-1.027-.923,1.1a.506.506,0,0,1-.313.164H112.5a.458.458,0,0,1-.313-.1l-10.3-8.589-10.3,8.589a.553.553,0,0,1-.357.1.506.506,0,0,1-.313-.164l-.923-1.1a.483.483,0,0,1-.1-.35.432.432,0,0,1,.164-.32l10.7-8.917a1.847,1.847,0,0,1,2.263,0l3.632,3.037v-2.9a.459.459,0,0,1,.476-.476h2.858a.459.459,0,0,1,.476.476v6.074l3.26,2.709a.432.432,0,0,1,.164.32A.483.483,0,0,1,113.783,262.974Z" transform="translate(-89.889 -253)" />
</svg>
);

export default HomeIcon;


