import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Layout } from 'component/common/Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { CustomFormWithBox } from 'component/common/Form/CustomFormWithBox'
import { Loader } from 'component/common/Loader/Loader'
import { fetchDriverDetails, updateDriver } from 'features/driverManagement/driverManagementAction'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { resetDriverData } from 'features/driverManagement/driverManagementSlice'
import { validateForm } from 'utils/formValidator'
import { currencies } from 'constants/currencyConstants'
import { serviceStatusConstants } from 'constants/serviceStatusConstants'
import { driverTypeConstants } from 'constants/driverTypeConstants'
import { vehicleTypeConstants } from 'constants/vehicleTypeConstants'
import { DriverFormFields } from '../userAccount/driverFormFields';
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';

const formInitialState = {
    loginName: '',
    licenseNumber: '',
    driverType: '',
    inServiceStatus: 'ACTIVE',
    ratePerHr: '',
    currencyUnit: '',
    vehicle: '',
    vehicleType: '',
    onBoardingDate: new Date(),
    workEmail: '',
    workPhone: '',
    homePhone: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    province: '',
    postalCode: '',
}

export const EditDriver = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('id');
    const isEdit = userId ? true : false
    const [modalProps, setModalProps] = useState('')
    const { addDriverSuccess, error, loading, driverDetails } = useSelector(
        (state) => state?.driverManagement
    )
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    useEffect(() => {
        return () => {
            // This code will run when the component unmounts
            dispatch(resetDriverData())
        };
    }, [])

    const handleFormSubmit = () => {
        const notRequiredFields = ['vehicle', 'addressTwo', 'id']
        notRequiredFields.push('tempPassword')
        const errors = validateForm(formValues, notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const { currencyUnit, driverType, vehicle, inServiceStatus, loginName, vehicleType, ...data } = formValues
            data['currencyUnit'] = currencyUnit?.value
            data['driverType'] = driverType?.value
            data['loginName'] = loginName?.value
            data['vehicleType'] = vehicleType?.value
            data['inServiceStatus'] = inServiceStatus?.value

            if (vehicle) {
                data['vehicleId'] = vehicle?.value
            }
            data['id'] = driverDetails?.id
            data['distributionCenter'] = driverDetails?.distributionCenter
            data['name'] = driverDetails?.name
            data['user'] = driverDetails?.user
            dispatch(updateDriver(data))
        }
    }

    const handleCancel = () => {
        dispatch(resetDriverData())
        navigate(urls?.DRIVER_PROFILE_WIDGET_URL)
    }

    useEffect(() => {
        dispatch(fetchDriverDetails(userId))
    }, []);

    useEffect(() => {
        if (driverDetails) {
            const inServiceStatus = driverDetails?.inServiceStatus ? serviceStatusConstants.find(service => service.value === driverDetails?.inServiceStatus) : null
            const driverType = driverDetails?.driverType ? driverTypeConstants.find(service => service.value === driverDetails?.driverType) : null
            const currencyUnit = driverDetails?.currencyUnit ? currencies.find(service => service.value === driverDetails?.currencyUnit) : null
            const vehicleType = driverDetails?.vehicleType ? vehicleTypeConstants.find(vehicleType => vehicleType.value === driverDetails?.vehicleType) : null

            setFormValues({
                id: driverDetails?.id,
                loginName: { label: driverDetails?.loginName, value: driverDetails?.loginName },
                licenseNumber: driverDetails?.licenseNumber,
                driverType: driverType,
                inServiceStatus: inServiceStatus,
                ratePerHr: driverDetails?.ratePerHr,
                vehicle: driverDetails?.vehicle ? { label: driverDetails?.vehicle?.registrationNumber, value: driverDetails?.vehicle?.id } : null,
                currencyUnit: currencyUnit,
                vehicleType: vehicleType,
                onBoardingDate: driverDetails?.onBoardingDate,
                workEmail: driverDetails?.workEmail,
                addressOne: driverDetails?.addressOne,
                addressTwo: driverDetails?.addressTwo,
                city: driverDetails?.city,
                province: driverDetails?.province,
                postalCode: driverDetails?.postalCode,
                workPhone: driverDetails?.workPhone,
                homePhone: driverDetails?.homePhone
            });
        }
    }, [driverDetails]);

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (addDriverSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Driver updated successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                dispatch(resetDriverData())
                navigate(urls?.DRIVER_PROFILE_WIDGET_URL)
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [addDriverSuccess]);

    const driverFormProps = DriverFormFields({ handleFieldChange, formError, formValues, isEdit });

    const formProps = {
        ...driverFormProps,
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleCancel,
                color: 'error'
            }
        ],
        loading: loading,
    }

    return (
        <Layout headerTitle={'Edit Driver'}>
            <CustomFormWithBox {...formProps} showFullHeight={true} />
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : ''}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}