import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import './carousel.scss';
import { getDateWithTimeStamp } from 'utils/common';

const CarouselContainer = styled.div`
  border: 5px solid white;
  margin: 10px 0px;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
  object-fit: contain;
  max-height: 200px;
`;


export const ImageCarousel = ({ images, user, loading }) => {
  return (
    <CarouselContainer>
      {loading ? <div>Loading...</div> :
        <Carousel showThumbs={false} showStatus={false} // Set the color here
        >
          {images?.map((image, index) => (
            <div key={index}>
            <div className="coordinates">
                Lat: {image.imageLat}, Lon: {image.imageLon}
              </div>
              <div className="timestamp">
                Captured at: {getDateWithTimeStamp(image.imageCapturedTime, user)}
              </div>
              <Image src={`data:image/jpeg;base64,${image?.image}`} alt={`Image ${index}`} />
            </div>
          ))}
        </Carousel>}
    </CarouselContainer>
  );
};