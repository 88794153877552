import React from 'react'
import { styled } from 'styled-components'
import { getRouteDetails } from 'utils/returnTerminalHelper'
import { ScannedItems } from './ScannedItems'

import 'component/route/routeDetailsComponent.scss'

const Container = styled.div`
padding: 0.8rem 0rem;
gap: 1rem;
display: flex;
flex-direction: column;
padding: 1rem;
`

const RowContainer = styled.div`
background-color: #D5D5D5;
padding: 0.5rem;
display: flex;

`

const Item = styled.span`
font-weight: ${(props) => props?.fontWeight || 'normal'};
font-family: degular, sans-serif;
padding: 0px 2px;
`

const TextComponent = ({ label, value, dataTestId }) => {
    return (
        <RowContainer data-testid={`field-${dataTestId}`}>
            <Item data-testid={`label-${dataTestId}`}  fontWeight="700">{label}</Item>{`:`}<Item data-testid={`item-${dataTestId}`}>{value}</Item>
        </RowContainer>
    );
};

export const RouteSummaryDetails = ({ data }) => {
    
    const { routeData, parcels } = getRouteDetails(data)
    return (
        <Container>
            {
                routeData?.map((item) => {
                    return (<TextComponent dataTestId ={item?.label} key={item?.label} label={item?.label} value={item?.value} />)
                })

            }
            <ScannedItems parcels={parcels} routeId={data?.routeId}/>
        </Container>
    )
}