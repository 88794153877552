import { createSlice } from '@reduxjs/toolkit';
import { fetchClients, addClient, updateClient, fetchClientDetails } from './clientManagementAction'
import { logout } from 'features/auth/authAction'

const initialState = {
    clients: [],
    loading: false,
    error: null,
    clientDetails: null,
    clientCount: 0,
    addClientSuccess: false
}
// Redux slice for clients
const clientManagementSlice = createSlice({
    name: 'driverManagement',
    initialState,
    reducers: {
        resetClientData: (state) => {
            state.addClientSuccess = false;
            state.clientDetails = null;
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            // Client Slice
            .addCase(fetchClients.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.clients = action?.payload?.clients || []
                state.clientCount = action?.payload?.totalCount || 0
            })
            .addCase(fetchClients.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // add client Slice
            .addCase(addClient.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addClient.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.addClientSuccess = true
            })
            .addCase(addClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // fetch clientdetails
            .addCase(fetchClientDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchClientDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.clientDetails = action?.payload
            })
            .addCase(fetchClientDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // update client Slice
            .addCase(updateClient.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateClient.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.addClientSuccess = true
            })
            .addCase(updateClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })
    }
});

export const { resetClientData } = clientManagementSlice.actions;
export default clientManagementSlice.reducer;
