import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

const userRole = {
    ROLE_CUSTOMER : 'customer',
    ROLE_ADMIN : 'admin',
    ROLE_DRIVER: 'driver',
    ROLE_CSR : 'csr'
}
export class UserManagementService {
    getUsers(formData) {
        const endpoint = `${apiEndpoint}/api/admin/users`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: formData
        });
    }

    addUser(formData) {
        const endpoint = `${apiEndpoint}/api/admin/users`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    getUserDetails(userName) {
        const endpoint = `${apiEndpoint}/api/admin/users/${userName}`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    updateUser(formData) {
        const endpoint = `${apiEndpoint}/api/admin/users`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    getDataCenters() {
        const endpoint = `${apiEndpoint}/api/data-centers`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getTenants() {
        const endpoint = `${apiEndpoint}/api/tenants`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    updateProfile(formData) {
        const endpoint = `${apiEndpoint}/api/account`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    getProfileDetails(formData) {
        const role = userRole?.[formData?.authorities[0]]
        const endpoint = `${apiEndpoint}/api/user/${role}`;
        const params = {
            userId: formData?.id,
          };
        return api.get(endpoint, {
            headers: getHeader(),
            params: params
        });
    }

    logout() {
        const endpoint = `${apiEndpoint}/api/logout`;
        return api.post(endpoint, null, {
            headers: getHeader(),
        });
    }
}