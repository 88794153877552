import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { fetchDrivers } from 'features/driverManagement/driverManagementAction'
import { useNavigate } from "react-router-dom";
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { VehicleDetails } from 'component/userManagement/driver/VehicleDetail'
import { getDate, covertToString, iconStyle, formatDate, checkPermission, getDateWithTimeStamp, handleApplyFilter } from 'utils/common'
import { TableHeader } from 'component/dataTable/tableHeader';
import { Audit } from 'component/audit/audit'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Tooltip } from '@mui/material';
import { driverTypeConstants } from 'constants/driverTypeConstants'
import { serviceStatusConstants } from 'constants/serviceStatusConstants'
import { TableFilter } from 'component/dataTable/tableFilter';
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';
import '../userAccount/UserAccount.scss'
import { roleConstantEnum } from 'constants/roleConstants';

const rowKey = "id"

const formInitialState = {
    name: '',
    loginName: '',
    driverType: '',
    inServiceStatus: '',
    onBoardingDate: '',
}

export const Driver = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { drivers, error, loading, driverCount } = useSelector(
        (state) => state?.driverManagement
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { user } = useSelector(
        (state) => state?.auth
    )

    const [page, setPage] = useState(0);
    const [modalProps, setModalProps] = useState(null)
    const [sorteData, setSortedData] = useState(drivers);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [applyFilter, setApplyFilter] = useState(false);
    const hasEditPermission = checkPermission(user, 'DRIVER MANAGEMENT')

    

    useEffect(() => {
        const data = {}
        if (formValues?.name) {
            data['name'] = formValues?.name?.trim()
        }
        if (formValues?.loginName) {
            data['loginName'] = formValues?.loginName?.trim()
        }
        if (formValues?.driverType?.value) {
            data['driverType'] = formValues?.driverType?.value
        }
        if (formValues?.inServiceStatus?.value) {
            data['inServiceStatus'] = formValues?.inServiceStatus?.value
        }
        if (formValues?.onBoardingDate) {
            data['onBoardingDate'] = formatDate(formValues?.onBoardingDate) || null
        }
        dispatch(fetchDrivers({
            page: page,
            size: rowsPerPage,
            ...data,
        }))
    }, [page, rowsPerPage, applyFilter])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
        else {
            // No error, close modal or reset modal props
            setModalProps({
                title: '',
                message: '',
                open: false,
                type: ''
            });
        }
    }, [error]);

    const handleApplyFilterClick = () => {
        handleApplyFilter(formValues, formInitialState, setFormErrors, setApplyFilter, setPage, applyFilter);
    }

    const handleResetFilter = () => {
        setFormValues({
            name: '',
            loginName: '',
            driverType: '',
            inServiceStatus: '',
            onBoardingDate: '',
        })
        setFormErrors({})
    }

    const columns = [
        {
            accessor: "id",
            title: "ID",
            width: "4rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.id || '-'
        },
        {
            accessor: "user.firstName",
            title: "Name",
            width: "6rem",
            Cell: ({ cell: { row: { original } } }) => `${original?.user?.firstName} ${original?.user?.lastName}`,
            render: (item) => `${item?.user?.firstName} ${item?.user?.lastName}`
        },
        {
            accessor: "user.login",
            title: "User Name",
            width: "6rem",
            Cell: ({ cell: { row: { original } } }) => original?.user?.login,
            render: (item) => item?.user?.login || '-'
        },
        {
            accessor: "distributionCenter.dcName",
            title: "DC Name",
            width: "6rem",
            Cell: ({ cell: { row: { original } } }) => original?.distributionCenter?.dcName,
            render: (item) => item?.distributionCenter?.dcName || '-'
        },
        {
            accessor: "driverType",
            title: "Driver Type",
            width: "4rem",
            Cell: ({ cell: { value } }) => covertToString(value) || '--',
            render: (item) => covertToString(item?.driverType) || '--'
        },
        {
            accessor: 'inServiceStatus',
            title: "Service Status",
            width: "4rem",
            Cell: ({ cell: { value } }) => covertToString(value) || '--',
            render: (item) => covertToString(item?.inServiceStatus) || '--'
        },
        {
            accessor: 'onBoardingDate',
            title: "Hire Date",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
            render: (item) => item?.onBoardingDate ? getDate(item?.onBoardingDate) : '--'
        },
        {
            accessor: 'ratePerHr',
            title: "Rate per Hour",
            width: "2rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.ratePerHr || '--'
        },
        {
            accessor: 'vehicleType',
            title: "Vehicle Type",
            Cell: ({ cell: { value } }) => covertToString(value) || '--',
            render: (item) => covertToString(item?.vehicleType) || '--'
        },
        {
            accessor: 'user.createdDate',
            title: "Created Date",
            width: "3rem",
            Cell:  ({ cell: { row: { original } } }) => original?.user ? getDateWithTimeStamp(original?.user?.createdDate, user) : '--',
            render: (item) => item?.user?.createdDate ? getDateWithTimeStamp(item?.user?.createdDate, user) : '--'
        },
        hasEditPermission && {
            accessor: 'action',
            title: "Action",
            width: "0.5rem",
            isSortable: false,
            Cell: ({ cell: { row: { original } } }) => <div className='action' >
                { user?.authorities.includes(roleConstantEnum?.ROLE_ADMIN) ? <Tooltip title="Edit"><img src="/static/images/edit-icon.svg" alt='edit' onClick={() => handleEditDriverClick(original?.id)} /></Tooltip> : null }
                <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.id, 'entityType': 'drivers' }))} /></Tooltip>
                {/* <DeleteIcon style={iconStyle} fontSize="small" onClick={() => handleDeleteDriverClick(original?.id)} /> */}
            </div>
        }
    ]?.filter(Boolean)

    const tableFilterProps = {
        fields: [{
            label: 'Name',
            value: formValues?.name,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'name',
            error: formError?.name,
            placeholder: 'Name'
        },
        {
            label: 'Username',
            value: formValues?.loginName,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'loginName',
            error: formError?.loginName,
            placeholder: 'Username'
        },
        {
            label: 'Driver Type',
            value: formValues?.driverType,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '12rem',
            options: driverTypeConstants,
            name: 'driverType'
        },
        {
            label: 'Service Status',
            value: formValues?.inServiceStatus,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '12rem',
            options: serviceStatusConstants,
            name: 'inServiceStatus'
        },
        {
            label: 'Hire Date',
            value: formValues?.onBoardingDate,
            handleFieldChange: handleFieldChange,
            type: 'date',
            width: isMobile ? '9rem' : '12rem',
            name: 'onBoardingDate',
            placeholder: 'DD-MM-YYYY',
            error: formError?.onBoardingDate
        }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px',
    }

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleEditDriverClick = (id) => {
        if (id) {
            navigate(`${urls?.EDIT_DRIVER_WIDGET_URL}?id=${id}`)
        }
    }

    return (
        <Layout headerTitle={'Driver Profiles'} showBackArrow={false} 
        // action={<PrimaryButton type="button" variant='primary' label={'Add Driver'} height={'30px'} onClick={() => handleAddDriverClick()} />}
        >
                <div className='container'>
                    <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Driver Profiles' onRefreshClick={handleApplyFilterClick} />
                    <TableFilter {...tableFilterProps} />
                    <div className='content'>
                        <DataTable columns={columns} data={drivers || []} isCollpsable={true} rowKey={rowKey} childComponent={VehicleDetails} onSortChange={setSortedData} uniqueKey={'id'} setUpdatedColumns={setUpdatedColumns} showPagination={drivers?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={driverCount} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} collpasibleColumnLabel={'Vehicle Details'} />
                    </div>
                </div>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            <Audit />
        </Layout>
    )
}