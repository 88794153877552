import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { parseNotificationBody, getRedirectURL } from 'utils/notificationUtils'
import { PrimaryButton } from 'component/common/Button/Button'
import { sendSOSAcceptance } from 'features/route/routeAction'
import './Layout.scss'

export const NotificationPanel = ({ open, onClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { messages } = useSelector(
        (state) => state?.messages
    )
    const handleAnchorClick = (event, data) => {
        event.preventDefault()
        const url = getRedirectURL(data)
        if (url) {
            navigate(url)
        }
    };

    const handleSOSAction = (action, routeId) => {
        dispatch(sendSOSAcceptance({
            action,
            routeId
        }))
    }

    return (
        <Drawer anchor="right" open={open} onClose={onClose} className='notification-drawer'
        >
            <Box sx={{ width: 250 }} onClick={onClose} onKeyDown={onClose}>
                <List>
                    {messages?.map((msg, index) => {
                        const parsedJson = parseNotificationBody(msg);
                        return (
                            <React.Fragment key={`${index}-${parsedJson?.title}`}>
                                <ListItem>
                                    <ListItemText primary={parsedJson?.title}
                                        secondary={
                                            parsedJson?.body?.isSOS ? (
                                                <div className='message'>
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: parsedJson?.body?.message }}
                                                        onClick={(event) => handleAnchorClick(event, msg.body)}
                                                    />
                                                    <div className='action'>
                                                        <PrimaryButton height={'30px'} padding={'11px 15px'} label={'Accept'} onClick={() => handleSOSAction(true, parsedJson?.body?.id)} />
                                                        <PrimaryButton height={'30px'} padding={'11px 15px'} label={'Reject'} onClick={() => handleSOSAction(false, parsedJson?.body?.id)} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: parsedJson?.body?.message }}
                                                    onClick={(event) => handleAnchorClick(event, msg.body)}
                                                />
                                            )
                                        }
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
                </List>
            </Box>
        </Drawer>
    );
};