export const serviceStatusConstants = [
    {
        label: 'Active',
        value: 'ACTIVE'
    },
    {
        label: 'In Active',
        value: 'INACTIVE'
    },
    {
        label: 'On Call',
        value: 'ONCALL'
    },
    {
        label: 'On Leave',
        value: 'ONLEAVE'
    },
    {
        label: 'Quit',
        value: 'QUIT'
    },
    {
        label: 'Terminated',
        value: 'TERMINATED'
    },
    {
        label: 'Vacation',
        value: 'VACATION'
    }
]