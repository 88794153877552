import { createSlice } from '@reduxjs/toolkit';
import { fetchDrivers, addDriver, updateDriver, fetchDriverDetails, deleteDriver, fetchLoginNames, fetchDriversForRoaster } from './driverManagementAction'
import { logout } from 'features/auth/authAction'

const initialState = {
    drivers: [],
    loading: false,
    error: null,
    driverDetails: null,
    deleteDriverSuccess: false,
    usernames: [],
    driverCount: 0,
    driversForRoasters: [],
    addDriverSuccess: false
}
// Redux slice for drivers
const driverManagementSlice = createSlice({
    name: 'driverManagement',
    initialState,
    reducers: {
        resetDriverData: (state) => {
            state.addDriverSuccess = false;
            state.driverDetails = null;
            state.error = null
            state.deleteDriverSuccess = false
        }
    },
    extraReducers: (builder) => {
        builder
            // Drivers Slice
            .addCase(fetchDrivers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDrivers.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.drivers = action?.payload?.drivers
                state.driverCount = action?.payload?.totalCount
            })
            .addCase(fetchDrivers.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // add driver Slice
            .addCase(addDriver.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addDriver.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.addDriverSuccess = true
            })
            .addCase(addDriver.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // fetch driverdetails
            .addCase(fetchDriverDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDriverDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.driverDetails = action?.payload
            })
            .addCase(fetchDriverDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // update driver Slice
            .addCase(updateDriver.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateDriver.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.addDriverSuccess = true
            })
            .addCase(updateDriver.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Delete driver
            .addCase(deleteDriver.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteDriver.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.deleteDriverSuccess = true
            })
            .addCase(deleteDriver.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // Drivers Slice
            .addCase(fetchLoginNames.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLoginNames.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.usernames = action?.payload
            })
            .addCase(fetchLoginNames.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Fetch drivers for roaster
            .addCase(fetchDriversForRoaster.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDriversForRoaster.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.driversForRoasters = action?.payload
            })
            .addCase(fetchDriversForRoaster.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })

    }
});

export const { resetDriverData } = driverManagementSlice.actions;
export default driverManagementSlice.reducer;
