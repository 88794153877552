import React from 'react';

export const Dot = ({ color }) => {
  const dotStyle = {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: color,
    display: 'inline-block',
    marginRight: '5px'
  };

  return <span style={dotStyle}></span>;
};