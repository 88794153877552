import { createSlice } from '@reduxjs/toolkit';
import { fetchRollerCageData, fetchTotesData, addTote, updateTote, fetchWarehouseData } from './warehouseOperationAction'
import { logout } from 'features/auth/authAction'

 const initialState = {
    rollerCages: [],
    totes: [],
    totesCount: 0,
    loading: false,
    error: null,
    addSuccess: false,
    toteFilter: {},
    warehouseData: []
 }

// Redux slice for warehouse operation
const warehouseOperationSlice = createSlice({
    name: 'warehouseOperation',
    initialState,
    reducers: {
        resetData: (state) => {
            state.addSuccess = false;
            state.error = null
        },
        setToteFilter: (state, action) => {
            state.toteFilter = action.payload;
        },
        resetToteFilter: (state, action) => {
            state.toteFilter = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRollerCageData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRollerCageData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.rollerCages = action?.payload
            })
            .addCase(fetchRollerCageData.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(fetchTotesData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTotesData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.totes = action?.payload?.totes
                state.totesCount = action?.payload?.totalCount
            })
            .addCase(fetchTotesData.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(addTote.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addTote.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.addSuccess = true
            })
            .addCase(addTote.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(updateTote.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTote.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.addSuccess = true
            })
            .addCase(updateTote.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Warehouse data
            .addCase(fetchWarehouseData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWarehouseData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.warehouseData = action?.payload
            })
            .addCase(fetchWarehouseData.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })

    }
});

export const { resetData, resetToteFilter, setToteFilter } = warehouseOperationSlice.actions;
export default warehouseOperationSlice.reducer;
