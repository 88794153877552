import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class DriverManagementService {
    // Driver APIs
    getDrivers(queryParams) {
        const endpoint = `${apiEndpoint}/api/drivers`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    getDriversForRoaster(queryParams) {
        const endpoint = `${apiEndpoint}/api/daily-roasters/drivers`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    addDriver(formData) {
        const endpoint = `${apiEndpoint}/api/drivers`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    getDriverDetails(id) {
        const endpoint = `${apiEndpoint}/api/drivers/${id}`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getLoginNames() {
        const endpoint = `${apiEndpoint}/api/account/availablefordriver`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    updateDriver(formData) {
        // const { id, ...data } = formData
        const endpoint = `${apiEndpoint}/api/drivers/${formData?.id}`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    deleteDriver(driverId) {
        const endpoint = `${apiEndpoint}/api/drivers/${driverId}`;
        return api.delete(endpoint, null, {
            headers: getHeader(),
        });
    }
}