import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CustomModal } from 'component/common/Modal/CustomModal';
import useFieldChange from 'hooks/useFieldChange';
import { CustomForm } from 'component/common/Form/CustomForm';
import { getUserTimeZone, getUTCTime } from 'utils/common';
import { rescueRoute } from 'features/route/routeAction'
import { validateForm } from 'utils/formValidator'
import { getTrackingNumbers } from 'component/returnDashboard/ScannedItems'

export const rescuePointOptions = [
    {
        label: "DC",
        value: "DC"
    },
    {
        label: "Rescue Stop",
        value: 'Rescue_Stop'
    }
]

const formInitialState = {
    rescuePoint: '',
    routeStartTime: '',
    routeId: '',
};


export const RescueRouteModal = ({ isModelOpen, handleClose, route, parcels, data }) => {
    const dispatch = useDispatch()
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const { loading } = useSelector(
        (state) => state?.route
    );
   
    const { user } = useSelector(
        (state) => state?.auth
    )

    const userTimeZone = getUserTimeZone(user?.distributionCenterResponseVm?.timeZone) || 'America/Toronto'

    useEffect(() => {
        if (route) {
            setFormValues((prevValues) => ({
                ...prevValues,
                routeId: route?.dailyRouteScanSummaryVM?.routeId,
            }));
        }
    }, [route])

    const handleFormSubmit = () => {
        const errors = validateForm(formValues, []);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const payload = {
                routeId: formValues?.routeId,
                rescuePoint: formValues?.rescuePoint?.value,
                routeStartTime: getUTCTime(formValues?.routeRescheduleTime, userTimeZone),
               trackingNumbers : getTrackingNumbers(parcels, data)
            }
            dispatch(rescueRoute(payload));
        }
    };


    const handleDateChange = (date) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            routeStartTime: date,
        }));
    };

    const formProps = {
        fields: [
            {
                label: 'Route ID',
                name: 'routeId',
                required: true,
                type: 'text',
                value: formValues?.routeId || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.routeId,
                disabled: true
            },
            {
                label: 'Select Rescue Point',
                name: 'rescuePoint',
                required: true,
                type: 'select',
                options: rescuePointOptions,
                value: formValues?.rescuePoint || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.rescuePoint,
            },
            {
                label: 'Route Start Time',
                name: 'routeStartTime',
                required: true,
                type: 'datetime',
                value: formValues?.routeStartTime || '',
                handleFieldChange: handleDateChange,
                width: '19rem',
                error: formError?.routeStartTime,
                timeZone: userTimeZone
            }
        ],
        buttons: [
            {
                label: 'Submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: 'center'
    };

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>{'Rescue Route'}</span></div>
                <CustomForm {...formProps} allowOverflow='unset' />
            </div>
        </CustomModal>
    );
};
