import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Layout } from 'component/common/Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { CustomForm } from 'component/common/Form/CustomForm'
import { languages } from 'constants/languageConstants'
import { roles } from 'constants/roleConstants'
import { Loader } from 'component/common/Loader/Loader'
import { fetchUserDetails, updateUser, fetchTenants } from 'features/userManagement/userManagementAction'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { resetUserData } from 'features/userManagement/userManagementSlice'
import { validateForm } from 'utils/formValidator'
import { getDCObject } from 'utils/common'
import { UserFormFields } from './userFormFields';
import { urls } from 'utils/urls'
import { fetchLoggedInUserDetails } from 'features/auth/authAction'

const formInitialState = {
    firstName: '',
    lastName: '',
    email: '',
    login: '',
    langKey: '',
    dcName: '',
    tenantName: '',
    authorities: '',
    userPhoto: null,
    tempPassword: '',
    activated: true
}

export const EditUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userName = queryParams.get('user_name');
    const isEdit = userName ? true : false
    const [modalProps, setModalProps] = useState('')
    const { addUserSuccess, error, loading, userDetails, dataCenters, tenants } = useSelector(
        (state) => state?.userManagement
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const [formValues, setFormValues] = useState(formInitialState);
    const [formError, setFormErrors] = useState({});

    useEffect(() => {
        if (!tenants?.length) {
            dispatch(fetchTenants())
        }
    }, [tenants])

    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result;
                setFormValues((prevValues) => ({
                    ...prevValues,
                    'userPhoto': base64Data
                }));
            };

            reader.readAsDataURL(file);
        }
    }

    const handleFieldChange = (event) => {
        if (event?.target?.name === 'activated') {
            const name = event?.target?.name
            const value = event?.target?.checked
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
        else {
            const { name, value } = event.target;
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }

    };

    const handleFormSubmit = () => {
        const notRequiredFields = ['userPhoto', 'activated']
        if (isEdit) {
            notRequiredFields.push('tempPassword')
        }

        const errors = validateForm(formValues, notRequiredFields);

        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const { authorities, userPhoto, langKey, tenantName, dcName, activated, ...data } = formValues
            data['authorities'] = [authorities?.value]
            data['langKey'] = langKey?.value
            data['tenantName'] = tenantName?.value
            if (dcName?.value) {
                data['distributionCenterResponseVm'] = getDCObject(dcName?.value, dataCenters)
            }
            data['activated'] = activated

            if (userPhoto) {
                // Remove prefix from base64 data, this needs to be handle at time of submit
                // only as image upload component need this prefix
                data['userPhoto'] = userPhoto?.split(',')?.[1];
            }
            if (isEdit) {
                data['id'] = userDetails?.id
            }
            dispatch(updateUser(data))
        }
    }

    const handleCancel = () => {
        navigate(urls?.USER_MANAGEMENT_WIDGET_URL)
        dispatch(resetUserData())
    }

    useEffect(() => {
        if (isEdit) {
            dispatch(fetchUserDetails(userName))
        }
    }, [isEdit]);

    useEffect(() => {
        if (userDetails) {
            const photo = userDetails?.userPhoto ? `data:image\/(png|jpeg|jpg);base64,${userDetails?.userPhoto}` : null
            const language = userDetails?.langKey ? languages.find(lang => lang.value === userDetails?.langKey) : null
            const tenant = userDetails?.tenantName ? tenants.find(tenant => tenant?.tenantName === userDetails?.tenantName) : null
            const dc = userDetails?.distributionCenterResponseVm ? dataCenters.find(dc => dc.dcName === userDetails?.distributionCenterResponseVm?.dcName) : null
            const authorities = userDetails?.authorities ? roles.find(role => role.value === userDetails?.authorities[0]) : null
            setFormValues({
                firstName: userDetails?.firstName,
                lastName: userDetails?.lastName,
                email: userDetails?.email,
                login: userDetails?.login,
                langKey: language,
                dcName: { label: dc?.dcName, value: dc?.dcName, ...dc },
                tenantName: { label: tenant?.tenantName, value: tenant?.tenantName },
                authorities: authorities,
                userPhoto: photo,
                tempPassword: '',
                activated: userDetails?.activated
            });
        }
    }, [userDetails]);

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (addUserSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: isEdit ? 'User updated successfully' : 'User created successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                dispatch(resetUserData())
                //If user is logged in as admin and updating his own profile then details needs to be fetch to update app bar
                if(user?.login === userName){
                    dispatch(fetchLoggedInUserDetails())
                }
                navigate(urls?.USER_MANAGEMENT_WIDGET_URL)
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [addUserSuccess]);

    const userFormProps = UserFormFields({ handleFieldChange, formError, formValues, handleImageUpload, isEdit });
    const formProps = {
        ...userFormProps,
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleCancel,
                color: 'error'
            }
        ],
        showImageUploader: true,
        loading: loading,
        handleImageUpload: handleImageUpload,
        image: formValues?.userPhoto,
    }

    return (
        <Layout headerTitle={'Edit User'}>
            <CustomForm {...formProps} showFullHeight={true}/>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : ''}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}