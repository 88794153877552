import React from "react";
import styled from "styled-components";
import { Layout } from "component/common/Layout/Layout.jsx";
import GraphContainer from "component/Charts/GraphContainer.jsx";
import ContainerComponent from "component/Charts/ContainerComponent.jsx";
import GroupCharts from "component/Charts/GroupCharts.jsx";
import DoughnutCharts from "component/Charts/DoughnutCharts.jsx";
import LiveTrackingTable from "component/Charts/LiveTrackingTable.jsx";
import Fleets from "component/Charts/Fleets.jsx";
import MetricTable from "component/Charts/MetricTable.jsx";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import BarData from "component/Charts/ChartData/BarData.json";
import Doughnutdata from "component/Charts/ChartData/Doughnutdata.json";
import ManagementIcon from "icons/ManagementIcon";
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ProfessionalSkillsIcon from 'icons/ProfessionalSkillsIcon'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';

const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  height: 100%;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const cardData = [
    { number: '1,583', label: 'Parcels', icon: <Inventory2OutlinedIcon style={{ fontSize: 42, color: '#ffffff' }}/>},
    { number: '25', label: 'Vehicles', icon: <DirectionsCarFilledOutlinedIcon style={{ fontSize: 42, color: '#ffffff' }}/> },
    { number: '251', label: 'Live Tracking', icon: <LocationSearchingOutlinedIcon style={{ fontSize: 42, color: '#ffffff' }}/>},
    { number: '58', label: 'Manifests', icon: <Inventory2OutlinedIcon style={{ fontSize: 42, color: '#ffffff' }}/>},
]

const AdminDashboard = () => {
  return (
    <Layout headerTitle={"Daily Dashboard"} showBackArrow={false}>
      <ContainerComponent cardData={cardData}/>
      <DashboardContainer>
        <LeftContainer>
          <GraphContainer
            title="Parcels"
            icon={<Inventory2OutlinedIcon />}
            link="/packages"
          >
            <GroupCharts data={BarData[0]} />
          </GraphContainer>
          <GraphContainer
            title="Manifests"
            icon={<ManagementIcon fill="#5D48FF" />}
            link="/manifest"
          >
            <GroupCharts data={BarData[1]} />
          </GraphContainer>
          <GraphContainer
            title="Vehicles"
            icon={<LocalShippingOutlinedIcon />}
            link="/fleet-dashboard/vehicles"
          >
            <GroupCharts data={BarData[2]} />
          </GraphContainer>
          <GraphContainer title="Fleet"
            icon={<ProfessionalSkillsIcon />}
            link="/fleet-dashboard/drivers"
          >
            <Fleets />
          </GraphContainer>
          <GraphContainer
            title="OPERATIONS SUMMARY"
            icon={<GridViewRoundedIcon />}
            link="/fleet-dashboard/vehicles"
          >
            <MetricTable />
          </GraphContainer>
        </LeftContainer>
        <RightContainer>
          <GraphContainer
            title="Live Tracking"
            icon={<GpsFixedIcon />}
            link="/live-tracking"
          >
            <DoughnutCharts data={Doughnutdata[1]} />
          </GraphContainer>
          <GraphContainer
            title="Live Tracking Details"
            icon={<GpsFixedIcon />}
            link="/live-tracking"
          >
            <LiveTrackingTable />
          </GraphContainer>
          <GraphContainer
            title="Vehicle Status"
            icon={<LocalShippingOutlinedIcon />}
            link="/fleet-dashboard/vehicles"
          >
            <DoughnutCharts data={Doughnutdata[0]} />
          </GraphContainer>
        </RightContainer>
      </DashboardContainer>
    </Layout>
  );
};

export default AdminDashboard;
