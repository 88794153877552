import React from 'react';

const HomeDeliveryIcon = (fill) => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill={fill}>
  <path id="home-delivery-icon" d="M0,14.975H11.45V16H0ZM19.239,1.367h-1.35c-.059,0-.088.051-.1.107l-.2.723c-.016.057.047.107.1.107h1.016a2.691,2.691,0,0,0-.135.82,2.528,2.528,0,1,0,5.056,0,2.568,2.568,0,0,0-2.539-2.6,2.478,2.478,0,0,0-1.853.846ZM23.414,14.1,24,19.321c-.016,1.654-2.242,1.814-2.484.451l-.424-5.05-.586,4.761a1.248,1.248,0,1,1-2.484-.25l.371-2.148.316-3.609c-.523-1.4-.064-2.344-.1-3.988a4.22,4.22,0,0,1-3.1,1.689c-.451-.018-1.172-.051-1.623-.1-.551-.062-.937-.039-1.283-.537A.968.968,0,0,1,13.485,9c.781.07,2.219.248,2.756-.414.559-.533.99-1.279,1.719-1.516,4.1-1.332,5.119-.81,5.468.656a42.652,42.652,0,0,1,.2,4.453,8.959,8.959,0,0,1-.2,1.928ZM12.345,5.228v3.23a1.8,1.8,0,0,0-.371.391,1.724,1.724,0,0,0-.228.5l-1.072-.641L10.7,4.185l1.64,1.035Zm.687-1.469,1.629.928-2.119.268-1.484-.935,1.974-.26Zm-.35,4.492V5.273l2.209-.281L14.964,7.1l.781-.527.781.437L16.366,4.8l1.324-.15v1.6a4.043,4.043,0,0,0-1.242,1.109,6.507,6.507,0,0,1-.467.516l-.055.059c-.342.4-1.514.295-2.256.227-.3-.027.391.031-.154-.016a1.451,1.451,0,0,0-.836.111Zm3.465-3.759-1.58-.935.666-.066,2.01.883ZM10.073,14.514V0h-8.7V14.514ZM2.9,7.031l.922-.412V9.493L2.9,9.236v-2.2Z" fillRule="evenodd"/>
</svg>
);

export default HomeDeliveryIcon;




