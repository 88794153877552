// src/redux/drawerReducer.js
import { OPEN_DRAWER, CLOSE_DRAWER, UPDATE_SIDE_MENU_STATE } from './drawerAction';

const initialState = {
  isDrawerOpen: false,
  sideMenuState: {}, // Initial state for sideMenuState
};

const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DRAWER:
      return {
        ...state,
        isDrawerOpen: true,
      };
    case CLOSE_DRAWER:
      return {
        ...state,
        isDrawerOpen: false,
      };
    case UPDATE_SIDE_MENU_STATE:
      return {
        ...state,
        sideMenuState: action.payload, // Update sideMenuState with the payload
      };

    default:
      return state;
  }
};

export default drawerReducer;
