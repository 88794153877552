import { createAsyncThunk } from '@reduxjs/toolkit'
import { DailyRoasterService } from 'services/dailyRoasterService'
const dailyRoasterService = new DailyRoasterService();

export const fetchDailyRoasters = createAsyncThunk(
    'dailyroaster/fetchDailyRoasters',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await dailyRoasterService.getDailyRoasters(formData)
            return { dailyRoasters: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addRoaster = createAsyncThunk(
    'dailyroaster/addRoaster',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await dailyRoasterService.addRoaster(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const updateRoaster = createAsyncThunk(
    'dailyroaster/updateRoaster',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await dailyRoasterService.updateRoaster(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchDailyRoasterDetails = createAsyncThunk(
    'dailyroaster/fetchDailyRoasterDetails',
    async (id, { rejectWithValue }) => {
        try {
            const response = await dailyRoasterService.getDailyRoasters(id)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchDailyRoasterRoutes = createAsyncThunk(
    'dailyroaster/fetchDailyRoasterRoutes',
    async (driverId, { rejectWithValue }) => {
        try {
            const response = await dailyRoasterService.getDailyRoasterRoutes(driverId)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)