import { createSlice } from '@reduxjs/toolkit';
import { addRoaster, fetchDailyRoasters, updateRoaster, fetchDailyRoasterDetails, fetchDailyRoasterRoutes } from './dailyRoasterAction'
import { logout } from 'features/auth/authAction'

const initialState = {
    dailyRoasters: [],
    loading: false,
    error: null,
    addRoasterSuccess: false,
    updateRoasterSuccess: false,
    dailyRoasterDetails: null,
    dailyRoasterRoutes: [],
    dailyRoasterRouteLoading: false,
    dailyRoasterCount: 0
}
// Redux slice for drivers
const dailyRoasterSlice = createSlice({
    name: 'dailyRoaster',
    initialState,
    reducers: {
        resetSuccessData: (state) => {
            state.addRoasterSuccess = false;
            state.error = null;
            state.dailyRoasterDetails = null;
            state.updateRoasterSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder
            // Daily Roaster Slice
            .addCase(fetchDailyRoasters.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDailyRoasters.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.dailyRoasters = action?.payload?.dailyRoasters
                state.dailyRoasterCount = action?.payload?.totalCount

            })
            .addCase(fetchDailyRoasters.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // add roaster Slice
            .addCase(addRoaster.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addRoaster.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.addRoasterSuccess = true
            })
            .addCase(addRoaster.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // update roaster Slice
            .addCase(updateRoaster.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.updateRoasterSuccess = false
            })
            .addCase(updateRoaster.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.updateRoasterSuccess = true
            })
            .addCase(updateRoaster.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // roaster detais Slice
            .addCase(fetchDailyRoasterDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDailyRoasterDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.dailyRoasterDetails = action?.payload
            })
            .addCase(fetchDailyRoasterDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // roaster routes Slice
            .addCase(fetchDailyRoasterRoutes.pending, (state) => {
                state.dailyRoasterRouteLoading = true;
                state.error = null;
            })
            .addCase(fetchDailyRoasterRoutes.fulfilled, (state, action) => {
                state.dailyRoasterRouteLoading = false;
                state.error = null;
                state.dailyRoasterRoutes = action?.payload
            })
            .addCase(fetchDailyRoasterRoutes.rejected, (state, action) => {
                state.dailyRoasterRouteLoading = false;
                state.error = action?.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })
    }
});

export const { resetSuccessData } = dailyRoasterSlice.actions;
export default dailyRoasterSlice.reducer;
