import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class VehicleService {
    getVehicles(formData) {
        const endpoint = `${apiEndpoint}/api/vehicles`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: formData
        });
    }

    getVehicleDetails(id) {
        const endpoint = `${apiEndpoint}/api/vehicles/${id}`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getVehiclesWithoutDrivers(formData) {
        const endpoint = `${apiEndpoint}/api/vehicles?page=0&size=500&widget=${formData?.widget}`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    addVehicle(formData) {
        const endpoint = `${apiEndpoint}/api/vehicles`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    updateVehicle(formData) {
        const endpoint = `${apiEndpoint}/api/vehicles/${formData?.id}`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    deleteVehicle(vehicleId) {
        const endpoint = `${apiEndpoint}/api/vehicles/${vehicleId}`;
        return api.delete(endpoint, {
            headers: getHeader(),
        });
    }

}