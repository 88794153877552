import { createAsyncThunk } from '@reduxjs/toolkit'
import { RouteOperationService } from "../../services/routeOperationService.js";

const routeOperationService = new RouteOperationService();
export const getRoutes = createAsyncThunk(
    'routeOperation/getRoutes',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeOperationService.getAllRoutes(formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const updateRouteStatus = createAsyncThunk(
    'routeOperation/updateRouteStatus',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeOperationService.updateRouteStatus(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
)

export const updateRouteStopStatus = createAsyncThunk(
    'routeOperation/updateRouteStopStatus',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeOperationService.updateRouteStopStatus(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
)

export const updatePackageStatus = createAsyncThunk(
    'routeOperation/updatePackageStatus',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeOperationService.updatePackageStatus(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
)
