import { environment } from "../environments/environment.jsx";
import api from './api';
import { getHeader } from './authUtils.js'

const apiEndpoint = environment.webservices.endpoint;

export class AuditService {
    getAuditData(queryParams) {
        const endpoint = `${apiEndpoint}/api/${queryParams?.entityType}/audit/${queryParams?.entityId}`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }
}