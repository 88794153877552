import React, { useEffect, useState } from 'react'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import Login from './component/login/login.jsx';
import Register from './component/login/register.jsx';
import PageNotFound from './component/pageNotFound'
import { fetchLoggedInUserDetails } from './features/auth/authAction'
import { sideMenuOptions } from './utils/sideMenu'
// import TestRoute from './component/test/testRoute.jsx';
import { setIsMobile, setIsIpad } from 'features/viewportSlice';
import ErrorBoundary from 'ErrorBoundary';
import ErrorPage from 'ErrorPage';
import TwoFactorAuthenticator from 'component/login/TwoFactorAuthenticator'
import ResetPassword from 'component/login/ResetPassword'
import { resetLocalStorage, PUBLIC_URL } from 'utils/authHelper'
import { urls } from 'utils/urls'
import { getUserRole } from 'utils/common'
import { initializeFirebaseMessaging  } from './firebaseMessaging';
import { closeDrawer } from 'features/drawer/drawerAction';
import { CustomDrawer} from 'component/common/Layout/CustomDrawer';
import { fetchFSAZones } from 'features/fsaZone/fsaZoneAction'
import { fetchDataCenters } from 'features/userManagement/userManagementAction';
import { getUserDCOption } from 'utils/common'

export default function App() {
  const token = localStorage.getItem('token')

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalProps, setModalProps] = useState(null)

  const { userFetchError, user } = useSelector(
    (state) => state?.auth
  )
  const { isMobile } = useSelector((state) => state?.viewport); 
  const { isDrawerOpen } = useSelector(
        (state) => state?.drawer
    );

    const handleDrawerClose = () => {
        dispatch(closeDrawer());
    };


  const routes = sideMenuOptions[getUserRole(user)]

  useEffect(() => {
    initializeFirebaseMessaging(dispatch); // Register Firebase messaging
  }, [dispatch]);

  useEffect(() => {
    redirectUser();
  }, [location.pathname]);

  useEffect(() => {
    if (!token) {
      redirectUser();
    }
    if(token){
      dispatch(fetchDataCenters())
    }
  }, [token]);

  useEffect(() => {
    if(user){
      dispatch(fetchFSAZones({
        dcName: getUserDCOption(user)?.value
      }))
    }
  }, [user]);

  useEffect(() => {
    if (userFetchError) {
      setModalProps(
        {
          title: 'Error Occured While fetching user details!',
          message: userFetchError,
          open: true,
          type: 'error'
        }
      )
      const redirectTimer = setTimeout(() => {
        setModalProps(null)
        redirectUser();
      }, 1000);
      return () => {
        clearTimeout(redirectTimer);
      }
    }
  }, [userFetchError]);

  useEffect(() => {
    const checkViewport = () => {
      const isMobileView = window.innerWidth <= 768;
      const isIpadView = window.innerWidth > 768 && window.innerWidth <= 1024;

      dispatch(setIsMobile(isMobileView));
      dispatch(setIsIpad(isIpadView))
    };

    checkViewport();

    window.addEventListener('resize', checkViewport);

    return () => {
      window.removeEventListener('resize', checkViewport);
    };
  }, [dispatch]);

  const redirectUser = () => {
    const fullPath = `${location.pathname}${location.search}`;
    if (PUBLIC_URL.includes(location?.pathname)) {
      navigate(fullPath);
    }
    else if (!PUBLIC_URL.includes(location?.pathname)) {
      if (!token) {
        resetLocalStorage()
        //Unauthenticate Scenraio
        navigate(urls?.LOGIN_URL);
      }
      if (token) {
        //Page Realod Scenario post login
        if (!user && !userFetchError) {
          dispatch(fetchLoggedInUserDetails());
          navigate(fullPath);
        }
        else if (userFetchError) {
          resetLocalStorage();
          navigate(urls?.LOGIN_URL);
        }
      }
    }
  };

  const showDrawer = ![...PUBLIC_URL, urls?.RESET_PASSWORD_URL].includes(location.pathname) && routes!==undefined

  return (
    <ErrorBoundary>
      {showDrawer && !isMobile && (
        <CustomDrawer isOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} />
      )}
      <Routes>
        {/* If component exists render it else render  PageNotFound */}
        {routes?.map((route, index) => {
          return route?.map((item) => {
            return item?.children ? (
              item?.children?.map((childMenu) => {
                return (
                  <Route
                    key={index}
                    path={childMenu?.path || '/'}
                    element={childMenu?.component || <PageNotFound />}
                    exact
                  />
                );
              })
            ) : (
              <Route
                key={index}
                path={item?.path || '/'}
                element={item?.component || <PageNotFound />}
                exact
              />
            );
          });
        })}
        <Route exact path={urls?.LOGIN_URL} element={<Login />} />
        <Route exact path={urls?.REGISTER_URL} element={<Register />} />
        <Route exact path={urls?.GENERATE_QR_CODE_URL} element={<TwoFactorAuthenticator />} />
        <Route path={urls?.ERROR_PAGE_URL} element={<ErrorPage />} /> {/* Add this route */}
        <Route exact path={urls?.RESET_PASSWORD_URL} element={<ResetPassword />} />

        {/* Will keep this comment for some time
        <Route exact path='/admin/uploadManifest' element={<UploadManifest token={token} />} />
        <Route exact path='/tracking' element={<UpdatePackage token={token} />} />
	*/}
        {/* <Route exact path='/test' element={<TestRoute />} /> */}
      </Routes>
      {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
    </ErrorBoundary>

  );
}