import React from 'react';

const RoutingIcon = (fill) => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 23.633" fill={fill}>
  <path id="road-map-icon" d="M4.549,17.657a5.908,5.908,0,0,1-1.3,1.074.182.182,0,0,1-.209,0,7.317,7.317,0,0,1-1.806-1.6,5.738,5.738,0,0,1-1.214-2.62,3.489,3.489,0,0,1,.435-2.452,3.026,3.026,0,0,1,.681-.744,3.278,3.278,0,0,1,2.048-.767,2.893,2.893,0,0,1,1.952.8,2.79,2.79,0,0,1,.521.635,3.856,3.856,0,0,1,.371,2.809,6.16,6.16,0,0,1-1.484,2.862Zm-1.38,2.095a1.931,1.931,0,0,1,1.794,1.212.639.639,0,0,1,.105,0h1.55a.781.781,0,0,1,0,1.55H5.068a.679.679,0,0,1-.143-.014,1.933,1.933,0,1,1-1.757-2.733ZM20.95,9.1a1.935,1.935,0,1,1-1.773,2.708H16.759a.781.781,0,1,1,0-1.548h2.419A1.933,1.933,0,0,1,20.95,9.1Zm-6.009,6.509a.781.781,0,1,0,0,1.55h1.864a.781.781,0,1,0,0-1.55Zm-1.185-3.784a.781.781,0,0,0-.2-1.536,3.43,3.43,0,0,0-1.8.81.781.781,0,0,0,.676,1.343.8.8,0,0,0,.34-.172,1.884,1.884,0,0,1,.988-.445Zm-1.562,2.442a.781.781,0,0,0-1.488.461l.035.105a3.46,3.46,0,0,0,1.064,1.531.781.781,0,0,0,.988-1.195,1.919,1.919,0,0,1-.607-.9ZM9.716,20.956a.781.781,0,0,0,0,1.55h1.55a.781.781,0,1,0,0-1.55Zm4.65,0a.781.781,0,0,0,0,1.55h1.55a.781.781,0,1,0,0-1.55Zm4.4-.086a.781.781,0,0,0-.023,1.468.765.765,0,0,0,.494,0,3.444,3.444,0,0,0,1.63-1.107.781.781,0,1,0-1.2-.976l-.039.045a1.915,1.915,0,0,1-.861.564ZM19.9,18.2a.781.781,0,0,0,1.367-.718A3.5,3.5,0,0,0,19.9,16.062a.781.781,0,1,0-.769,1.345,1.939,1.939,0,0,1,.754.793ZM22.371,7.106a5.984,5.984,0,0,1-1.3,1.074.2.2,0,0,1-.209,0,7.3,7.3,0,0,1-1.8-1.611,5.748,5.748,0,0,1-1.214-2.62A3.477,3.477,0,0,1,18.281,1.5a2.991,2.991,0,0,1,.672-.746A3.266,3.266,0,0,1,21.011,0a3,3,0,0,1,2.474,1.431,3.858,3.858,0,0,1,.371,2.809A6.173,6.173,0,0,1,22.371,7.1Zm-1.5-5.517a1.533,1.533,0,1,1-1.086.449,1.535,1.535,0,0,1,1.086-.449ZM3.051,12.132a1.533,1.533,0,1,1-1.086.449A1.535,1.535,0,0,1,3.051,12.132Z" transform="translate(0.039)" fillRule="evenodd"/>
</svg>
);

export default RoutingIcon;




