export const currencies = [
    {
        label: 'USD',
        value: 'USD'
    },
    {
        label: 'EUR',
        value: 'EUR'
    },
    {
        label: 'JPY',
        value: 'JPY'
    },
    {
        label: 'GBP',
        value: 'GBP'
    },
    {
        label: 'CHF',
        value: 'CHF'
    },
    {
        label: 'CAD',
        value: 'CAD'
    },
    {
        label: 'AUD',
        value: 'AUD'
    },
    {
        label: 'CNY',
        value: 'CNY'
    },
    {
        label: 'INR',
        value: 'INR'
    },
    {
        label: 'BRL',
        value: 'BRL'
    },
    {
        label: 'ZAR',
        value: 'ZAR'
    },
    {
        label: 'RUB',
        value: 'RUB'
    },
    {
        label: 'MXN',
        value: 'MXN'
    },
    {
        label: 'SGD',
        value: 'SGD'
    },
    {
        label: 'HKD',
        value: 'HKD'
    },
    {
        label: 'NZD',
        value: 'NZD'
    },
    {
        label: 'KRW',
        value: 'KRW'
    },
    {
        label: 'SAR',
        value: 'SAR'
    },
] 