import React from 'react';
import { CircularProgress } from '@mui/material';
import './Loader.scss'; // Import custom CSS file for overlay styles

export const Loader = ({ isLoading }) => {
    return (
        <div data-testid = "loader" className={`loader-overlay ${isLoading ? 'show' : ''}`}>
            {isLoading && <CircularProgress />}
        </div>
    );
};