import React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SideMenu from '../../sideMenu/sideMenu.jsx';
import { DrawerHeader } from './Layout';

const drawerWidth = 230;
const drawerMinHeight = '50px';

const CustomMobileDrawer = ({ container, mobileOpen, handleDrawerToggle }) => {
    return (
        <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            <DrawerHeader style={{ minHeight: drawerMinHeight }}>
                <img src="/static/images/k-logo.png" alt="logo" />
                <IconButton onClick={handleDrawerToggle}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <SideMenu open={mobileOpen} />
        </Drawer>
    );
};

export default CustomMobileDrawer;
