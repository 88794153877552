import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'features/auth/authAction'
import { getPackageSummary } from './reportingAction';

const initialState = {
    reportsData: {},
    loading: false,
    error: null,
}

const reportingSlice = createSlice({
    name: 'route',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPackageSummary.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getPackageSummary.fulfilled, (state, action) => {
                state.loading = false;
                state.reportsData = action?.payload
            })
            .addCase(getPackageSummary.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })

    }
})

export default reportingSlice.reducer;