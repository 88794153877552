



import React from 'react';

const BackArrow = ({ fill }) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="11.256" viewBox="0 0 18 11.256">
        <path id="arrow-left-long-solid" d="M.355,100.758a1.127,1.127,0,0,0,0,1.593l4.5,4.5a1.126,1.126,0,1,0,1.593-1.593l-2.581-2.581H16.9a1.125,1.125,0,1,0,0-2.25H3.867l2.581-2.581a1.126,1.126,0,0,0-1.593-1.593l-4.5,4.5Z" transform="translate(-0.025 -95.925)" fill={fill || '#5d48ff'} />
    </svg>)
};

export default BackArrow;


