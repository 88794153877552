import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { fetchFSASubZones } from 'features/fsaZone/fsaZoneAction'
import DataTable from 'component/dataTable/dataTable'
import { Loader } from 'component/common/Loader/Loader'
import { TableHeader } from 'component/dataTable/tableHeader';
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { getDateWithTimeStamp, getFSAZones, getUserDCOption } from 'utils/common'
import '../userManagement/userAccount/UserAccount.scss'
import { resetState } from 'features/fsaZone/fsaZoneSlice';
import { AddFSASubZone } from './AddFSASubZone'
import { Tooltip } from '@mui/material';
import { PrimaryButton } from 'component/common/Button/Button'
import useFieldChange from 'hooks/useFieldChange';
import { TableFilter } from 'component/dataTable/tableFilter';

const rowKey = "id"

const formInitialState = {
    fsaZones: [],
}

export const FSASubZone = () => {
    const dispatch = useDispatch();
    const { fsaZones, fsaSubZones, loading, success } = useSelector(
        (state) => state?.fsaZones
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [sorteData, setSortedData] = useState(fsaSubZones);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [modalProps, setModalProps] = useState(null)
    const [addModelOpen, setAddModelOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(() => {
        dispatch(fetchFSASubZones({
            "fsaZones": [],
            dcName: getUserDCOption(user)?.value
        }))
        return () => {
            dispatch(resetState())
        };
    }, [])

    const handleResetFilter = () => {
        setFormValues({
            fsaZones: [],
        }
        )
    }

    const handleApplyFilterClick = () => {
        const selectedFSAZones = formValues?.fsaZones?.map(item => item.label)?.join(',') || []
        dispatch(fetchFSASubZones({ fsaZone: selectedFSAZones, dcName: getUserDCOption(user)?.value }));
      };

    useEffect(() => {
        if (success) {
            dispatch(fetchFSASubZones({
                dcName: getUserDCOption(user)?.value
            }))
            setModalProps(
                {
                    title: 'Success',
                    message: 'FSA SubZones Updated Sussessfully!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                setAddModelOpen(false)    
                dispatch(resetState())
                setModalProps(null)
                // dispatch(fetchFSASubZones())
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [success])

    const handleAddClick = (item) => {
        setSelectedItem(item)
        setAddModelOpen(!addModelOpen)    
    }

    const columns = [
        {
            accessor: "id",
            title: "Id",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.id || '--',
            render: (item) => item?.id || '--'
        },
        {
            accessor: "fsaZoneId",
            title: "FSA Zone Id",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.fsaZone?.id || '--',
            render: (item) => item?.id || '--'
        },
        {
            accessor: "code",
            title: "Code",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.code || '--',
            render: (item) => item?.code || '--'
        },
        {
            accessor: "fsaZoneName",
            title: "FSA Zone",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.fsaZone?.fsaZoneName || '--',
            render: (item) => item?.fsaZone?.fsaZoneName || '--'
        },
        {
            accessor: 'priority',
            title: "Priority",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.priority || '--',
            render: (item) => item?.priority || '--'
        },
        {
            accessor: 'createDate',
            title: "Created Date",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
            render: (item) => item?.createDate ? getDateWithTimeStamp(item?.createDate, user) : '--'
        },
        {
            accessor: 'action',
            title: "Action",
            width: "1rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action' >
                <Tooltip title="Edit"><img src="/static/images/edit-icon.svg" alt='edit' onClick={() => handleAddClick(original)} /></Tooltip>
            </div>
        }
    ]

    const tableFilterProps = {
        fields: [
            {
                label: 'FSA Zone',
                value: formValues?.fsaZones,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '15rem',
                isMultiselect: true,
                options: getFSAZones(fsaZones),
                name: 'fsaZones'
            }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px',
    }

    return (
        <Layout headerTitle={'FSA Sub Zones'} showBackArrow={false} action={<div><PrimaryButton type="button" variant='primary' label={'Add FSA SubZone'} height={'30px'} onClick={() => handleAddClick(null)} /></div>}
        >
            <div className='container'>
                <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Totes' />
                <TableFilter {...tableFilterProps} />
                <div className='content'>
                    <DataTable columns={columns} data={fsaSubZones || []} isCollpsable={false} onSortChange={setSortedData} uniqueKey={rowKey} setUpdatedColumns={setUpdatedColumns} showPagination={false} rowKey={rowKey} />
                </div>
            </div>
            {addModelOpen ? <AddFSASubZone isModelOpen={addModelOpen} handleClose={handleAddClick} fsaSubZone={selectedItem} /> : null}
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}