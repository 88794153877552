// authActions.js
import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserSerice } from '../../services/userService'
import { UserManagementService } from 'services/userManagementService'
import { resetLocalStorage } from 'utils/authHelper'

const userService = new UserSerice();
const userManagementService = new UserManagementService();

export const login = createAsyncThunk(
    'auth/login',
    async (formData, { rejectWithValue }) => {
        //Stored token mst be clear before login
        resetLocalStorage()
        try {
            const response = await userService.loggedInUser(formData);
            localStorage?.setItem('loginName',formData?.username)
            localStorage?.setItem('token', response?.data?.id_token);
            return response?.data
        } catch (error) {
            if (error?.response) {
                if(error?.response?.data?.id_token){
                    localStorage?.setItem('loginName',formData?.username)
                    localStorage?.setItem('token', error?.response?.data?.id_token);
                    return rejectWithValue(error?.response?.data)
                }
                if (error.response && error?.response?.data?.fieldErrors) {
                    return rejectWithValue(error?.response?.data?.fieldErrors)
                } else {
                    return rejectWithValue(error?.response?.data)
                }
            }
            else {
                return rejectWithValue('Something Went wrong! Please try after sometime')
            }
        }
    }
)

export const fetchLoggedInUserDetails = createAsyncThunk(
    'auth/fetchUser',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await userService.getLoggedInUser();
            // Dispatch action to fetch other details only for customer
            if (response?.data && response?.data?.authorities?.[0] === 'ROLE_CUSTOMER') {
                dispatch(fetchUserProfile(response?.data));
            }
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail)
        }
    }
)

export const register = createAsyncThunk(
    'auth/register',
    async (formData, { rejectWithValue }) => {
        try {
            await userService.registerUser(formData);
            return true
        } catch (error) {
            if (error.response && error?.response?.data?.fieldErrors) {
                return rejectWithValue(error?.response?.data?.fieldErrors)
            } else {
                return rejectWithValue(error?.response?.data?.title)
            }
        }
    }
)

export const fetchUserProfile = createAsyncThunk(
    'auth/fetchUserProfile',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await userManagementService.getProfileDetails(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            const response = await userManagementService.logout()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const gererateQRCode = createAsyncThunk(
    'auth/gererateQRCode',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await userService.gererateQRCode(formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title)
        }
    }
)

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await userService.resetPassword(formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title)
        }
    }
)