import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { fetchVehicles } from 'features/vehicle/vehicleAction'
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from 'component/common/Button/Button'
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { getDate, covertToString, checkPermission, formatDate, getDateWithTimeStamp, getConstantValue, handleApplyFilter,iconStyle } from 'utils/common'
import { TableHeader } from 'component/dataTable/tableHeader'
import { Audit } from 'component/audit/audit'
import { ConfirmationModal } from 'component/common/Modal/ConfirmationModal'
// import DeleteIcon from '@mui/icons-material/Delete';
import 'component/userManagement/userAccount/UserAccount.scss'
import { resetVehicleData } from 'features/vehicle/vehicleSlice'
import { Tooltip } from '@mui/material';
import { materialTypes } from 'constants/materialTypeConstant';
import { vehicleStatusConstant } from 'constants/vehicleStatusConstant'
import { TableFilter } from 'component/dataTable/tableFilter';
import { vehicleTypeConstants } from 'constants/vehicleTypeConstants'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { roleConstantEnum } from 'constants/roleConstants';



const formInitialState = {
    insuranceValidTill: '',
    materialType: '',
    onBoardingDate: '',
    registrationNumber: '',
    vehicleModel: '',
    vehicleStatus: ''
}

export const Vehicles = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { vehicles, error, loading, vehicleCount, deleteSuccess } = useSelector(
        (state) => state?.vehicles
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const [page, setPage] = useState(0);
    const [modalProps, setModalProps] = useState(null)
    const [sorteData, setSortedData] = useState(vehicles);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [confirmationModalProps, setConfirmationModalProps] = useState(null);
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [applyFilter, setApplyFilter] = useState(false);

    const hasEditPermission = checkPermission(user, 'VEHICLE MANAGEMENT')

    useEffect(() => {
        const data = {}
        if (formValues?.registrationNumber) {
            data['registrationNumber'] = formValues?.registrationNumber?.trim()
        }
        if (formValues?.vehicleModel) {
            data['vehicleModel'] = formValues?.vehicleModel?.trim()
        }
        if (formValues?.vehicleStatus?.value) {
            data['vehicleRosterStatus'] = formValues?.vehicleStatus?.value
        }
        if (formValues?.materialType?.value) {
            data['materialType'] = formValues?.materialType?.value
        }
        if (formValues?.insuranceValidTill) {
            data['insuranceValidTill'] = formatDate(formValues?.insuranceValidTill) || null
        }
        if (formValues?.onBoardingDate) {
            data['onBoardingDate'] = formatDate(formValues?.onBoardingDate) || null
        }

        dispatch(fetchVehicles({
            page: page,
            size: rowsPerPage,
            widget: 'vehicle',
            ...data
        }))
    }, [page, rowsPerPage, applyFilter])

    useEffect(() => {
        if (deleteSuccess) {
            setConfirmationModalProps(null)
            setModalProps(
                {
                    title: 'Success',
                    message: 'Vehicle deleted successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                dispatch(fetchVehicles({
                    page: page,
                    size: rowsPerPage,
                    widget: 'vehicle'
                }))
                dispatch(resetVehicleData())
                setModalProps(null)
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [deleteSuccess]);

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
        else {
            // No error, close modal or reset modal props
            setModalProps({
                title: '',
                message: '',
                open: false,
                type: ''
            });
        }
    }, [error]);

    const handleApplyFilterClick = () => {
        handleApplyFilter(formValues, formInitialState, setFormErrors, setApplyFilter, setPage, applyFilter);
    }

    const handleResetFilter = () => {
        setFormValues(formInitialState)
        setFormErrors({})
    }

    const columns = [
        {
            accessor: "id",
            title: "ID",
            width: "4rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.id || '-'
        },
        {
            accessor: "registrationNumber",
            title: "Registration Number",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.registrationNumber || '-'
        },
        {
            accessor: 'distributionCenter.dcName',
            title: "DC Name",
            Cell: ({ cell: { row: { original } } }) => original?.distributionCenter?.dcName || '--',
            render: (item) => item?.distributionCenter?.dcName || '--'
        },
        {
            accessor: "vehicleType",
            title: "Vehicle Type",
            width: "6rem",
            Cell: ({ cell: { value } }) => value ? getConstantValue(vehicleTypeConstants, value) : '--',
            render: (item) => item?.vehicleType ? getConstantValue(vehicleTypeConstants, item?.vehicleType) : '--'
        },
        {
            accessor: "vehicleModel",
            title: "Vehicle Model",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.vehicleModel || '-'
        },
        {
            accessor: "materialType",
            title: "Material Type",
            width: "4rem",
            Cell: ({ cell: { value } }) => value ? covertToString(value) : '--',
            render: (item) => item?.materialType ? covertToString(item?.materialType) : '--'
        },
        {
            accessor: 'fuelCapacity',
            title: "Fuel Capacity",
            Cell: ({ cell: { row: { original } } }) => original?.fuelCapacity ? `${original?.fuelCapacity} ${original?.fuelCapacityUnit}` : '--',
            render: (item) => item?.fuelCapacity ? `${item?.fuelCapacity} ${item?.fuelCapacityUnit}` : '--'
        },
        {
            accessor: 'maxCapacityVolume',
            title: "Max Capacity",
            Cell: ({ cell: { row: { original } } }) => `${original?.maxCapacityVolume} ${original?.volumeUnit}` || '--',
            render: (item) => `${item?.maxCapacityVolume} ${item?.volumeUnit}` || '--'
        },
        {
            accessor: 'maxCapacityWeight',
            title: "Max Weight",
            Cell: ({ cell: { row: { original } } }) => `${original?.maxCapacityWeight} ${original?.weightUnit}` || '--',
            render: (item) => `${item?.maxCapacityWeight} ${item?.weightUnit}` || '--'
        },
        {
            accessor: 'runningRate',
            title: "Running Rate",
            Cell: ({ cell: { row: { original } } }) => `${original?.runningRate} ${original?.runningRateUnit}` || '--',
            render: (item) => `${item?.runningRate} ${item?.runningRateUnit}` || '--'
        },
        {
            accessor: 'vehicleRosterStatus',
            title: "Vehicle Roaster Status",
            Cell: ({ cell: { value } }) => value ? covertToString(value) : '--',
            render: (item) => item?.vehicleRosterStatus? covertToString(item?.vehicleRosterStatus) : '--'
        },
        {
            accessor: 'insuranceValidTill',
            title: "Insurance Valid Till",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
            render: (item) => item?.insuranceValidTill ? getDate(item?.insuranceValidTill) : '--'
        },
        {
            accessor: 'onBoardingDate',
            title: "OnBoarding Date",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
            render: (item) => item?.onBoardingDate ? getDate(item?.onBoardingDate) : '--'
        },
        {
            accessor: 'offBoardingDate',
            title: "OffBoarding Date",
            Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
            render: (item) => item?.offBoardingDate ? getDate(item?.offBoardingDate) : '--'
        },
        {
            accessor: 'lastModified',
            title: "Last Modified",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
            render: (item) => item?.lastModified ? getDateWithTimeStamp(item?.lastModified, user) : '--'
        },
        hasEditPermission && {
            accessor: 'action',
            title: "Action",
            width: "0.5rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action' >
            {user?.authorities.includes(roleConstantEnum?.ROLE_ADMIN) ?<Tooltip title="Edit"><img src="/static/images/edit-icon.svg" alt='edit' onClick={() => handleAddVehicleClick(original?.id)} /></Tooltip> : null }
                {/* <Tooltip title="Delete"><DeleteIcon style={iconStyle} fontSize="small" onClick={() => handleDeleteVehicleClick(original?.id)} /></Tooltip> */}
                <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.id, 'entityType': 'vehicles' }))} /></Tooltip>
            </div>
        }
    ]?.filter(Boolean)

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleAddVehicleClick = (id) => {
        if (id) {
            navigate(`${urls?.ADD_VEHICLE_WIDGET_URL}?id=${id}`)
        }
        else {
            navigate(urls?.ADD_VEHICLE_WIDGET_URL)
        }
    }

    // const handleConfirmationClose = () => {
    //     setConfirmationModalProps(null)
    // }

    // const handleDeleteVehicleClick = (vehicleId) => {
    //     if (vehicleId) {
    //         setConfirmationModalProps(
    //             {
    //                 title: 'Delete Vehicle',
    //                 message: 'Are you sure, you want to delete this vehicle?',
    //                 open: true,
    //                 onConfirm: handleDeleteVehicle,
    //                 selectedId: vehicleId,
    //                 onClose: handleConfirmationClose
    //             }
    //         )
    //     }
    // }

    // const handleDeleteVehicle = (vehicleId) => {
    //     dispatch(deleteVehicle(vehicleId))
    // }

    const tableFilterProps = {
        fields: [{
            label: 'Registration Number',
            value: formValues?.registrationNumber,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'registrationNumber',
            error: formError?.registrationNumber,
            placeholder: 'Registration Number'
        },
        {
            label: 'Vehicle Model',
            value: formValues?.vehicleModel,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'vehicleModel',
            error: formError?.vehicleModel,
            placeholder: 'Vehicle Model'
        },
        {
            label: 'Material Type',
            value: formValues?.materialType,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '12rem',
            options: materialTypes,
            name: 'materialType'
        },
        {
            label: 'Vehicle Status',
            value: formValues?.vehicleStatus,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '12rem',
            options: vehicleStatusConstant,
            name: 'vehicleStatus'
        },
        {
            label: 'Insurance Vaild Till',
            value: formValues?.insuranceValidTill,
            handleFieldChange: handleFieldChange,
            type: 'date',
            width: isMobile ? '9rem' : '12rem',
            name: 'insuranceValidTill',
            placeholder: 'DD-MM-YYYY',
            error: formError?.insuranceValidTill
        },
        {
            label: 'Onboarding Date',
            value: formValues?.onBoardingDate,
            handleFieldChange: handleFieldChange,
            type: 'date',
            width: isMobile ? '9rem' : '12rem',
            name: 'onBoardingDate',
            placeholder: 'DD-MM-YYYY',
            error: formError?.onBoardingDate
        }
    ],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px',
    }

    return (
        <Layout headerTitle={'Vehicle Profiles'} showBackArrow={false} action={hasEditPermission ? <div><PrimaryButton type="button" variant='primary' label={'Add Vehcile'} height={'30px'} onClick={() => handleAddVehicleClick()} /></div> : null}>
                <div className='container'>
                    <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Vehicle Profiles' onRefreshClick={handleApplyFilterClick}/>
                    <TableFilter {...tableFilterProps} />
                    <div className='content'>
                        <DataTable columns={columns} data={vehicles || []} isCollpsable={false} uniqueKey={'id'} onSortChange={setSortedData} setUpdatedColumns={setUpdatedColumns} showPagination={vehicles?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={vehicleCount} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
                    </div>
                </div>
            <Audit />
            {confirmationModalProps ? <ConfirmationModal {...confirmationModalProps} /> : null}
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}