import { createAsyncThunk } from '@reduxjs/toolkit'
import { FSAZoneService } from 'services/fsaZoneService'
const fsaZoneServices = new FSAZoneService();

export const fetchFSAZones = createAsyncThunk(
    'fsaZones/fetchFSAZones',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await fsaZoneServices.getFSAZones(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addFSAZone = createAsyncThunk(
    'fsaZones/addFSAZone',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await fsaZoneServices.addFSAZone(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updateFSAZone = createAsyncThunk(
    'fsaZones/updateFSAZone',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await fsaZoneServices.updateFSAZone(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchFSASubZones = createAsyncThunk(
    'fsaSubZones/fetchFSASubZones',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await fsaZoneServices.getFSASubZones(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addFSASubZone = createAsyncThunk(
    'fsaZones/addFSASubZone',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await fsaZoneServices.addFSASubZone(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updateFSASubZone = createAsyncThunk(
    'fsaZones/updateFSASubZone',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await fsaZoneServices.updateFSASubZone(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)