import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { AuthLayout } from 'component/common/Layout/AuthLayout.jsx'
import { gererateQRCode } from 'features/auth/authAction'
import { Loader } from 'component/common/Loader/Loader'
import { GenerateQRCode } from './GenerateQRCode.jsx'

import './login.scss';

export default function TwoFactorAuthenticator() {
    const dispatch = useDispatch();
    const { error, loading, qrCodeData } = useSelector(
        (state) => state?.auth
    )

    const handleGenerateQRCode = (userName) => {
        dispatch(gererateQRCode(userName));
    }

  
    return (
        <AuthLayout>
            <Grid item xs={6} className='loginForm'>
                <div className='loginFormmWrap'>
                    <Box>
                        <h1>Two-Factor Authentication</h1>
                        <GenerateQRCode qrCodeData={qrCodeData} handleGenerateQRCode={handleGenerateQRCode} />
                        <div className="forgetLink">
                            {/* <span className="reg-link">Don’t have account? <a href='/register'>Sign up</a></span> */}
                            <span className="fp-link">Back To <a href='/login'>Login</a></span>
                            {/* <span className='fp-link'><a href='/forget'>Forgot Password?</a></span> */}
                        </div>
                        {error ? <><br /><Alert severity="error">{error}</Alert></> : null}
                        <div className='loginSupport'>
                            <p>If you are having trouble please contact<br></br>
                                <a href="mailto:support@koorier.ca">support@koorier.ca</a>
                            </p>
                        </div>
                        {loading ? <Loader isLoading={loading} /> : null}
                    </Box>
                </div>
            </Grid>
        </AuthLayout>
    )
}