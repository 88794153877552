import React from 'react';
import { Card, Typography, CardHeader, IconButton } from '@mui/material';

const CardComponent = ({ number, label, icon }) => {
  return (
    <div style={{ flex: '1' }}>

      <Card style={{ borderRadius: '1rem', backgroundColor: '#5D48FF' }}>
        <CardHeader
          avatar={
            <Typography style={{ fontSize: '2rem', color: '#ffffff' }}>{number}</Typography>
          }
          action={
            <IconButton sx={{ size: 'small' }}>
              {icon}
            </IconButton>
          }
          title={
            <Typography style={{ fontSize: '1.2rem', color: "#ffffff" }} >
              {label}
            </Typography>
          }
        />
      </Card>
    </div>
  );
};

export default CardComponent;

