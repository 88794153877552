import { getDateWithTimeStamp } from "utils/common";
import { routeStopStatusEnum } from 'constants/routeStatus'

export const getRoutSummary = (routeSummary) => {
    return [
        { key: 'Total', value: routeSummary?.dailyRouteStatCountMap?.totalRoutes || 0 },
        { key: 'Not Departed', value: routeSummary?.dailyRouteStatCountMap?.notDeparted || 0 },
        { key: 'In Progress', value: routeSummary?.dailyRouteStatCountMap?.inProgress || 0 },
        { key: 'Completed', value: routeSummary?.dailyRouteStatCountMap?.completed || 0 },
        { key: 'At Risk', value: routeSummary?.dailyRouteStatCountMap?.atRisk || 0 },
        {
            key: <span style={{ color: routeSummary?.dailyRouteStatCountMap?.behind > 0 ? 'red' : 'inherit' }}>Behind</span>,
            value: <span style={{ color: routeSummary?.dailyRouteStatCountMap?.behind > 0 ? 'red' : 'inherit' }}>{routeSummary?.dailyRouteStatCountMap?.behind || 0}</span>
        },
        {
            key: <span style={{ color: routeSummary?.dailyRouteStatCountMap?.routeTerminated > 0 ? 'red' : 'inherit' }}>Terminated</span>,
            value: <span style={{ color: routeSummary?.dailyRouteStatCountMap?.routeTerminated > 0 ? 'red' : 'inherit' }}>{routeSummary?.dailyRouteStatCountMap?.routeTerminated || 0}</span>
        },
        { key: 'Late Departing', value: routeSummary?.dailyRouteStatCountMap?.lateDeparting || 0 },
        { key: 'Parcel In Progress', value: routeSummary?.overAllLiveStatCountMap?.inprogress || 0 },
        { key: 'Stop In Progress', value: routeSummary?.overAllLiveStatCountMap?.stopProgress || 0 },
        { key: 'Attempt Success Parcel', value: routeSummary?.overAllLiveStatCountMap?.attemptSuccess || 0 },
        { key: 'Not Attempted', value: routeSummary?.packageStatCountMap?.remaining || 0 },
        { key: 'Re-Attemptable', value: routeSummary?.packageStatCountMap?.reattemptable || 0 },
        { key: 'Undeliverable', value: routeSummary?.packageStatCountMap?.undeliverable || 0 },
        { key: 'Damaged', value: routeSummary?.packageStatCountMap?.damaged || 0 },
        {
            key: <span style={{ color: routeSummary?.packageStatCountMap?.missing > 0 ? 'red' : 'inherit' }}>Missing</span>,
            value: <span style={{ color: routeSummary?.packageStatCountMap?.missing > 0 ? 'red' : 'inherit' }}>{routeSummary?.packageStatCountMap?.missing || 0}</span>
        },
        { key: 'Return to Station', value: routeSummary?.packageStatCountMap?.returnedToStation || 0 },
        { key: 'Total Parcels', value: routeSummary?.packageStatCountMap?.totalPackages || 0 },        
    ]
}

export const getInProgressPackages = (routes) => {
    const result = routes?.map(item => {
        return {
            driverName: item?.liveTrackingWidgetVM?.driverName,
            time: item?.liveTrackingWidgetVM?.currentStopApproachTime || '--',
            totalStops: item?.liveTrackingWidgetVM?.totalStops,
            completedStops: item?.liveTrackingWidgetVM?.totalStopsDelivered,
            remainingStops: item?.liveTrackingWidgetVM?.totalStops - item?.liveTrackingWidgetVM?.totalStopsDelivered,
            timeLeft: item?.liveTrackingWidgetVM?.totalEtaLeft || '--',
            address: item?.liveTrackingWidgetVM?.approachingStopAddress || '--',
            lat: item?.liveTrackingWidgetVM?.lat,
            lng: item?.liveTrackingWidgetVM?.lon,
            routeId: item?.liveTrackingWidgetVM?.routeId,
            routeName: item?.liveTrackingWidgetVM?.routeName,
            driverId: item?.liveTrackingWidgetVM?.driverId,
            routeStatus: item?.liveTrackingWidgetVM?.routeStatus,
            primaryFsaZone: item?.liveTrackingWidgetVM?.primaryFsaZone,
            outOfSequence: item?.liveTrackingWidgetVM?.outOfSequence,
            atRisk: item?.liveTrackingWidgetVM?.atRisk
        }
    })
    return result
}

export const getStopColor = (status) => {
    const statues = {
        'ROUTE_STOP_SERVED': 'green',
        'ROUTE_STOP_APPROACHING': 'yellow',
        'ROUTE_STOP_SKIPPED': 'red'
    }
    return statues[status] || "#1b468d"
}

export const getProgressBarColor = (atRisk, outOfSequence) => {
    if (outOfSequence) {
        return {
            color: null,
            backgroundImage: 'repeating-linear-gradient(45deg, transparent, transparent 5px, #5D48FF 5px, #5D48FF 10px)'
        }
    }
    else if (atRisk) {
        return {
            color: null,
            backgroundImage: `repeating-linear-gradient(
                45deg,
                transparent,
                transparent 5px,
                #5D48FF 5px,
                #5D48FF 10px
              ), 
              repeating-linear-gradient(
                -45deg,
                transparent,
                transparent 5px,
                #5D48FF 5px,
                #5D48FF 10px
              )`
        }
    }
    else {
        return {
            color: '#5D48FF',
            backgroundImage: null
        }
    }
}

export const convertDataToGeoJson = (data) => {
    const features = data?.filter(item => item?.lat !== null && item?.lng !== null) // Filter out items with null lat or lng
        .map(item => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [parseFloat(item?.lng), parseFloat(item?.lat)]
            },
            properties: {
                name: item?.driverName
            }
        }));

    return {
        type: "FeatureCollection",
        features: features
    };

}

export const createPopupContent = (stop, user) => {
    const trackingNumbers = getTrackingNumbers(stop);
    const popupContent = document.createElement('div');
    popupContent.innerHTML = `<b>Tracking ID's:</b> ${trackingNumbers}<br/>
                              <b>Address:</b> ${stop?.routeStopAddress}<br/>
                              <b>Estimated Arrival Time:</b> ${getDateWithTimeStamp(stop?.estimatedArrival, user, true)}<br/>
                              <b>Actual Arrival Time:</b> ${getDateWithTimeStamp(stop?.actualArrivalTime, user, true)}<br/>
                              <b>Stop Location:</b> ${stop?.lat}, ${stop?.lon}`;

    // Check if the stop status is 'complete'
    if (stop?.routeStopStatus === routeStopStatusEnum?.ROUTE_STOP_SERVED) {
        popupContent.innerHTML += `<br/><b style="color:  blue; cursor: pointer;" id="showDetailsBtn">Show Delivery Details</b><br/>`;
    }

    // Apply CSS styles for height and width
    popupContent.style.width = '200px';
    popupContent.style.height = 'auto';

    return popupContent;
};

const getTrackingNumbers = (stop) => {
    const trackingNumbers = stop?.packageDetails?.map(packageDetail => packageDetail.trackingNumber);

    if (!trackingNumbers?.length) {
        return 'NA';
    }

    return trackingNumbers?.join(', ');
};

export const createThumbnailImage = (url) => {
    const thumbnailImg = document.createElement('img');
    thumbnailImg.src = url; // Example thumbnail image URL
    thumbnailImg.alt = 'Thumbnail';
    thumbnailImg.style.cursor = 'pointer';
    return thumbnailImg;
};


// Remove HERE branding elements
export const removeBranding = () => {
    const brandingElements = document.querySelectorAll('.H_copyright, .H_logo');
    brandingElements.forEach(element => {
        element.style.display = 'none';
    });
};

function splitIntoBatches(coords, batchSize = 25) {
    const batches = [];
    for (let i = 0; i < coords?.length; i += batchSize) {
        batches.push(coords.slice(i, i + batchSize));
    }
    return batches;
}

export async function fetchRoutesInBatches(allCoords, apiToken) {
    const batches = splitIntoBatches(allCoords);
    let allRoutes = [];

    for (const batch of batches) {
        const waypoints = batch?.map(coord => `${coord[0]},${coord[1]}`).join(';');
        const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${waypoints}?geometries=geojson&access_token=${apiToken}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            const route = data?.routes[0]?.geometry?.coordinates;
            allRoutes = allRoutes.concat(route);
        } catch (error) {
            console.error('Error fetching route:', error);
        }
    }

    return allRoutes;
}