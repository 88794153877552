import React from 'react'
import styled from "styled-components";
import { getPackageAddresDetails } from 'utils/packageHelper'

const Container = styled.div`
background-color: lightyellow;
padding: 0.5rem;
display: flex;
flex-direction: row;
justify-content: flex-start;
flex-wrap: wrap;
gap: 1rem;
`

const TextComponent = ({ text, value }) => {
    return (
        <div data-testid = {`field-${text}`}>
            <span data-testid = {text} style={{ fontWeight: 500 }} >{text}</span> : <span style={{ fontWeight: 300 }}>{value}</span>
        </div>
    );
};

const PackageAddressAccordian = ({ data }) => {
    const packageDetails = getPackageAddresDetails(data)

    return (
        <Container>
            {
                packageDetails?.map(({ key, value }) => {
                    return (<TextComponent key={key} text={key} value={value} />)
                })
            }
        </Container>
    );
};

export default PackageAddressAccordian
