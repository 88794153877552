export const transformedErrors = (errorsObject) => {
    if(errorsObject){
        return Object.keys(errorsObject)?.map(key => ({
            field: key,
            message: errorsObject[key]
        }));
    }
}

export const resetLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loginName');
}

export const PUBLIC_URL = ['/login', '/register', '/error', '/generateQRCode']
