import { createAsyncThunk } from '@reduxjs/toolkit'
import { PackageServices } from 'services/packageServices'
const packageServices = new PackageServices();

export const fetchPackages = createAsyncThunk(
    'packages/fetchPackages',
    async (formData, { rejectWithValue }) => {
        try {
            // const response = await packageServices.getAllPackages(formData?.page, formData?.size, formData?.filter, formData?.usePagination)
            let response = await packageServices.getAllPackages(formData)
            // Access the X-Total-Count header from the response headers response.headers.get('X-Total-Count')
            return { packages: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updatePackage = createAsyncThunk(
    'packages/updatePackage',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.updatePackage(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updateBusinessLocation = createAsyncThunk(
    'packages/updateBusinessLocation',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.updateBusinessHours(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updateReturnPackage = createAsyncThunk(
    'packages/updateReturnPackage',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.updateReturnPackage(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
)

export const removeSignatureAttribute = createAsyncThunk(
    'packages/removeSignatureAttribute',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await packageServices.removeSignatureAttribute(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
);


export const updateNotificationStatus = createAsyncThunk(
    'packages/updateNotificationStatus',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await packageServices.updateNotificationStatus(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
);

export const fetchAssetImages = createAsyncThunk(
    'packages/fetchAssetImages',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.getAssetImages(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchPackageByTrackingNumber = createAsyncThunk(
    'packages/fetchPackageByTrackingNumber',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.getPackageByTrackingNumber(formData)
            return response?.data?.[0]
        } catch (error) {
            if (error?.response?.status === 400) {
                //If content not found , error is sent in key title
                return rejectWithValue(error?.response?.data?.title)
            }
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchScannedPackages = createAsyncThunk(
    'packages/fetchScannedPackages',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await packageServices.getReturnPackages(formData)
            return { packages: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchUnScannedPackages = createAsyncThunk(
    'packages/fetchUnScannedPackages',
    async (formData, { rejectWithValue }) => {
        try {
            let response = await packageServices.getReturnPackages(formData)
            return { packages: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const reclaimTotes = createAsyncThunk(
    'packages/reclaimTotes',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.reclaimTotes(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const scanPackage = createAsyncThunk(
    'packages/scanPackage',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.scanPackage(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const getStreetPerfectDetails = createAsyncThunk(
    'packages/streetPerfectDetails',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.getStreetPerfectDetails(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)


export const markHighPriorityAndSLA = createAsyncThunk(
    'packages/markHighPriorityAndSLA',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.markHighPriorityAndSLA(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)


export const markOnHold = createAsyncThunk(
    'packages/markOnHold',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await packageServices.markOnHold(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const sendSLAEmailViolation = createAsyncThunk(
    'package/SLAViolationEmail',
    async(formData, {rejectWithValue}) => {
        try{
            const response = await packageServices.sendSLAViolation(formData)
            return response.data
        }catch(error){
            return rejectWithValue(error?.response?.data?.title)
        }
    }
)