
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RouteService } from "../../services/routeService.jsx";

const routeService = new RouteService();
export const getRoute = createAsyncThunk(
    'route/getRoute',
    async (id) => {
        const response = await routeService.getRouteById(id);
        return response.data;
    }
)

export const getCurrentLocation = createAsyncThunk(
    'route/getCurrentLocation',
    async (id) => {
        const response = await routeService.getCurrentLocation(id);
        return response.data;
    }
)


export const fetchRoutes = createAsyncThunk(
    'route/getAllRoutes',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.getAllRoutes(formData)
            return { routes: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchRouteSummary = createAsyncThunk(
    'route/getRouteSummary',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.getRouteSummary(formData)
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchReturnTerminalData = createAsyncThunk(
    'route/fetchReturnTerminalData',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.getReturnTerminalData(formData)
            return { routes: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)


export const fetchLiveTrackingRoutes = createAsyncThunk(
    'route/getLiveTrackingRoutes',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.getLiveTrackingRoutes(formData)
            return { routes: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.violations[0].message);
        }
    }
)

export const rejectRoute = createAsyncThunk(
    'route/rejectRoute',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.rejectRoute(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
)

export const removeRoute = createAsyncThunk(
    'route/removeRoute',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.removeRoute(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchLiveTrackingSummary = createAsyncThunk(
    'route/fetchLiveTrackingSummary',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.getLiveTrackingSummary(formData)
            return { routes: response?.data }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const rescueRoute = createAsyncThunk(
    'route/rescueRoute',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.rescueRoute(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail || error?.response?.data?.message);
        }
    }
)

export const rescheduleRoute = createAsyncThunk(
    'route/rescheduleRoute',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.rescheduleRoute(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const markedMissingTotes = createAsyncThunk(
    'route/markedMissingTotes',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.markedMissingTotes(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchRouteStopDetails = createAsyncThunk(
    'route/fetchRouteStopDetails',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.getRouteStopDetails(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const markRouteTerminate = createAsyncThunk(
    'route/markRouteTerminate',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.routeTerminate(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const splitRoute  = createAsyncThunk(
    'route/splitRoute',
    async(formData, {rejectWithValue}) => {
        try{
            const response = await routeService.splitRoute(formData)
            return response?.data
        }catch(error){
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const breakDownRoute  = createAsyncThunk(
    'route/breakDownRoute',
    async(formData, {rejectWithValue}) => {
        try{
            const response = await routeService.breakDownRoute(formData)
            return response?.data
        }catch(error){
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const getRouteHistoricData = createAsyncThunk(
    'route/getRouteHistoricData',
    async (id) => {
        const response = await routeService.getRouteHistoricData(id);
        return { data: response?.data, routeId: id }
    }
)


export const sendSOSAcceptance = createAsyncThunk(
    'route/sendSOSAcceptance',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await routeService.sendSOSAcceptance(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
)

