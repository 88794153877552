import { UPDATE_PRIORITY, UPDATE_SLA_VIOLATION } from './deliveryStatusManagementAction';

const initialState = {
  highPriority: false,
  sla: false,
};

const deliveryStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRIORITY:
      return {
        ...state,
        highPriority: action.payload,
      };
    case UPDATE_SLA_VIOLATION:
      return {
        ...state,
        sla: action.payload,
      };
    default:
      return state;
  }
};

export default deliveryStatusReducer;
