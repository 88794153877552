import React from 'react';
import { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { PrimaryButton } from '../Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getConstantValue, getDate } from 'utils/common';
import { packageStatus } from 'constants/packageStatus';
import { fetchBillingDetails } from 'features/clientManagement/clientManagementAction';

const Billing = ({ businessName, id, showBill }) => {
    const dispatch = useDispatch()
  const { packages } = useSelector((state) => state?.packages);
  const [billDetails, setBillDetails] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(fetchBillingDetails(id))
        .unwrap()
        .then(response => {
          setBillDetails(response);
        })
    }
  }, [id, dispatch]);

  const commonTableStyles = {
    theme: 'grid',
    headStyles: {
      fillColor: [255, 255, 255], 
      textColor: [70, 70, 70],       
      lineWidth: 0.3,             
      lineColor: [100, 100, 100]  
    },
    styles: {
      cellPadding: 3,             
      lineWidth: 0.2,             
      lineColor: [100, 100, 100]  
    },
    tableLineColor: [100, 100, 100], 
    tableLineWidth: 0.2         
  };

  const handleGeneratePDF = () => {

    if (!businessName || !showBill) return;
    
    const doc = new jsPDF();
    const img = new Image();
    img.src = '/static/images/koorier-logo.png';
    img.onload = () => {
      doc.addImage(img, 'PNG', 20, 15, 75, 20);

      const pageWidth = doc.internal.pageSize.width;
      const margin = 20;
      const textRightAlignX = pageWidth - margin;
      const textY = 25;

      const title = 'PARCEL RETURNS';
      const billNumber = `BILL #: ${billDetails.billId}`;
      const billDate = `BILL DATE: ${new Date().toISOString().split('T')[0]}`;
  
      doc.setFontSize(18);
      const titleWidth = doc.getTextWidth(title);
  
      doc.setFontSize(10);
      const billNumberWidth = doc.getTextWidth(billNumber);
      const billDateWidth = doc.getTextWidth(billDate);
  
      doc.setFontSize(18);
      doc.text(title, textRightAlignX - titleWidth, textY);
  
      doc.setFontSize(10);
      doc.text(billNumber, textRightAlignX - billNumberWidth, textY + 6);
      doc.text(billDate, textRightAlignX - billDateWidth, textY + 12);


      //Shipper and Client information
      const clientAddress = billDetails.clientAddress;
      doc.autoTable({
        startY: 50,
        head: [['Shipper', 'Client']],
        body: [['Koorier Inc.\n5160 Explorer Drive\nMississauga, ON\nL4W 4T7', `${clientAddress.businessName}\n${clientAddress.addressOne ?? ''}\n${clientAddress.addressTwo ?? ''}\n${clientAddress.city ?? ''}, ${clientAddress.province ?? ''}\n${clientAddress.postalCode ?? ''}`]],
        ...commonTableStyles
      });

      doc.setFontSize(14);
      doc.setFont('Bold')
      doc.text(`Total Parcels: ${packages.length}`, 15, doc.lastAutoTable.finalY + 20).setFont('Bold');

      //Pacage details
      const tableColumn = ["Tracking ID", "Manifest ID", "Reason for Return", "Last Service Date", "Service count"];
      const tableRows = packages?.filter(parcel => parcel?.bussinessName === businessName)?.map(parcel => [
        parcel.trackingNumber,
        parcel.id,
        getConstantValue(packageStatus, parcel.latestUnsuccessfulStatus),
        getDate(parcel.createDate),
        parcel.serviceCount
      ]);

      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 30,
        head: [tableColumn],
        body: tableRows,
        ...commonTableStyles
      });


      doc.addPage();

      const rightMargin = 10;

      doc.setFontSize(14);
  
      doc.text('Shipper Signature:',rightMargin+15,rightMargin + 10);
  
      const dateX = pageWidth - rightMargin - 75; 
      doc.text('Date:', dateX, rightMargin + 10);

    const signatureLineY = rightMargin + 20; 
    doc.line(rightMargin+15, signatureLineY, pageWidth - rightMargin - 100, signatureLineY);
    doc.line(dateX, signatureLineY, pageWidth - rightMargin, signatureLineY);
    doc.save('parcel_returns.pdf');
    };
  };

  return (
    <div className="parcel-returns">
      <PrimaryButton
        onClick={handleGeneratePDF}
        label={'Bill'}
        height={'30px'}
        disabled={!(businessName && showBill)}
      />
    </div>
  );
};

export default Billing;
