import styled from "styled-components";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartTitle = styled.span`
font-size: 20px;
font-family:  'Degular', sans-serif;
font-weight: 700;
`

export const ChartWrapper = ({ title, link, icon, marginBottom }) => {
    return (<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: marginBottom }}>
        <ChartTitle >{title}</ChartTitle>
        {/* <a href={link} target="_blank" rel="noopener noreferrer">
            {icon}
        </a> */}
    </div>)
}

export const getDoughnutChartOptions = () => {
    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                align: 'start',
                labels: {
                    filter: function (legendItem, data) {
                        legendItem.text += `: ${data.datasets[0].data[legendItem.index]}`;
                        return legendItem;
                    }
                }
            }
        },
        layout: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    }
};


export const getBarChartOptions = (data) => {

    const calculateTotals = (datasets) => {
        return datasets?.map(dataset => dataset?.data?.reduce((total, value) => total + value, 0));
    };

    const totals = calculateTotals(data?.datasets);

    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                align: 'start', // Align the legend to the start (left) of the chart area
                labels: {
                    generateLabels: (chart) => {
                        const originalLabels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
                        return originalLabels.map((label, index) => ({
                            ...label,
                            text: `${label.text}: ${totals[index]}`,
                        }));
                    },
                },
            }
        },
        layout: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    }
};

export const getCombinedChartOptions = (data) => {
    return {
        responsive: true,
        scales: {
            'y-axis-1': {
                type: 'linear',
                position: 'left',
                ticks: {
                    beginAtZero: true,
                },
            },
            'y-axis-2': {
                type: 'linear',
                position: 'right',
                ticks: {
                    beginAtZero: true,
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
                align: 'start',
                labels: {
                    generateLabels: (chart) => {
                        const originalLabels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
                        return originalLabels.map((label, index) => ({
                            ...label,
                            text: `${label.text}`,
                        }));
                    }
                },   
            },
        },
         layout: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    };
};
