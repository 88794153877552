import React, { useState } from "react"
import { useSelector } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import { packageStatus } from 'constants/packageStatus'
import { getConstantValue, getDateWithTimeStamp } from 'utils/common'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import PanoramaIcon from '@mui/icons-material/Panorama';
import { PopupWithImage } from './PopUpWithImage'
import InfoIcon from '@mui/icons-material/Info';
import { getDelievyStatusColorCode } from 'utils/packageHelper'
import './liveTracking.scss'

const IconStyle = {
    height: 15, width: 15, marginRight: '10px', marginTop: '3px'
}

export const PackageDetails = ({ data }) => {
    const { user } = useSelector(
        (state) => state?.auth
    )
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStop, setSelectedStop] = useState(null);

    const handleImageClick = (item) => {
        setOpenDialog(true);
        setSelectedStop(item)
    }

    const handlePopupClose = () => {
        setOpenDialog(false)
        setSelectedStop(null)
    }

    return (
        <div className="package-detils-container" data-testid='package-detils-container'>
            {
                data?.packageDetails?.length ? data?.packageDetails?.map((item) => {
                    return (
                        <React.Fragment key={item?.trackingNumber}>
                            <div key={item?.trackingNumber} className="package-detils-row-wrapper" data-testid='package-detils-row-wrapper'>
                                <div className="package-item-wrapper" data-testid='package-item-wrapper'>
                                    <span className="package-detail-item"><PersonIcon sx={IconStyle} />{item?.consignee}</span>
                                    <span className="package-detail-item"><LocationSearchingIcon
                                        sx={{ color: getDelievyStatusColorCode(item?.packageStatus), ...IconStyle }}

                                    />{item?.packageDetailsId} / {item?.trackingNumber}</span>
                                </div>
                                <div className="package-item-wrapper" data-testid='package-item-wrapper'>
                                    <span className="package-detail-item"><HomeIcon sx={IconStyle} />{item?.ssAddress}</span>

                                    <span className="package-detail-item"><InfoIcon sx={IconStyle} />{item?.specialInstructions || 'NA'}</span>
                                </div>
                                <div className="package-item-wrapper" data-testid='package-item-wrapper'>
                                    <span className="package-detail-item"><LocalShippingIcon sx={IconStyle} />{getConstantValue(packageStatus, item?.packageStatus)} : {item?.statusApplicationDateTime ? getDateWithTimeStamp(item?.statusApplicationDateTime, user) : 'NA'}</span>
                                    <span className="package-detail-item">{item?.imageAsset ? <PanoramaIcon sx={{ cursor: 'pointer', height: 15, width: 15, marginRight: '10px' }} onClick={() => handleImageClick(item)} /> : null}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }) : <span className="package-detail-item">No data available</span>
            }
            {openDialog ? <PopupWithImage parcel={selectedStop} open={openDialog} user={user} onClose={handlePopupClose} /> : null}

        </div>
    )
}