import React from 'react';

const FindLocationIcon = (fill) => (
<svg id="find-location-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24.231" fill={fill}>
  <path id="Path_227" data-name="Path 227" d="M10.764,16.727a.321.321,0,0,1-.343.01C8.016,14.309,5.048,12.41,5.47,9.1a5.234,5.234,0,0,1,5.141-4.68,4.989,4.989,0,0,1,5.147,4.656c.375,3.207-2.337,4.983-5,7.656ZM10.572,0a10.575,10.575,0,0,1,8.851,16.358L24,21.346l-3.157,2.885-4.413-4.855A10.574,10.574,0,1,1,10.572,0Zm6.106,4.488A8.594,8.594,0,1,0,19.2,10.57a8.594,8.594,0,0,0-2.522-6.082ZM10.612,7.581A1.976,1.976,0,1,1,8.636,9.557a1.977,1.977,0,0,1,1.976-1.976Z" transform="translate(0 0)" fillRule="evenodd"/>
</svg>

);

export default FindLocationIcon;


