import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { StyledLabel, ErrorMessage, FieldWrapper } from './SelectField'

export const SwitchButton = ({ label, name,  handleFieldChange, isDisabled, value, required, lableColor, error,width, ...rest }) => {
    return (
        <FieldWrapper width={width} {...rest}>
            <StyledLabel data-testid={`label-${name}`} lablecolor={lableColor}>{label}
                {required && <StarIcon style={{ color: 'red', fontSize: '8px', verticalAlign: 'middle', marginLeft: '5px' }} />}
            </StyledLabel>
            <FormGroup>
                <FormControlLabel control={<Switch checked={value} onChange={handleFieldChange} name={name} disabled={isDisabled}/>
                } />
            </FormGroup>
            {error && <ErrorMessage width={rest?.width}>{error}</ErrorMessage>}
        </FieldWrapper>
    );
};