import React from 'react';
import Button from '@mui/material/Button';
import './Button.scss';

export const PrimaryButton = ({ label, type, onClick, dataTestId, height, disabled, color, padding=null, isSquare=false,  ...rest }) => {
  return (
    <button  type={type} className={disabled ? "buton-primary-disabled" : isSquare ? "buton-primary-square" : "buton-primary"} data-testid={ dataTestId ? dataTestId : `button-${label}`} onClick={onClick} disabled={disabled} style={{ '--height': height, '--color': color, '--padding': padding }} {...rest}>
      {label}
    </button>
  );
};

export const IconButton = ({ variant, startIcon, children, ...rest }) => {
  return (
    <Button className='icon-button' variant={variant || "outlined"} startIcon={startIcon} {...rest}>
      {children}
    </Button>
  )
}