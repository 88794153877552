import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import './Layout.scss'
import { WebLayout } from './WebDrawer.jsx'
import { MobileLayout } from './MobileDrawer.jsx'

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));

export const Layout = ({ action, headerTitle, showBackArrow = true, children, handleBackClick, backUrl, globalFilter }) => {
  const navigate = useNavigate()
  const { isMobile } = useSelector(
    (state) => state?.viewport
  )

  const handleBackArrowClick = () => {
    backUrl ? navigate(backUrl) : navigate(-1);
  }
  return (isMobile ? <MobileLayout action={action}
    headerTitle={headerTitle} showBackArrow={showBackArrow} children={children} handleBackClick={handleBackClick} handleBackArrowClick={handleBackArrowClick} globalFilter={globalFilter}/> : <WebLayout action={action}
      headerTitle={headerTitle} showBackArrow={showBackArrow} children={children} handleBackClick={handleBackClick} handleBackArrowClick={handleBackArrowClick} globalFilter={globalFilter} />)
}
