import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from "component/common/Layout/Layout.jsx";
import { DateRangePickerField } from 'component/common/Input/DateRangePicker/DateRangePickerField'
import { PrimaryButton } from 'component/common/Button/Button'
import { getCurrentDateForFilter } from 'utils/common'
import useFieldChange from 'hooks/useFieldChange';
import { getPackageSummary } from "features/reporting/reportingAction";
import { streetPerfectData, manifestDataData, returnToSenderData, environmentalImpact, ClientSummaryTableData } from './GraphData'
import BarChart from "component/common/Chart/BarChart";
import DoughnutChart from "component/common/Chart/DoughnutChart";
import GraphContainer from "./GraphContainer";
import TableChart from "component/common/Chart/TableChart";
import SummaryTable from "component/common/Chart/SummaryTable";
import CombineLineAndAreaChart from "component/common/Chart/CombineLineAndAreaChart";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import styled from "styled-components";
import { getClienTableSummary } from 'utils/reportingHelper'



const GraphLayout = styled.div`
background-color: #F2F2F2;
padding: 1rem;
display: flex;
gap: 1rem;
flex-direction: column;
`

const GraphRowWrapper = styled.div`
display: flex;
gap: 1rem;
`

const formInitialState = {
    startDate: getCurrentDateForFilter(),
    endDate: getCurrentDateForFilter()
}

const columnsForShortage = ['Tracking Number', 'Manifest ID', 'Return Date', 'DC']
const ClientReportingDashboard = () => {
    const dispatch = useDispatch();

    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { userProfile, user } = useSelector(
        (state) => state?.auth
    )
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);

    const { reportsData } = useSelector(
        (state) => state?.reporting
    )

    const fetchData = (startDate, endDate) => {
        dispatch(getPackageSummary({
            startDate: startDate,
            endDate: endDate,
            businessName: [userProfile?.businessName],
            filter: "province"
        }))
    }

    useEffect(() => {
        fetchData(formValues?.startDate, formValues?.endDate)
    }, [])

    const handleDateRangeSelect = (startDate, endDate) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            startDate: startDate,
            endDate: endDate,
        }));
        fetchData(startDate, endDate)
    }


    return (
        <Layout headerTitle={"Reporting Dashboard"} showBackArrow={false} action={
            <>
                <PrimaryButton className='buton-primary' type="button" label={'Past 7-Days'} height={'30px'} color={'#bdbdbd'} padding={'11px 15px'} />
                <PrimaryButton className='buton-primary' type="button" label={'Past Month'} height={'30px'} disabled={true} padding={'11px 15px'} />
                <PrimaryButton className='buton-primary' type="button" label={'Past Quarter'} height={'30px'} color={'#bdbdbd'} padding={'11px 15px'} />
                <DateRangePickerField name='startDate' type='date' width={isMobile ? '100%' : '12rem'} value={new Date()?.toISOString()?.split('T')?.[0]} handleFieldChange={handleDateRangeSelect} max={new Date()?.toISOString()?.split('T')?.[0]}
                />
            </>}>
            <GraphLayout>
                {/* <GraphRowWrapper>
                    <GraphContainer
                        width={'70%'}
                    >
                        <BarChart data={manifestDataData} title="Manifest Reports"
                            icon={<Inventory2OutlinedIcon />} link="/packages"
                        />
                    </GraphContainer>
                    <GraphContainer
                        width={'30%'}
                    >
                        <DoughnutChart data={streetPerfectData} title="Street Perfect Report"
                            icon={<Inventory2OutlinedIcon />} height={500} link="/packages"
                        />
                    </GraphContainer>

                </GraphRowWrapper>
                <GraphRowWrapper>
                    <GraphContainer
                        width={'50%'}
                    >
                        <TableChart columns={columnsForShortage}
                            data={returnToSenderData}
                            title={`Return To Sender : ${returnToSenderData?.length}`}
                            link="https://example.com"
                            marginBottom={'0.4rem'}
                            icon={<Inventory2OutlinedIcon />} />
                    </GraphContainer>
                    <GraphContainer
                        width={'50%'}
                    >
                        <CombineLineAndAreaChart data={environmentalImpact} title="Environmental Impact"
                            icon={<Inventory2OutlinedIcon />} link="/packages"
                        />
                    </GraphContainer>
                </GraphRowWrapper> */}
                <GraphRowWrapper>
                    <GraphContainer
                        width={'100%'}
                    >
                        <SummaryTable columns={columnsForShortage}
                            data={getClienTableSummary(reportsData)}
                            title={`Performance`}
                            link="https://example.com"
                            marginBottom={'0.4rem'}
                            icon={<Inventory2OutlinedIcon />} />
                    </GraphContainer>
                </GraphRowWrapper>
            </GraphLayout>
        </Layout>
    );
};

export default ClientReportingDashboard;
