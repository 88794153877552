// CollapsibleRow.js
import React from 'react';
import { TableRow, TableCell, IconButton, Collapse, Checkbox } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CollapsibleRow = ({
  row,
  rowKey,
  isExpanded,
  toggleRowExpansion,
  fetchDataByNestedKey,
  ChildComponent,
  columnsLength,
  allowSelect,
  uniqueKey,
  handleRowSelect,
  selectedRowIds,
  isCollpsable,
  isChildComponentExpanded
}) => {
  const { key: rowKUniqueKey, ...restProps } = row.getRowProps();
  const id = uniqueKey ? fetchDataByNestedKey(row?.original, uniqueKey) : null

  return (
    <>
      <TableRow key={rowKUniqueKey} {...restProps}>
        {allowSelect && (
          <TableCell className="table-row-cell" style={{ maxWidth: '1rem' }}>
            <Checkbox
              checked={!!selectedRowIds[id]}
              onChange={() => handleRowSelect(id)}
            />
          </TableCell>
        )}
        {row.cells.map((cell) => {
          const { key, ...restProps } = cell.getCellProps();
          return (<TableCell key={`${rowKUniqueKey}-${key}`} className="table-row-cell" data-testid={cell?.column?.id} {...restProps}>
            {cell.render('Cell')}
          </TableCell>)
        })}
        {isCollpsable && !isChildComponentExpanded && (
          <TableCell className="table-row-cell" align="center">
            <IconButton data-testid={'accordion'} aria-label="expand row" size="small" onClick={() => toggleRowExpansion(row)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={columnsLength}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <ChildComponent id={fetchDataByNestedKey(row?.original, rowKey)} data={row?.original} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>)
}

export default CollapsibleRow;
