import { createAsyncThunk } from '@reduxjs/toolkit'
import { WarehouseOperationService } from 'services/warehouseOperationService'
const warehouseOperationService = new WarehouseOperationService();

export const fetchRollerCageData = createAsyncThunk(
    'warehouseOperation/fetchRollerCageData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.getRollerCageData()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchTotesData = createAsyncThunk(
    'warehouseOperation/fetchTotesData',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.getTotesData(formData)
            return { totes: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addTote = createAsyncThunk(
    'warehouseOperation/addTote',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.addTote(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updateTote = createAsyncThunk(
    'warehouseOperation/updateTote',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.updateTote(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchWarehouseData = createAsyncThunk(
    'warehouseOperation/fetchWarehouseData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.getWarehouseData()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)