import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, CardActions, IconButton } from '@mui/material';
import { PrimaryButton } from '../../component/common/Button/Button.jsx'
import './cardDetail.scss'

export const CardDetail = ({ title, action, footerActionButtons, children, backUrl, handleBackClick, showBackArrow = true }) => {
  const navigate = useNavigate()
  const { isMobile } = useSelector(
    (state) => state?.viewport
  )
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }
  }, []);

  const handleBackArrowClick = () => {
    backUrl ? navigate(backUrl) : navigate(-1);
  }

  return (
    <Card className='cardDetails'>
      <CardContent className='content'>
        {children}
      </CardContent>
      {footerActionButtons ?
        <CardActions>
          {
            <div className='actions'>
              {footerActionButtons?.map((button) => {
                return (<PrimaryButton type="button" variant='primary' label={button?.title} onClick={button?.onClick} />)
              })}
            </div>
          }
        </CardActions>
        : null}
    </Card>
  );
};