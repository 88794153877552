import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { fetchTotesData } from 'features/warehouseOperation/warehouseOperationAction'
import DataTable from 'component/dataTable/dataTable'
import { Loader } from 'component/common/Loader/Loader'
import { Link } from '@mui/material';
import { getDate, covertToString, checkPermission, iconStyle } from 'utils/common'
import { PrimaryButton } from 'component/common/Button/Button.jsx'
import { TableHeader } from 'component/dataTable/tableHeader';
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { AddTote } from './AddTote'
import { TableFilter } from 'component/dataTable/tableFilter';
import { setToteFilter, resetToteFilter } from 'features/warehouseOperation/warehouseOperationSlice'
import '../userManagement/userAccount/UserAccount.scss'
import { validateForm } from 'utils/formValidator'
import { toteStatuses } from 'constants/toteStatusConstant'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';
import { setPackageFilter } from 'features/packages/packagesSlice'

const formInitialState = {
    toteId: '',
    routeId: '',
    toteStatus: '',
}

export const Totes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { totes, error, loading, totesCount, addSuccess, toteFilter } = useSelector(
        (state) => state?.warehouseOperation
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const [sorteData, setSortedData] = useState(totes);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [modalProps, setModalProps] = useState(null)
    const [addToteModalOpen, setAddToteModalOpen] = useState(false)
    const [page, setPage] = useState(0);
    const [selectedTote, setSelectedTote] = useState(null)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const hasEditPermission = checkPermission(user, 'TOTES')

    useEffect(() => {
        const data = {}
        if (toteFilter?.toteStatus) {
            data['toteStatus'] = toteFilter?.toteStatus?.value
        }
        if (toteFilter?.toteId) {
            data['toteId'] = toteFilter?.toteId?.trim()
        }
        if (toteFilter?.routeId) {
            data['routeId'] = typeof toteFilter?.routeId === 'string' ? toteFilter.routeId.trim() : toteFilter?.routeId;
        }
        dispatch(fetchTotesData({
            page: page,
            size: rowsPerPage,
            ...data
        }))
    }, [page, rowsPerPage, toteFilter])

    useEffect(() => {
        if (toteFilter?.routeId) {
            setFormValues((prevValues) => ({
                ...prevValues,
                routeId: toteFilter?.routeId,
            }));
        }
    }, [toteFilter])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        //Fetch data o add or edit operation
        if (addSuccess) {
            dispatch(fetchTotesData({
                page: page,
                size: rowsPerPage
            }))
        }
    }, [addSuccess]);

    useEffect(() => {
        return () => {
            dispatch(resetToteFilter())
        };
    }, [])

    const onRouteClick = (value) => {
        navigate(`${urls?.ROUTES_WIDGET_URL}?routeId=${value}`)
    }

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleResetFilter = () => {
        setFormValues(formInitialState)
    }

    // const handleEditModalClick = (item) => {
    //     setSelectedTote(item)
    //     setAddToteModalOpen(true)
    // }

    const handleApplyFilterClick = () => {
        const notRequiredFields = ['toteId', 'routeId', 'toteStatus']
        const errors = validateForm(formValues, notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            dispatch(setToteFilter(formValues))
            setPage(0)
        }
    }

    const handleNavigation = (params) => {
        dispatch(setPackageFilter(params))
        navigate(urls?.PARCEL_WIDGET_URL)
    }

    const columns = [
        {
            accessor: "id",
            title: "Id",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.id || '--',
            render: (item) => item?.id || '--'
        },
        {
            accessor: "toteId",
            title: "Tote Id",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.toteId || '--',
            render: (item) => item?.toteId || '--'
        },
        {
            accessor: 'routeId',
            title: "Route Id",
            width: "2rem",
            Cell: ({ cell: { value } }) => value ? <Link id='dailyRouteId' onClick={() => onRouteClick(value)} style={iconStyle} sx={{ textDecoration: 'none' }}>{value}</Link > : '--',
            render: (item) => item?.routeId || '--'
        },
        {
            accessor: 'totalPackages',
            title: "Tote Parcels",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.totalPackages ? <Link id='toteId' onClick={() => handleNavigation({
                toteId: original?.id
            })} style={iconStyle} sx={{ textDecoration: 'none' }}>{original?.totalPackages}</Link > : '--',
            render: (item) => item?.totalPackages || '--'
        },
        {
            accessor: 'toteCapacity',
            title: "Tote Capacity",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.toteCapacity ? `${original?.toteCapacity} ${original?.toteCapacityUnit}` : '--',
            render: (item) => item?.toteCapacity ? `${item?.toteCapacity} ${item?.toteCapacityUnit}` : '--'
        },
        {
            accessor: 'toteStatus',
            title: "Tote Status",
            width: "2rem",
            Cell: ({ cell: { value } }) => value ? covertToString(value) : '--',
            render: (item) => item?.toteStatus ? covertToString(item?.toteStatus) : '--'
        },
        {
            accessor: 'inductionDate',
            title: "Induction Date",
            width: "2rem",
            Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
            render: (item) => item?.inductionDate ? getDate(item?.inductionDate) : '--'
        },
        // {
        //     accessor: 'action',
        //     title: "Action",
        //     width: "1rem",
        //     Cell: ({ cell: { row: { original } } }) => <div className='action' >
        //         <Tooltip title="Edit"><img src="/static/images/edit-icon.svg" alt='edit' onClick={() => handleEditModalClick(original)} /></Tooltip>
        //     </div>
        // }
    ]

    const tableFilterProps = {
        fields: [
            {
                label: 'Tote Id',
                value: formValues?.toteId,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '15rem',
                name: 'toteId',
                placeholder: 'Tote Id',
                error: formError?.toteId
            },
            {
                label: 'Route Id',
                value: formValues?.routeId,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '15rem',
                name: 'routeId',
                placeholder: 'Route Id',
                error: formError?.routeId
            },
            {
                label: 'Tote Status',
                value: formValues?.toteStatus,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '15rem',
                options: toteStatuses,
                name: 'toteStatus'
            }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px'
    }


    const handleAddToteModalClick = () => {
        if (addToteModalOpen) {
            setSelectedTote(null)
        }
        setAddToteModalOpen(!addToteModalOpen)
    }

    return (
        <Layout headerTitle={'Totes'} showBackArrow={false} action={hasEditPermission ? <div><PrimaryButton type="button" variant='primary' label={'Add Tote'} height={'30px'} onClick={handleAddToteModalClick} /></div> : null}
        >
            <div className='container'>
                <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Totes' />
                <TableFilter {...tableFilterProps} />
                <div className='content'>
                    <DataTable columns={columns} data={totes || []} isCollpsable={false} onSortChange={setSortedData} uniqueKey={'id'} setUpdatedColumns={setUpdatedColumns} showPagination={totes?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={totesCount} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
                </div>
            </div>
            {addToteModalOpen ? <AddTote isModelOpen={addToteModalOpen} handleClose={handleAddToteModalClick} selectedTote={selectedTote} /> : null}
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}