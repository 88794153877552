import React, { useState } from 'react';
import { PrimaryButton } from 'component/common/Button/Button.jsx'
import styled from "styled-components";
import './login.scss';

const Layout = styled.div`
width: 10rem;
height: 10rem;
display: flex;
align-items: center;
border: 1px solid black;
justify-content: center;
`;


export const GenerateQRCode = ({ qrCodeData, handleGenerateQRCode }) => {
    const [username, setUsername] = useState('')

    const handleFieldChange = (event) => {
        setUsername(event?.target?.value)
    }


    return (
        <div className='codeScanner'>
            <input
                name={'QRCode'}
                className='inputField'
                data-testid={`name-QRCode`}
                placeholder='Enter Username'
                onChange={handleFieldChange}
            />
            <div className='btnModule'>
                <PrimaryButton type="button" onClick={() => handleGenerateQRCode(username)} disabled={!username} label={'Generate QR'} data-testid='login-btn' />
            </div>
            {qrCodeData ? <img className="preview-image"
                src={qrCodeData}
                alt='qr-code'
                style={{ width: '10rem', height: '10rem' }}
            /> :
                <Layout>
                    <span>QR Code</span>
                </Layout>
            }
        </div>
    );
}
