import { environment } from "../environments/environment.jsx";
import api from './api';
import { getHeader } from './authUtils.js'

const apiEndpoint = environment.webservices.endpoint;

export class ManifestService {
    getAllManifest(queryParams){
        const endpoint = `${apiEndpoint}/api/manifests`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }
    getManifestDetails(id){
        const endpoint = `${apiEndpoint}/api/manifests/${id}/summary`; 
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }
    uploadManifest(payload){
        const endpoint = `${apiEndpoint}/api/manifests`;
        return api.post(endpoint, payload, {
            headers: getHeader(),
        });
    }

    getBusinessDetails(queryParams){
        const endpoint = `${apiEndpoint}/api/customer/unbatched`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        })
    }

    updateOperationDate(formData){
        const endpoint = `${apiEndpoint}/api/manifest/update`
        return api.put(endpoint,formData, {
            headers: getHeader(),
        })
    }
  
    markManifestOnHold(formdata) {
        const {id , status} = formdata
        const endpoint = `${apiEndpoint}/api/manifests/${id}/${status}`;
        return api.put(endpoint,null, {
            headers: getHeader()
        });
    }
}