import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { addFSAZone, updateFSAZone } from 'features/fsaZone/fsaZoneAction'
import { resetSuccessData } from 'features/dailyRoaster/dailyRoasterSlice'
import { Loader } from 'component/common/Loader/Loader'
import { CustomModal } from 'component/common/Modal/CustomModal'
import { CustomForm } from 'component/common/Form/CustomForm'
import { getDataCentersList } from 'utils/userAccountHelper'
import useFieldChange from 'hooks/useFieldChange';
import 'component/dailyRoaster/AddDuty.scss'
import { getUserDCOption } from 'utils/common';

const formInitialState = {
    fsaZoneName: '',
    priority: '',
    dc: '',
    totalParcelLimit: '',
    totalAddressLimit: '',
}

export const AddFSAZone = ({ isModelOpen, handleClose, fsaZone }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    const { dataCenters } = useSelector(
        (state) => state?.userManagement
    )

    const { loading } = useSelector(
        (state) => state?.fsaZones
    )

    const { user } = useSelector(
        (state) => state?.auth
    )

    useEffect(() => {
        if (fsaZone) {
            setFormValues({
                priority: fsaZone?.priority,
                fsaZoneName: fsaZone?.fsaZoneName,
                totalParcelLimit : fsaZone?.totalParcelLimit,
                totalAddressLimit: fsaZone?.totalAddressLimit,
                dc: fsaZone?.dcName ? { label: fsaZone?.dcName, value: fsaZone?.dcName } : null
            });
        }
    }, [fsaZone])

    const handleFormSubmit = () => {
        const notRequiredFields = ['dc'];
        const errors = validateForm(formValues,notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const data = {}
            data['priority'] = formValues?.priority
            data['fsaZoneName'] = formValues?.fsaZoneName
            data['distributionCenterName'] = getUserDCOption(user)?.value
            data['totalParcelLimit'] = formValues?.totalParcelLimit
            data['totalAddressLimit'] = formValues?.totalAddressLimit
            if (fsaZone) {
                data['id'] = fsaZone?.fsaZoneId
            }
            dispatch(fsaZone ? updateFSAZone([data]) : addFSAZone(data))
        }
    }

    const handleCancel = () => {
        setFormValues(formInitialState)
        dispatch(resetSuccessData())
        handleClose()
    }

    const formProps = {
        fields: [{
            label: 'FSA Zone Name',
            name: 'fsaZoneName',
            required: true,
            type: 'text',
            value: formValues?.fsaZoneName || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            error: formError?.fsaZoneName,
        },
        {
            label: 'Priority',
            name: 'priority',
            required: true,
            type: 'text',
            value: formValues?.priority || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            error: formError?.priority,
        },
        {
            label: 'DC',
            value: getUserDCOption(user),
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: '19rem',
            options: getDataCentersList(dataCenters),
            name: 'dc',
            required: true,
            error: formError?.dc,
            isDisabled: true
        },
        {
            label: 'Total Parcel Limit',
            name: 'totalParcelLimit',
            required: true,
            type: 'text',
            value: formValues?.totalParcelLimit || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            error: formError?.totalParcelLimit,
        },
        {
            label: 'Total Address Limit',
            name: 'totalAddressLimit',
            required: true,
            type: 'text',
            value: formValues?.totalAddressLimit || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            error: formError?.totalAddressLimit,
        }
        ],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: 'center'
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleCancel}
            >
                <div className='add-duty-modal'>
                    <span className='title'>{fsaZone ? 'Edit FSA Zone' : 'Add FSA Zone'}</span>
                    <CustomForm {...formProps} />
                </div>
            </CustomModal>
            {loading ? <Loader isLoading={loading} /> : null}
        </>

    )
}