import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ListItemButton, ListItem, ListItemIcon, Paper, ListItemText, List, Divider } from '@mui/material';
import { sideMenuOptions } from '../../utils/sideMenu'
import './sideMenu.scss';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { updateSideMenuState } from 'features/drawer/drawerAction'
import { getUserRole } from 'utils/common'

function SideMenu({ open }) {
  const location = useLocation();
  const currentPath = location.pathname;
  const { user } = useSelector(
    (state) => state?.auth
  )
  const { sideMenuState } = useSelector(
    (state) => state?.drawer
  )
  const sideMenus = sideMenuOptions[getUserRole(user)]
  const dispatch = useDispatch();

  const handleSubMenuClick = (path) => {
    const updatedState = { ...sideMenuState };
    if (updatedState[path]) {
      delete updatedState[path];
    } else {
      updatedState[path] = true;
    }
    dispatch(updateSideMenuState(updatedState))
  };

  return (
    <div className='sideNav'>
      <Paper className='sideBarMenu'>
        {sideMenus?.map((menu, index) => {
          return (
            <div key={`sidemenu-wrapper-${index}`}>
              <List className='menuList' key={`sidemenu-${index}`} >
                {menu?.map((subMenu, subMenuIndex) => {
                  return (subMenu?.show ?
                    subMenu.children ? (
                      <div key={`${subMenu?.path}-${subMenuIndex}`} >
                        <ListItem                                             
                          disablePadding sx={{ display: 'block' }}
                          onClick={() => handleSubMenuClick(subMenu?.path)}
                          className={currentPath.includes(subMenu?.path) ? "active" : ""}
                        >
                          <ListItemButton 
                                                    
                            sx={{
                              height: 40,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                          >
                            <Tooltip title={subMenu?.title}>
                              <ListItemIcon                              
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 1 : 'auto',
                                  justifyContent: 'center',
                                }}
                                className={currentPath.includes(subMenu?.path) ? "active-icon" : ""}
                              >
                                {subMenu.icon}
                              </ListItemIcon>
                            </Tooltip>
                            <ListItemText   className={`menuLink ${currentPath.includes(subMenu?.path) ? 'active-link' : ''}`}
                              sx={{ opacity: open ? 1 : 0 }}>
                              <div
                              data-testid={`menuLink-${subMenu?.title}`}
                                className={`menuLink ${currentPath === subMenu?.path ? 'active-link' : ''}`}                               
                              >
                                {subMenu?.title}
                                {sideMenuState[subMenu?.path] ? <ExpandLess /> : <ExpandMore />}
                              </div>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                        <Collapse key={subMenu?.path} className='collapse' in={sideMenuState[subMenu?.path]} unmountOnExit>
                          <List>
                            {subMenu?.children?.map((childMenu, childIndex) => (
                              <Link to={childMenu?.path} key={childMenu.path}>
                                <ListItemButton
                                  key={`child-${childMenu.path}-${childIndex}`}
                                  sx={{
                                    height: 40,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                  }}
                                >
                                  <Tooltip title={childMenu?.title}>
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 2 : 'auto',
                                        justifyContent: 'center',
                                      }}
                                      className={currentPath === childMenu.path ? "active-icon" : ""}
                                    >
                                      {childMenu.icon}
                                    </ListItemIcon>
                                  </Tooltip>
                                  <ListItemText sx={{ opacity: open ? 1 : 0 }} className={`menuLink ${currentPath === childMenu.path ? 'active-link' : ''}`}
                                  >

                                    <div
                                    data-testid={`menuLink-${childMenu?.title}`}
                                      className={`menuLink ${currentPath === childMenu?.path ? 'active-link' : ''}`}
                                    >
                                      {childMenu?.title}
                                    </div>
                                  </ListItemText>

                                </ListItemButton>
                              </Link>
                            ))}
                          </List>
                        </Collapse>
                      </div>
                    )
                      : (
                        <Link to={subMenu?.path} key={subMenu?.title}>
                          <ListItemButton
                            sx={{
                              height: 40,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                          >
                            <Tooltip title={subMenu?.title}>
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 1 : 'auto',
                                  justifyContent: 'center',
                                }}
                                className={currentPath === subMenu?.path ? "active-icon" : ""}
                              >
                                {subMenu.icon}
                              </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }} className={`menuLink ${currentPath === subMenu?.path ? 'active-link' : ''}`}
                            >
                              <div
                              data-testid={`menuLink-${subMenu?.title}`}
                                className={`menuLink ${currentPath === subMenu?.path ? 'active-link' : ''}`}
                              >
                                {subMenu?.title}
                              </div>
                            </ListItemText>
                          </ListItemButton>
                        </Link>
                      )
                    : null)
                }
                )}
              </List>
              <Divider />
            </div>
          )
        })}
      </Paper >
    </div >
  );
}
export default SideMenu;