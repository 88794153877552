import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CustomModal } from 'component/common/Modal/CustomModal';
import useFieldChange from 'hooks/useFieldChange';
import { CustomForm } from 'component/common/Form/CustomForm';
import { routeTerminateStatus } from 'constants/routeStatus';
import { markRouteTerminate } from 'features/route/routeAction';

const formInitialState = {
    selectedOption: '',
    isSLA: false,
};

const checkBoxOptions = {
    isSLA: 'SLA',
};

export const TerminateRouteModal = ({ isModelOpen, handleClose,route }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const { loading } = useSelector(
        (state) => state?.route
    );
    
    const handleFormSubmit = () => {
        if (!formValues?.selectedOption) {
            setFormErrors({ selectedOption: 'This field is required' });
            return;
        }
        const data = {
            routeId: route,
            status: formValues?.selectedOption.value,
            isSLA: formValues?.isSLA
        }
        dispatch(markRouteTerminate(data));
    };


    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: checked,
        }));
    };

    const formProps = {
        fields: [
            {
                label: 'Select Terminate Option',
                name: 'selectedOption',
                required: true,
                type: 'select',
                options: routeTerminateStatus,
                value: formValues?.selectedOption || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.selectedOption,
            },
            {
                type: 'checkbox',
                options: checkBoxOptions,
                value: formValues,
                handleChange: handleCheckboxChange,
                width: '19rem',
                name: 'isSLA'
            }
        ],
        buttons: [
            {
                label: 'Submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: 'center'
    };

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>{'Terminate Route'}</span></div>
                <CustomForm {...formProps} allowOverflow='unset' />
            </div>
        </CustomModal>
    );
};
