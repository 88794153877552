export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const UPDATE_SIDE_MENU_STATE = 'UPDATE_SIDE_MENU_STATE';

export const openDrawer = () => ({
  type: OPEN_DRAWER,
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});

export const updateSideMenuState = (newState) => ({
  type: UPDATE_SIDE_MENU_STATE,
  payload: newState,
});