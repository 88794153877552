import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartWrapper, getDoughnutChartOptions } from './Common'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DoughnutChart = ({ data, title, link, icon, height }) => {
    const options = getDoughnutChartOptions(data)

    return <div>
        <ChartWrapper title={title} link={link} icon={icon} />
        <div style={{ height: height }}>
            <Doughnut data={data} options={options} />
        </div>
    </div>;

};

export default DoughnutChart;
