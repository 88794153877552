import { createAsyncThunk } from '@reduxjs/toolkit'
import { AuditService } from 'services/auditServices'
const auditService = new AuditService();

export const fetchAuditData = createAsyncThunk(
    'audit/fetchAuditData',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await auditService.getAuditData(formData)
            return response?.data || []
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)
