import { createSlice } from '@reduxjs/toolkit';
import { fetchFSAZones, fetchFSASubZones, addFSAZone, updateFSAZone, updateFSASubZone, addFSASubZone } from './fsaZoneAction'
import { logout } from 'features/auth/authAction'

const initialState = {
    fsaZones: [],
    success: false,
    fsaSubZones: [],
    error: null
}
// Redux slice for packages
const fsaZoneSlice = createSlice({
    name: 'fsaZoneSlice',
    initialState,
    reducers: {
        resetState: (state) => {
            state.success = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFSAZones.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFSAZones.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.fsaZones = action?.payload?.sort((a, b) => a?.priority - b?.priority)
            })
            .addCase(fetchFSAZones.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //fetch FSA Sub-Zones
            .addCase(fetchFSASubZones.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFSASubZones.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.fsaSubZones = action?.payload
            })
            .addCase(fetchFSASubZones.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //add FSAZone
            .addCase(addFSAZone.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addFSAZone.fulfilled, (state, action) => {
                state.error = null;
                state.success = true
            })
            .addCase(addFSAZone.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //update FSAZone
            .addCase(updateFSAZone.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateFSAZone.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(updateFSAZone.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //add FSASubZone
            .addCase(addFSASubZone.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addFSASubZone.fulfilled, (state, action) => {
                state.error = null;
                state.success = true
            })
            .addCase(addFSASubZone.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //update FSASubZone
            .addCase(updateFSASubZone.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateFSASubZone.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(updateFSASubZone.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })
    }
});

export const { resetState } = fsaZoneSlice.actions;
export default fsaZoneSlice.reducer;