import React from 'react';
import { Modal } from '@mui/material';

export const CustomModal = ({ open, onClose, children }) => {

  const handleModalClose = (event, reason) => {
    if (reason === 'backdropClick') {
      // Prevent closing the modal on backdrop click
      return;
    }
    onClose(event);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {children}
    </Modal>
  );
};