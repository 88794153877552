import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ListComponent } from "component/common/List/List";
import { Title } from 'component/common/commonStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const AccordianComponent = ({ data, isMobile }) => {
    return (
        <Accordion sx={{ width: '100%', backgroundColor: '#f2f2f2' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Title>Summary</Title>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }} >
                <ListComponent
                    data={data}
                    height="30px"
                    flexDirection={isMobile ? "column" : "row"}
                />
            </AccordionDetails>
        </Accordion>
    );
}