import { roleConstantEnum } from "constants/roleConstants";

export const getDataCentersList = (data, userRole, userDC) => {
    // If the userRole is ROLE_ADMIN, return the entire data list
    if (!userRole || userRole === roleConstantEnum?.ROLE_ADMIN) {
        const dataCenters = data?.map((item) => {
            return { label: item?.dcName, value: item?.dcName };
        });
        return dataCenters || [];
    } 

    // For non-admin users, return only the userDC option
    return userDC ? [userDC] : [];
}

export const getTenantsList = (data) => {
    const tenants = data && data.map((item) => {
        return { label: item?.tenantName, value: item?.tenantName };
    })
    return tenants || []
}

export const getClientList = (data) => {
    const customers= data && data?.map((item) => {
        return { label: item?.businessName, value: item?.businessName };
    })
    return customers || []
}