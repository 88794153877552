import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { ImageUpload } from 'component/common/ImageUpload/ImageUpload'
import { FieldRenderer } from './FieldRenderer'
import './CustomForm.scss'

export const FormWrapper = styled.form.withConfig({
    shouldForwardProp: (prop) =>
        !['margin', 'allowOverflow', 'padding', 'backgroundcolor', 'borderradius', 'showFullHeight'].includes(prop),
})`
    background-color: ${props => props?.backgroundcolor || '#ffffff'};
    margin: ${props => props?.margin || '1rem'};
    padding: ${props => props?.padding || '2rem'};
    border-radius: ${props => props?.borderradius};
    height: ${props => (props?.showFullHeight ? '-webkit-fill-available' : 'auto')};
    overflow-y: ${props => props?.allowOverflow};
  `;

export const FieldContainer = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        !['fieldGap', 'fieldAlignment'].includes(prop),
})`
    display: flex;
    gap: ${props => props?.fieldGap || '1rem'};
    flex-wrap: wrap;
    justify-content: ${props => props?.fieldAlignment};
  `;

export const CustomForm = ({ fields, fieldAlignment = 'unset', buttons, showImageUploader, handleImageUpload, image, margin, borderRadius, padding, backgroundColor, lableColor, fieldGap, allowOverflow = 'auto', showFullHeight = false, isMobile }) => {
    return (
        <>
            <FormWrapper className='form-container' margin={margin} allowOverflow={allowOverflow} padding={padding} backgroundcolor={backgroundColor} borderradius={borderRadius} showFullHeight={showFullHeight}>
                <div className='form-wrapper'>
                    {showImageUploader ? <div className='image-container'>
                        <ImageUpload image={image} handleImageUpload={handleImageUpload} />
                    </div>
                        : null}
                    <FieldContainer fieldAlignment={fieldAlignment} fieldGap={fieldGap}>
                        {fields?.map((field) => {
                            return (<FieldRenderer key={field?.name} field={field} lableColor={lableColor} isMobile={isMobile} />)
                        })}
                        {buttons?.length ?
                            <div className='button-container'>
                                {buttons?.map((button) => {
                                    return (
                                        <Button data-testid={`button-${button?.label}`} key={button?.label} onClick={button?.onClick} variant={button?.variant || 'outlined'}
                                            sx={{ color: button?.color, backgroundColor: button?.backgroundColor }}>{button?.label}</Button>
                                    )
                                })}
                            </div> : null}
                    </FieldContainer>
                </div>
            </FormWrapper>
        </>)
}