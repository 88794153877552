export const UPDATE_PRIORITY = 'UPDATE_PRIORITY';
export const UPDATE_SLA_VIOLATION = 'UPDATE_SLA_VIOLATION';

export const updatePriority = (newPriority) => ({
    type: UPDATE_PRIORITY,
    payload: newPriority,
  });
  
  export const updateSLAViolation = (newSLAViolation) => ({
    type: UPDATE_SLA_VIOLATION,
    payload: newSLAViolation,
  });
  