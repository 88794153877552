import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'component/dailyRoaster/AddDuty.scss';
import { CustomForm } from 'component/common/Form/CustomForm';
import { resetManifestData } from 'features/manifestManagement/manifestManagementSlice';
import { CustomModal } from 'component/common/Modal/CustomModal';
import useFieldChange from 'hooks/useFieldChange';
import { updateManifestOperationDate } from 'features/manifestManagement/manifestManagementAction';
import { getUTCTime, getUserTimeZone } from 'utils/common';
import { validateForm } from 'utils/formValidator';

const formInitialState = {
    manifestId: '',
    operationApplicableDate: '',
};


export const UpdateOperationDate = ({ isModelOpen, handleClose, user, selectedManifestId }) => {
    const dispatch = useDispatch();

    const { isMobile } = useSelector(
        (state) => state?.viewport
    );

    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);

    const [formError, setFormErrors] = useState({});
    
    const userTimeZone = getUserTimeZone(user?.distributionCenterResponseVm?.timeZone) || 'America/Toronto';

    useEffect(() => {
        setFormValues((prevValues) => ({
            ...prevValues,
            manifestId: selectedManifestId, 
        }));  
        return () => {
            dispatch(resetManifestData());
        };
    }, [selectedManifestId]);

    const handleDateChange = (date) => {   
        setFormValues((prevValues) => ({
            ...prevValues,
            operationApplicableDate: date, 
        }));        
    };


    const handleFormSubmit = () => {
        const notRequiredFields = []
        const errors = validateForm(formValues, notRequiredFields);
        setFormErrors(errors)

        if (!Object.keys(errors)?.length) {
            setFormErrors({})
            const data = {
                manifestId: formValues.manifestId,
                updatedOperationApplicableDate: getUTCTime(formValues?.operationApplicableDate, userTimeZone), 
            };
            dispatch(updateManifestOperationDate(data));
        }
    };

    
    const formProps = {
        fields: [
            {
                type: 'text',
                name: 'manifestId',
                label: 'Manifest ID',
                value: formValues?.manifestId,
                handleFieldChange: handleFieldChange,
                required: true,
                error: formError?.manifestId, 
                width: '18rem',
                isDisabled: true,
            },
            {
                label: 'Operation Date',
                name: 'operationApplicableDate', 
                type: 'datetime',
                required: true,
                value: formValues?.operationApplicableDate || '', 
                handleFieldChange: handleDateChange, 
                error: formError?.operationApplicableDate, 
                width: '18rem',
                timeZone: userTimeZone,
            },
        ],
        buttons: [
            {
                label: 'Submit',
                onClick: handleFormSubmit,
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error',
            },
        ],
        fieldGap: '0.5rem',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: isMobile ? 'center' : 'unset',
    };

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>Update Operation applicable date</span></div>
                <CustomForm {...formProps} allowOverflow='unset' />
            </div>
        </CustomModal>
    );
};
