import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchWarehouseData } from 'features/warehouseOperation/warehouseOperationAction'
import { Layout } from 'component/common/Layout/Layout';
import { CardDetail } from 'component/dashboard/cardDetail'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Loader } from 'component/common/Loader/Loader'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import Shelf from './Shelf'
import './WarehouseOperations.scss'

export const WarehouseOperation = () => {
    const dispatch = useDispatch();
    const { warehouseData, error, loading } = useSelector(
        (state) => state?.warehouseOperation
    )
    const [modalProps, setModalProps] = useState(null)

    useEffect(() => {
        dispatch(fetchWarehouseData())
    }, [])

      useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    return (
        <Layout showBackArrow={false} headerTitle={'Warehouse Operations'}>
            <CardDetail>
                <DndProvider backend={HTML5Backend}>
                    <div className='wrapper'>
                        <div className='label-container'><span>West</span>
                            <span>North</span>
                            <span>East</span>
                        </div>
                        {Object.keys(warehouseData)?.length ?
                            <div className="warehouse-container">
                                <div className='tote-container'>
                                    {Object.entries(warehouseData?.W)?.map(([key, value]) => (
                                        <div key={key}>
                                            <Shelf row={{ rowLabel: key, largeBoxes: value, flexDirection: 'row-reverse' }} />
                                            <div className="spacer">
                                                <div className="label">{key}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='roller-cage-image'>
                                    <img src="/static/images/ConveyorBelt.jpg" className='img' alt='ConveyorBelt' />
                                </div>
                                <div className='tote-container'>
                                    {Object.entries(warehouseData?.E)?.map(([key, value]) => (
                                        <div key={key}>
                                            <Shelf row={{ rowLabel: key, largeBoxes: value }} />
                                            <div className="spacer">
                                                <div className="label">{key}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div> : null}
                        <div className='label-container'>
                            <span>Docking Zone</span>
                            <span>South</span>
                            <span>Holding Zone</span>
                        </div>
                    </div>
                </DndProvider>
                {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
                {loading ? <Loader isLoading={loading} /> : null}
            </CardDetail>
        </Layout>
    )
}