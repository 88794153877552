import React from 'react'
import { useSelector } from 'react-redux';
import DataTable from 'component/dataTable/dataTable'
import { covertToString } from 'utils/common'
import './VehicleDetail.scss'

const getVehiclDetails = (drivers, id) => {
    const driver = drivers && drivers.find(obj => obj.id === id)
    return [{...driver?.vehicle, vehicleType : driver?.vehicleType}]
}

export const VehicleDetails = ({ id }) => {
    const { drivers } = useSelector(
        (state) => state?.driverManagement
    )    
    const vehicleData = getVehiclDetails(drivers, id)

    const columns = [{
        accessor: "id",
        title: "ID",
        width: "8rem",
        Cell: ({ cell: { value } }) => value || '-',
        render: (item) => item?.id || '-'
    },
    {
        accessor: "vehicleModel",
        title: "Model",
        width: "8rem",
        Cell: ({ cell: { value } }) => value || '-',
        render: (item) => item?.vehicleModel || '-'
    },
    {
        accessor: "fuelType",
        title: "Fuel Type",
        width: "10rem",
        Cell: ({ cell: { value } }) => covertToString(value) || '--',
        render: (item) => covertToString(item?.fuelType) || '--'
    },
    {
        accessor: 'materialType',
        title: "Material Type",
        width: "12rem",
        Cell: ({ cell: { value } }) => covertToString(value) || '--',
        render: (item) => covertToString(item?.materialType) || '--'
    }]

    return (
        <div className='vehilce-container'><div className='title-container' ><span className='title'>Vehicle Details</span></div><DataTable columns={columns} data={vehicleData || []} isCollpsable={false} /></div>)
}