import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'features/auth/authAction'
import { getRoutes, updatePackageStatus, updateRouteStopStatus, updateRouteStatus } from './routeOperationAction';

const initialState = {
    loading: false,
    error: null,
    routes: [],
    success: false
}

const routeOperationSlice = createSlice({
    name: 'route',
    initialState,
    reducers: {
        resetSuccessState: (state) => {
            state.success = false;
        },
        resetError: (state) => {
            state.error = null;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getRoutes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getRoutes.fulfilled, (state, action) => {
                state.loading = false;
                state.routes = action.payload;
            })
            .addCase(getRoutes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // update package status
            .addCase(updatePackageStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePackageStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true
            })
            .addCase(updatePackageStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // update route status
            .addCase(updateRouteStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateRouteStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true
            })
            .addCase(updateRouteStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // update route stop status
            .addCase(updateRouteStopStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateRouteStopStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true
            })
            .addCase(updateRouteStopStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })

    }
})

export const { resetSuccessState, resetError } = routeOperationSlice.actions;
export default routeOperationSlice.reducer;