import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { fetchDailyRoasters } from 'features/dailyRoaster/dailyRoasterAction'
import DataTable from 'component/dataTable/dataTable'
import { Loader } from 'component/common/Loader/Loader'
import { getConstantValue, covertToString, iconStyle, getCurrentDate, checkPermission, getDateWithTimeStamp } from 'utils/common'
import { CardDetail } from 'component/dashboard/cardDetail'
import { PrimaryButton } from 'component/common/Button/Button'
import { Dot } from 'component/common/Dot/dot'
import { AddDuty } from './AddDuty'
import { getRoasterStausColor } from 'utils/dailyRoasterHelper'
import { TableHeader } from 'component/dataTable/tableHeader';
import '../userManagement/userAccount/UserAccount.scss'
import { routeStatus } from 'constants/routeStatus'
import { Link } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Audit } from 'component/audit/audit'
import { Tooltip } from '@mui/material';
import { urls } from 'utils/urls'
import { resetSuccessData } from 'features/dailyRoaster/dailyRoasterSlice'

const date = getCurrentDate()

export const DailyRoaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { dailyRoasters, dailyRoasterCount, loading, addRoasterSuccess } = useSelector(
        (state) => state?.dailyRoaster
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const [addDutyModalOpen, setAddDutyModalOpen] = useState(false)
    const [sorteData, setSortedData] = useState(dailyRoasters);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const hasEditPermission = checkPermission(user, 'DELIVERY DASHBOARD')

    const fetchData = () => {
        dispatch(fetchDailyRoasters({
            page: page,
            size: rowsPerPage,
            startDate: date
        }))
    }

    useEffect(() => {
        fetchData()
    }, [rowsPerPage, page])

    useEffect(() => {
        if (addRoasterSuccess) {
            fetchData()
        }
    }, [addRoasterSuccess])

    useEffect(() => {
        return () => {
            dispatch(resetSuccessData())
        };
    }, [])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleAddDutyModalClick = () => {
        setAddDutyModalOpen(!addDutyModalOpen)
    }

    const onRouteClick = (value) => {
        navigate(`${urls?.ROUTES_WIDGET_URL}?routeId=${value}`)
    }


    const columns = [{
        accessor: "id",
        title: "ID",
        width: "3rem",
        Cell: ({ cell: { row: { original } } }) => <><Dot color={getRoasterStausColor(original)} /> {original?.id || '--'}</>,
        render: (item) => item?.id || '--'
    },
    {
        accessor: "driverName",
        title: "Driver Name",
        width: "5rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.driverName || '--'
    },
    {
        accessor: 'reportingTime',
        title: "Reporting Time",
        width: "2rem",
        Cell: ({ cell: { row: { original } } }) => original?.reportingTime ? getDateWithTimeStamp(original?.reportingTime, user, true) : '--',
        render: (item) => item?.reportingTime ? getDateWithTimeStamp(item?.reportingTime, user, true) : '--'
    },
    {
        accessor: 'dailyRouteId',
        title: "Route ID",
        width: "2rem",
        Cell: ({ cell: { value } }) => value ? <Link id='dailyRouteId' onClick={() => onRouteClick(value)} style={iconStyle} sx={{ textDecoration: 'none' }}>{value}</Link > : '--',
        render: (item) => item?.dailyRouteId || '--'
    },
    {
        accessor: "routeStartTime",
        title: "Route Start Date",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
        render: (item) => item?.routeStartTime ? getDateWithTimeStamp(item?.routeStartTime, user) : '--'
    },
    {
        accessor: 'assignedRouteStatus',
        title: "Route Status",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getConstantValue(routeStatus, value) : '--',
        render: (item) => item?.assignedRouteStatus ? getConstantValue(routeStatus, item?.assignedRouteStatus) : '--'
    },
    {
        accessor: 'vehicle',
        title: "Vehicle",
        width: "5rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.vehicle || '--'
    },
    {
        accessor: 'dutyAssigned',
        title: "Duty Assigned",
        width: "2rem",
        Cell: ({ cell: { value } }) => value ? covertToString(value) : '--',
        render: (item) => item?.dutyAssigned ? covertToString(item?.dutyAssigned) : '--'
    },
    {
        accessor: 'action',
        title: "Action",
        isSortable: false,
        width: "2rem",
        Cell: ({ cell: { row: { original } } }) => <div className='action' >
            <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.id, 'entityType': 'daily-roasters' }))} /></Tooltip>
        </div>
    }
    ]

    return (
        <Layout headerTitle={'Driver Roster'} showBackArrow={false} action={hasEditPermission ?
            <div><PrimaryButton type="button" variant='primary' label={'Add Duty'} height={'30px'} onClick={handleAddDutyModalClick} /></div> : null}
        >
            <CardDetail>
                <div className='container'>
                    <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Driver Roster' />
                    <div className='content'>
                        <DataTable columns={columns} data={dailyRoasters || []} isCollpsable={false} onSortChange={setSortedData} uniqueKey={'id'} setUpdatedColumns={setUpdatedColumns} showPagination={dailyRoasters?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={dailyRoasterCount} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />

                        {addDutyModalOpen ? <AddDuty isModelOpen={addDutyModalOpen} handleClose={handleAddDutyModalClick} /> : null}
                    </div>
                </div>
            </CardDetail>
            {loading ? <Loader isLoading={loading} /> : null}
            <Audit />
        </Layout>
    )
}