import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class ClientManagementService {
    // Client APIs
    getClients(queryParams) {
        const endpoint = `${apiEndpoint}/api/customers`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    addClient(formData) {
        const endpoint = `${apiEndpoint}/api/customers`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    getClientDetails(id) {
        const endpoint = `${apiEndpoint}/api/customers/${id}`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    updateClient(formData) {
        const endpoint = `${apiEndpoint}/api/customers/${formData?.id}`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    getBillDetails(id) {
        const endpoint= `${apiEndpoint}/api/customer/generatebill/${id}`
        return api.get(endpoint, {
            headers: getHeader(),
        })
    }

}