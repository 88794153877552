import React from "react";
import styled from "styled-components";
import { Layout } from "component/common/Layout/Layout.jsx";
import GraphContainer from "component/Charts/GraphContainer.jsx";
import GroupCharts from "component/Charts/GroupCharts.jsx";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import BarData from "component/Charts/ChartData/ClientData.json";
import ManagementIcon from "icons/ManagementIcon";

const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  height: 100%;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ClientDashboard = () => {
    return (
        <Layout headerTitle={"Daily Dashboard"} showBackArrow={false}>
            {/* <ContainerComponent cardData={cardData} /> */}
            <DashboardContainer>
                <GraphContainer
                    title="Parcels"
                    icon={<Inventory2OutlinedIcon />}
                    link="/packages"
                >
                    <GroupCharts data={BarData[0]} />
                </GraphContainer>
                <GraphContainer
                    title="Manifests"
                    icon={<ManagementIcon fill="#5D48FF" />}
                    link="/manifest"
                >
                    <GroupCharts data={BarData[1]} />
                </GraphContainer>
            </DashboardContainer>
        </Layout>
    );
};

export default ClientDashboard;
