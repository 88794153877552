import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { addFSASubZone, updateFSASubZone } from 'features/fsaZone/fsaZoneAction'
import { resetSuccessData } from 'features/dailyRoaster/dailyRoasterSlice'
import { Loader } from 'component/common/Loader/Loader'
import { CustomModal } from 'component/common/Modal/CustomModal'
import { CustomForm } from 'component/common/Form/CustomForm'
import useFieldChange from 'hooks/useFieldChange';
import { getFSAZones } from 'utils/common'

import 'component/dailyRoaster/AddDuty.scss'

const formInitialState = {
    priority: '',
    fsaZone: '',
    code: ''
}

export const AddFSASubZone = ({ isModelOpen, handleClose, fsaSubZone }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    const { loading, fsaZones } = useSelector(
        (state) => state?.fsaZones
    )

    useEffect(() => {
        if (fsaSubZone) {
            setFormValues({
                code: fsaSubZone?.code,
                priority: fsaSubZone?.priority,
                fsaZone: fsaSubZone?.fsaZone ? { label: fsaSubZone?.fsaZone?.fsaZoneName, value: fsaSubZone?.fsaZone?.id } : null
            });
        }
    }, [fsaSubZone])

    const handleFormSubmit = () => {
        const errors = validateForm(formValues);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const data = {}
            data['priority'] = formValues?.priority
            data['code'] = formValues?.code
            data['fsaZoneId'] = formValues?.fsaZone?.value
            if (fsaSubZone) {
                data['id'] = fsaSubZone?.id
            }
            dispatch(fsaSubZone ? updateFSASubZone([data]) : addFSASubZone(data))
        }
    }

    const handleCancel = () => {
        setFormValues(formInitialState)
        dispatch(resetSuccessData())
        handleClose()
    }

    const formProps = {
        fields: [{
            label: 'Code',
            name: 'code',
            required: true,
            type: 'text',
            value: formValues?.code || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            error: formError?.code,
        },
        {
            label: 'Priority',
            name: 'priority',
            required: true,
            type: 'text',
            value: formValues?.priority || '',
            handleFieldChange: handleFieldChange,
            width: '19rem',
            error: formError?.priority,
        },
        {
            label: 'FSA Zone',
            value: formValues?.fsaZone,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: '19rem',
            options: getFSAZones(fsaZones),
            name: 'fsaZone',
            required: true,
            error: formError?.fsaZone,
        }
        ],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem'
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleCancel}
            >
                <div className='add-duty-modal'>
                    <span className='title'>{fsaSubZone ? 'Edit FSA Sub Zone' : 'Add FSA Sub Zone'}</span>
                    <CustomForm {...formProps} allowOverflow='unset'/>
                </div>
            </CustomModal>
            {loading ? <Loader isLoading={loading} /> : null}
        </>

    )
}