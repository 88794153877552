export const driverTypeEnum = {
    AGENCY : 'AGENCY',
    EMPLOYEE: 'EMPLOYEE',
    OWNEROPERATOR: 'OWNEROPERATOR'
}

export const driverTypeConstants = [
    {
        label: 'Agency',
        value: driverTypeEnum?.AGENCY
    },
    {
        label: 'Employee',
        value: driverTypeEnum?.EMPLOYEE
    },
    {
        label: 'Owner Operator',
        value: driverTypeEnum?.OWNEROPERATOR
    }
]