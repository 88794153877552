export const getRouteDetails = (route) => {
    const data = [
        {
            label: 'Unscanned Totes',
            value: route?.unScannedToteIds?.join(', '),
        },
        {
            label: 'Unscanned Parcels',
            value: route?.unScannedPackageIds?.join(', '),
        }
    ]
    return {routeData: data, parcels: route?.returnedPackages}
}

export const checkToteIDFormat = (id) => {
    const regex = /^[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+$/;
    return regex.test(id);
  }