import { createSlice } from '@reduxjs/toolkit';
import { fetchAuditData } from './auditAction'
import { logout } from 'features/auth/authAction'

const initialState = {
    auditData: [],
    loading: false,
    error: null,
    entityType: null,
    entityId: null
}
// Redux slice for audit
const auditSlice = createSlice({
    name: 'auditSlice',
    initialState,
    reducers: {
        setAuditData: (state, action) => {
            state.entityType = action?.payload?.entityType;
            state.entityId = action?.payload?.entityId;
        },
        resetAuditData: (state) => {
            state.entityType = null;
            state.entityId = null;
            state.auditData = [];
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            // Audit Slice
            .addCase(fetchAuditData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAuditData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.auditData = action?.payload;
            })
            .addCase(fetchAuditData.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // Listen to the logoutSuccess action and reset state to initial values
            .addCase(logout.fulfilled, (state) => {
                return initialState; 
            })
    }
});

export const { setAuditData, resetAuditData } = auditSlice.actions;
export default auditSlice.reducer;