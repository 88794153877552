import { createAsyncThunk } from '@reduxjs/toolkit'
import { ClientManagementService } from 'services/clientService'
const clientManagementService = new ClientManagementService();

export const fetchClients = createAsyncThunk(
    'clientManagement/fetchClients',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await clientManagementService.getClients(formData)
            return { clients: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addClient = createAsyncThunk(
    'clientManagement/addClient',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await clientManagementService.addClient(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchClientDetails = createAsyncThunk(
    'clientManagement/fetchClientDetails',
    async (userName, { rejectWithValue }) => {
        try {
            const response = await clientManagementService.getClientDetails(userName)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const updateClient = createAsyncThunk(
    'clientManagement/updateClient',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await clientManagementService.updateClient(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchBillingDetails = createAsyncThunk(
    'clientManagement/fetchBillingDetails',
    async (userName, { rejectWithValue }) => {
        try {
            const response = await clientManagementService.getBillDetails(userName)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)