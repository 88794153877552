import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, Checkbox, FormControlLabel } from '@mui/material';
import { StyledLabel } from 'component/common/Input/SelectField'
import styled from 'styled-components';
import { updatePriority, updateSLAViolation } from 'features/deliveryStatusManagement/deliveryStatusManagementAction'

const WrapperContainer = styled.div`
 margin: 0px 10px;
 display: flex;
`

export const DeliveryStatusFilter = ({ showSLA = true }) => {
    const dispatch = useDispatch();
    const { sla, highPriority } = useSelector(
        (state) => state?.deliveryStatusManagement
    )
    const handleChange = (event) => {
        const { name } = event?.target
        if (name === 'highPriority') {
            dispatch(updatePriority(!highPriority))
        }
        if (name === 'sla') {
            dispatch(updateSLAViolation(!sla))
        }
    }

    return (
        <WrapperContainer>
            <FormControl key={'highPriority'}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={highPriority}
                            onChange={handleChange}
                            name={'highPriority'}
                            data-testid={`high-priority`}
                        />
                    }
                    label={<StyledLabel>{'Priority'}</StyledLabel>}
                />
            </FormControl>
            {showSLA ? <FormControl key={'sla'}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={sla}
                            onChange={handleChange}
                            name={'sla'}
                            data-testid={`sla`}

                        />
                    }
                    label={<StyledLabel>{'SLA Violations'}</StyledLabel>}
                />
            </FormControl> : null}
        </WrapperContainer>
    )
}