import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ChartWrapper } from './Common';

const SummaryTable = ({ data, title, link, icon }) => {
    const renderRows = (sectionData, sectionName) => (
        <>
            <TableRow>
                <TableCell colSpan={data?.columns?.length} style={{ fontWeight: 'bold' }}>
                    {sectionName}
                </TableCell>
            </TableRow>
            {sectionData?.map((row, index) => (
                <TableRow key={index}>
                    {data?.columns?.map((column) => (
                        <TableCell key={column}>{row[column] || '--'}</TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );

    return (
        <div>
            <ChartWrapper title={title} link={link} icon={icon} />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                            {data?.columns?.map((columnName) => (
                                <TableCell key={columnName} style={{ fontWeight: 'bold' }}>
                                    {columnName}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderRows(data?.packageSummary, 'Package Summary')}
                        {renderRows(data?.dsvLeadTime, 'DSV Lead Time')}
                        {renderRows(data.attemptLeadTime, 'Attempt Lead Time')}
                        {renderRows(data.deliveryLeadTime, 'Delivery Lead Time')}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default SummaryTable;
