import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from "component/common/Layout/Layout.jsx";
import { DateRangePickerField } from 'component/common/Input/DateRangePicker/DateRangePickerField'
import { PrimaryButton } from 'component/common/Button/Button'
import CustomFilter from "./CustomFilter";
import { getCurrentDateForFilter } from 'utils/common'
import { getPackageSummary } from "features/reporting/reportingAction";
import useFieldChange from 'hooks/useFieldChange';
import { deliveryLeadTimeData, shortageLeadTimeData, currentShortageData } from './GraphData'
import BarChart from "component/common/Chart/BarChart";
import DoughnutChart from "component/common/Chart/DoughnutChart";
import GraphContainer from "./GraphContainer";
import LineChart from "component/common/Chart/LineChart";
import TableChart from "component/common/Chart/TableChart";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import styled from "styled-components";
import { getPackagesSummaryData, getLeadTimeData, getAttemptLeadTimeData, packageSummaryTableData, packageSummaryTableDataColumns } from 'utils/reportingHelper'

const GraphLayout = styled.div`
background-color: #F2F2F2;
padding: 1rem;
display: flex;
gap: 1rem;
flex-direction: column;
`

const GraphRowWrapper = styled.div`
display: flex;
gap: 1rem;
`

const formInitialState = {
    startDate: getCurrentDateForFilter(),
    endDate: getCurrentDateForFilter()
}

const columnsForShortage = ['Tracking Number', 'Manifest ID', 'Manifest Receive Date', 'DC']
const ReportingDashboard = () => {
    const dispatch = useDispatch();

    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { reportsData } = useSelector(
        (state) => state?.reporting
    )

    const [formValues, setFormValues] = useFieldChange(formInitialState);

    const fetchData = (startDate, endDate) => {
        dispatch(getPackageSummary({
            startDate: startDate,
            endDate: endDate,
            filter: 'date'
        }))
    }

    useEffect(() => {
        fetchData(formValues?.startDate, formValues?.endDate)
    }, [])

    const handleDateRangeSelect = (startDate, endDate) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            startDate: startDate,
            endDate: endDate,
        }));
        fetchData(startDate, endDate)
    }

    return (
        <Layout headerTitle={"Reporting Dashboard"} showBackArrow={false} action={
            <>
                <CustomFilter />
                <PrimaryButton className='buton-primary' type="button" label={'Past 7-Days'} height={'30px'} color={'#bdbdbd'} padding={'11px 15px'} />
                <PrimaryButton className='buton-primary' type="button" label={'Past Month'} height={'30px'} disabled={true} padding={'11px 15px'} />
                <PrimaryButton className='buton-primary' type="button" label={'Past Quarter'} height={'30px'} color={'#bdbdbd'} padding={'11px 15px'} />
                <DateRangePickerField name='startDate' type='date' width={isMobile ? '100%' : '12rem'} value={new Date()?.toISOString()?.split('T')?.[0]} handleFieldChange={handleDateRangeSelect} max={new Date()?.toISOString()?.split('T')?.[0]}
                />
            </>}>
            <GraphLayout>
                <GraphRowWrapper>
                    <GraphContainer
                        width={'70%'}
                    >
                        <BarChart data={getPackagesSummaryData(reportsData?.reportSummaryVM)} title="Parcel Summary"
                            icon={<Inventory2OutlinedIcon />} link="/packages"
                        />
                    </GraphContainer>
                    <GraphContainer
                        width={'30%'}
                    >
                        <DoughnutChart data={getLeadTimeData(reportsData?.leadTimeVM?.[0])} title="Manifest Lead Time"
                            icon={<Inventory2OutlinedIcon />} height={500} link="/packages"
                        />
                    </GraphContainer>
                </GraphRowWrapper>
                <GraphRowWrapper>
                    <GraphContainer
                        width={'50%'}
                    >
                        <LineChart data={getAttemptLeadTimeData(reportsData?.reportSummaryVM)} title="Attempt Lead Time"
                            icon={<Inventory2OutlinedIcon />} link="/packages"
                        />
                    </GraphContainer>
                    <GraphContainer
                        width={'50%'}
                    >
                        <LineChart data={deliveryLeadTimeData} title="Delivery Lead Time"
                            icon={<Inventory2OutlinedIcon />} link="/packages"
                        />
                    </GraphContainer>
                </GraphRowWrapper>
                <GraphRowWrapper>
                    <GraphContainer
                        width={'50%'}
                    >
                        <BarChart data={shortageLeadTimeData} title="Shortage Lead Time"
                            icon={<Inventory2OutlinedIcon />} link="/packages"
                        />
                    </GraphContainer>
                    <GraphContainer
                        width={'50%'}
                    >
                        <TableChart columns={columnsForShortage}
                            data={currentShortageData}
                            title={`Current Shortage : ${currentShortageData?.length}`}
                            link="https://example.com"
                            marginBottom={'0.4rem'}
                            icon={<Inventory2OutlinedIcon />} />
                    </GraphContainer>
                </GraphRowWrapper>
                <GraphRowWrapper>
                    <GraphContainer
                        width={'50%'}
                    >
                        <BarChart data={shortageLeadTimeData} title="Overages"
                            icon={<Inventory2OutlinedIcon />} link="/packages"
                        />
                    </GraphContainer>
                    {/* <GraphContainer
                        width={'50%'}
                    >
                        <TableChart columns={packageSummaryTableDataColumns(reportsData?.parcelSummaryVM)}
                            data={packageSummaryTableData(reportsData?.parcelSummaryVM)}
                            title={`Total Parcels`}
                            link="https://example.com"
                            marginBottom={'0.4rem'}
                            icon={<Inventory2OutlinedIcon />} />
                    </GraphContainer> */}
                </GraphRowWrapper>
            </GraphLayout>
        </Layout>
    );
};

export default ReportingDashboard;
