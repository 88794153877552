// DraggableColumnHeader.js
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TableCell } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ImportExportIcon from '@mui/icons-material/ImportExport';

const DraggableColumnHeader = ({ column, columnIndex, updateColumnOrder, allowSorting=true }) => {
  const { key, ...restProps } = column.getHeaderProps(column.getSortByToggleProps())

  const [, ref] = useDrag({
    type: 'COLUMN',
    item: { columnIndex },
  });

  const [, drop] = useDrop({
    accept: 'COLUMN',
    drop: (draggedItem) => {
      if (draggedItem.columnIndex !== columnIndex) {
        updateColumnOrder(draggedItem.columnIndex, columnIndex);
      }
    },
  });

  return (
    <TableCell
      ref={(node) => {
        ref(drop(node));
      }}
      key={key}
      data-testid={`header-${column?.id}`}
      {...restProps}
      className={`
                    ${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : null}
                    table-head-cell column
                  `}
    >
      <div className="columnWithSort">
        {column.render('title')}
        {column.isSortable !== false && column.isSorted && typeof column?.title === 'string' ? (
          column.isSortedDesc ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />
        ) 
        : column?.isSortable !== false && column?.title && column?.title !== 'Action' && typeof column?.title === 'string' && allowSorting ? (
          <ImportExportIcon fontSize="small" color="disabled" />
        ) 
        : null}
      </div>
    </TableCell>
  );
};

export default DraggableColumnHeader;
