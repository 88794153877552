export const firebaseConfig = {
    apiKey: "AIzaSyA0lEcwE6ww75uuLpTh1Y1kx1FbIyWVzko",
    authDomain: "koorier-89aef.firebaseapp.com",
    projectId: "koorier-89aef",
    storageBucket: "koorier-89aef.appspot.com",
    messagingSenderId: "429204932706",
    appId: "1:429204932706:web:650e056e6b08b88fbaa93b",
    measurementId: "G-648VSYDXH2"
  };
  
  export const vapidKey = 'BElVL5rJjG6OJNZRGOKsN3hl0yu6ooNkqqSazWkuJWr_p-6EDiMdEtS7HjW2OLbLTokOU5j1exvhb2V1NFgBsl0';
  