import { createAsyncThunk } from '@reduxjs/toolkit'
import { DashboardService } from '../../services/dashboardService'
const dashboardService = new DashboardService();

export const fetchDashboardData = createAsyncThunk(
    'dashboard/fetchDashboardData',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await dashboardService.getDashboardData(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchPackageDetailsData = createAsyncThunk(
    'dashboard/fetchPackageDetails',
    async (_, { rejectWithValue }) => {
        try {
            const response = await dashboardService.getPackageDetails()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)


