import React, { useState } from 'react'
import { CustomFormWithBox } from 'component/common/Form/CustomFormWithBox'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { DriverFormFields } from './driverFormFields';

export const AddDriver = ({ handleFieldChange, formValues, formError, loading }) => {
    const [modalProps, setModalProps] = useState('')
    const driverFormProps = DriverFormFields({ handleFieldChange, formError, formValues });
    const formProps = {
        ...driverFormProps,
        loading: loading,
        margin: '0px',
        padding: '1rem'
    }

    return (
        <>
            <CustomFormWithBox {...formProps} />
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : ''}
        </>
    )
}