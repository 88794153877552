import React from 'react'
import { useSelector } from 'react-redux';
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const handleResize = (chart) => {
  chart.resize();
}

const GroupCharts = ({ data }) => {
  const { isMobile } = useSelector(
    (state) => state?.viewport
  )

  const options = {
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
    plugins: {
      legend: {
        display: isMobile ? false : true,
        // Limit max width of the legend to prevent overflow on small screens
        maxWidth: 300, // Adjust based on your design 
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    onResize: handleResize,
  };

  return (
    <Bar data={data} options={options}></Bar>
  )
}

export default GroupCharts