import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class DashboardService {
    getDashboardData(formData){
        const endpoint = `${apiEndpoint}/api/dashboard/home`; 
        return api.get(endpoint, {
            headers: getHeader(),
            params: formData
        });
    }

    getPackageDetails(){
        const endpoint = `${apiEndpoint}/api/pakage-details`; 
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }
}