import { createAsyncThunk } from '@reduxjs/toolkit'
import { DriverManagementService } from 'services/driverService'
const driverManagementService = new DriverManagementService();

export const fetchDrivers = createAsyncThunk(
    'driverManagement/fetchDrivers',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await driverManagementService.getDrivers(formData)
            return { drivers: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addDriver = createAsyncThunk(
    'driverManagement/addDriver',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await driverManagementService.addDriver(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchDriverDetails = createAsyncThunk(
    'driverManagement/fetchDriverDetails',
    async (userName, { rejectWithValue }) => {
        try {
            const response = await driverManagementService.getDriverDetails(userName)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchLoginNames = createAsyncThunk(
    'driverManagement/fetchLoginNames',
    async (_, { rejectWithValue }) => {
        try {
            const response = await driverManagementService.getLoginNames()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)


export const updateDriver = createAsyncThunk(
    'driverManagement/updateDriver',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await driverManagementService.updateDriver(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const deleteDriver = createAsyncThunk(
    'driverManagement/deleteDriver',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await driverManagementService.deleteDriver(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchDriversForRoaster = createAsyncThunk(
    'driverManagement/fetchDriversForRoaster',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await driverManagementService.getDriversForRoaster(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)