import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { TreeItem2, TreeItem2Label } from "@mui/x-tree-view/TreeItem2";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import { getRoutes } from 'features/routeOperation/routeOperationAction'
import 'component/userManagement/userAccount/UserAccount.scss'
import { getRouteTreeData } from 'utils/routeHelper'
import { updateRouteStatus, updateRouteStopStatus, updatePackageStatus } from 'features/routeOperation/routeOperationAction';
import { resetSuccessState } from 'features/routeOperation/routeOperationSlice'
import { convertDateRangeToUTC, getCurrentDateForFilter, getUserDCOption } from 'utils/common'
import './RouteOperation.scss'

const date = getCurrentDateForFilter()
const dateFilter = convertDateRangeToUTC(date, date, "YYYY-MM-DD")

function CustomLabel(props) {
    const { label, status, onChange, options, type, ...other } = props;
    return (
        <TreeItem2Label {...other}>
            <span>{type} : {label}</span>
            <Select
                value={status}
                width={'100%'}
                style={{ marginLeft: "8px" }}
                onChange={(event) => {
                    onChange(event, type, props?.itemId); // Call your existing handler
                }}
                sx={{
                    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: '8px 32px 8px 8px'
                    }
                }}
            >
                {options?.map((status) => (
                    <MenuItem key={status?.label} value={status?.value}>
                        <span className='TextWrapper'>{status?.label}</span>
                    </MenuItem>
                ))}
            </Select>
        </TreeItem2Label>
    );
}

const CustomTreeItem = React.forwardRef((props, ref) => {
    const { label, status, onChange, type, children, options, ...other } = props;
    return (
        <TreeItem2
            {...other}
            ref={ref}
            slots={{ label: CustomLabel }}
            slotProps={{
                label: {
                    label, status, onChange: onChange, options: options, type, ...other
                }
            }}
        >
            {children}
        </TreeItem2>
    );
});

const renderTree = (nodes, onChange) =>
    nodes?.map((item) => (
        <CustomTreeItem
            key={item.itemId}
            itemId={item.itemId}
            label={item.label}
            status={item.status}
            options={item.options}
            type={item?.type}
            onChange={onChange}
        >
            {item.children && renderTree(item.children, onChange)}
        </CustomTreeItem>
    ));

function filterBy(arr, query) {
    return query ? arr?.reduce((acc, item) => {
        if (item?.children?.length) {
            const filtered = filterBy(item.children, query)
            if (filtered?.length) return [...acc, { ...item, children: filtered }]
        }

        const { children, ...itemWithoutChildren } = item;
        return item?.label?.toLowerCase().includes(query?.toLowerCase()) ? [...acc, itemWithoutChildren] : acc
    }, []) : arr
}

export const RouteOperation = () => {
    const dispatch = useDispatch();
    const { routes, loading, error, success } = useSelector(
        (state) => state?.routeOperation
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const [searchQuery, setSearchQuery] = useState('');
    const [modalProps, setModalProps] = useState(null)
    const treeData = routes ? getRouteTreeData(routes) : []

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: 'error'
                }
            )

        }
    }, [error]);

    const fetchData = () => {
        dispatch(getRoutes({
            page: 0,
            size: 10,
            widget: 'ROUTES_WITH_ROUTE_STOPS',
            ...dateFilter,
            dcName: getUserDCOption(user)?.value
        }))
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (success) {
            fetchData()
            dispatch(resetSuccessState())
        }
    }, [success])

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleStatusChange = (event, type, id) => {
        event?.stopPropagation()
        //Check of undefined is added to prevent function call on not selecting status
        if (event?.target?.value !== undefined) {
            const params = {
                id: id,
                status: event?.target?.value
            }

            if (type === 'Route') {
                dispatch(updateRouteStatus(params))
            }
            else if (type === 'RouteStop') {
                dispatch(updateRouteStopStatus(params))
            }
            else if (type === 'Parcel') {
                dispatch(updatePackageStatus(params))
            }
        }
    };

    // Filter tree data based on search query
    const filteredTreeData = filterBy(treeData, searchQuery)

    return (
        <Layout headerTitle={'Executive Control'} showBackArrow={false}>
            <div className='container'>
                <Input
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    style={{ marginBottom: '16px' }}
                />
                {treeData?.length ?
                    <SimpleTreeView>
                        {renderTree(filteredTreeData, handleStatusChange)}
                    </SimpleTreeView> :
                    <span>
                        No data available
                    </span>
                }
            </div>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}