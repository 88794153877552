export const packagesData =
{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Attempted',
            data: [65, 59, 80, 81, 56, 55, 40],
            backgroundColor: 'rgb(96, 165, 250)',
            borderColor: 'rgb(96, 165, 250)',
            borderWidth: 1,
            barPercentage: 0.3
        },
        {
            label: 'Delivered  ',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(99, 102, 241)',
            borderColor: 'rgb(99, 102, 241)',
            borderWidth: 1,
            barPercentage: 0.3
        },
    ],
}

export const leadTimeData = {
    labels: ['Same Day', 'Second Day', 'Third Day', 'Not Received'],
    datasets: [
        {
            data: [12, 19, 3, 5],
            backgroundColor: [
                'rgb(99, 102, 241)',
                'rgba(255, 99, 132, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(75, 192, 192, 0.5)',
            ],
            borderColor: [
                'rgb(99, 102, 241)',
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(75, 192, 192, 1)',

            ],
            borderWidth: 1,
        },
    ],
};

export const attemptLeadTimeData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Attempt Rate',
            data: [65, 59, 80, 81, 56, 55, 40],
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
        },
        {
            label: 'Second Attempt Rate',
            data: [45, 55, 70, 65, 50, 45, 30],
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 2,
        },
        {
            label: 'Third Attempt Rate',
            data: [30, 35, 50, 45, 35, 30, 25],
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 2,
        }
    ],
};

export const deliveryLeadTimeData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Attempt Rate',
            data: [65, 59, 80, 81, 56, 55, 40],
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
        },
        {
            label: 'Second Attempt Rate',
            data: [45, 55, 70, 65, 50, 45, 30],
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 2,
        },
        {
            label: 'Third Attempt Rate',
            data: [30, 35, 50, 45, 35, 30, 25],
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 2,
        }
    ],
};

export const shortageLeadTimeData =
{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Shipments Received Same Day',
            data: [65, 59, 80, 81, 56, 55, 40],
            backgroundColor: 'rgb(96, 165, 250)',
            borderColor: 'rgb(96, 165, 250)',
            borderWidth: 1,
            barPercentage: 0.5
        },
        {
            label: 'Shipments Received Second Day',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(99, 102, 241)',
            borderColor: 'rgb(99, 102, 241)',
            borderWidth: 1,
            barPercentage: 0.5
        },
        {
            label: 'Shipments Received Third Day',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            barPercentage: 0.5
        },
        {
            label: 'Shipments Received > 3 Days',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(252, 211, 77)',
            borderColor: 'rgb(252, 211, 77)',
            borderWidth: 1,
            barPercentage: 0.5
        },
        {
            label: 'Shipments Not Received',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(34, 197, 94) ',
            borderColor: 'rgb(34, 197, 94)',
            borderWidth: 1,
            barPercentage: 0.5
        }
    ],
}

export const currentShortageData = [
    { 'Tracking Number': '123456789', 'Manifest ID': 'M001', 'Manifest Receive Date': '2024-05-01', 'DC': 'DC1' },
    { 'Tracking Number': '987654321', 'Manifest ID': 'M002', 'Manifest Receive Date': '2024-05-02', 'DC': 'DC2' },
    { 'Tracking Number': '456789123', 'Manifest ID': 'M003', 'Manifest Receive Date': '2024-05-03', 'DC': 'DC3' },
    { 'Tracking Number': '789123456', 'Manifest ID': 'M004', 'Manifest Receive Date': '2024-05-04', 'DC': 'DC4' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Manifest Receive Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Manifest Receive Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Manifest Receive Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Manifest Receive Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Manifest Receive Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Manifest Receive Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Manifest Receive Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Manifest Receive Date': '2024-05-05', 'DC': 'DC5' },
]

export const overagesData =
{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Shipments Received Same Day',
            data: [65, 59, 80, 81, 56, 55, 40],
            backgroundColor: 'rgb(96, 165, 250)',
            borderColor: 'rgb(96, 165, 250)',
            borderWidth: 1,
            barPercentage: 0.5
        },
        {
            label: 'Shipments Received Second Day',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(99, 102, 241)',
            borderColor: 'rgb(99, 102, 241)',
            borderWidth: 1,
            barPercentage: 0.5
        },
        {
            label: 'Shipments Received Third Day',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            barPercentage: 0.5
        },
        {
            label: 'Shipments Received > 3 Days',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(252, 211, 77)',
            borderColor: 'rgb(252, 211, 77)',
            borderWidth: 1,
            barPercentage: 0.5
        },
        {
            label: 'Shipments Not Received',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(34, 197, 94) ',
            borderColor: 'rgb(34, 197, 94)',
            borderWidth: 1,
            barPercentage: 0.5
        }
    ],
}


export const streetPerfectData = {
    labels: ['Valid Addresses', 'Ambiguous'],
    datasets: [
        {
            data: [12, 19],
            backgroundColor: [
                'rgb(99, 102, 241)',
                'rgba(54, 162, 235, 0.5)',
            ],
            borderColor: [
                'rgb(99, 102, 241)',
                'rgba(54, 162, 235, 0.5)',
            ],
            borderWidth: 1,
        },
    ],
};


export const manifestDataData =
{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Expected Manifests',
            data: [65, 59, 80, 81, 56, 55, 40],
            backgroundColor: 'rgb(96, 165, 250)',
            borderColor: 'rgb(96, 165, 250)',
            borderWidth: 1,
            barPercentage: 0.3
        },
        {
            label: 'Manifests Received On-Time',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(99, 102, 241)',
            borderColor: 'rgb(99, 102, 241)',
            borderWidth: 1,
            barPercentage: 0.3
        },
        {
            label: 'Manifests Received Late',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            barPercentage: 0.3
        },
        {
            label: 'Manifests Not Received',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(252, 211, 77)',
            borderColor: 'rgb(252, 211, 77)',
            borderWidth: 1,
            barPercentage: 0.3
        },
        {
            label: 'Bad Manifest Records',
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgb(34, 197, 94)',
            borderColor: 'rgb(34, 197, 94)',
            borderWidth: 1,
            barPercentage: 0.3
        },
    ],
}


export const returnToSenderData = [
    { 'Tracking Number': '123456789', 'Manifest ID': 'M001', 'Return Date': '2024-05-01', 'DC': 'DC1' },
    { 'Tracking Number': '987654321', 'Manifest ID': 'M002', 'Return Date': '2024-05-02', 'DC': 'DC2' },
    { 'Tracking Number': '456789123', 'Manifest ID': 'M003', 'Return Date': '2024-05-03', 'DC': 'DC3' },
    { 'Tracking Number': '789123456', 'Manifest ID': 'M004', 'Return Date': '2024-05-04', 'DC': 'DC4' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Return Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Return Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Return Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Return Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Return Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Return Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Return Date': '2024-05-05', 'DC': 'DC5' },
    { 'Tracking Number': '321654987', 'Manifest ID': 'M005', 'Return Date': '2024-05-05', 'DC': 'DC5' },
]

export const environmentalImpact = {
    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    datasets: [
        {
            label: 'ICE Vehicles CO2 Emissions (kg)',
            data: [1200, 1150, 1100, 1050, 1000, 950, 900, 850, 800, 750, 700, 650],
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            fill: false,
            yAxisID: 'y-axis-1',
        },
        {
            label: 'EV Vehicles CO2 Emissions (kg)',
            data: [300, 320, 340, 360, 380, 400, 420, 440, 460, 480, 500, 520],
            borderColor: 'rgba(54, 162, 235, 1)',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            fill: false,
            yAxisID: 'y-axis-1',
        },
        {
            label: 'CO2 Emissions Averted (kg)',
            data: [900, 830, 760, 690, 620, 550, 480, 410, 340, 270, 200, 130],
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true,
            yAxisID: 'y-axis-2',
        },
    ],
}

export const ClientSummaryTableData = {
    columns : ['Summary', 'ON', 'QC', 'BC', 'AB', 'CON'],
    packageSummary: [
        { Summary: 'Attempted', ON: 132435, QC: 345678, BC: 12245, AB: 23345, CON: 1345 },
        { Summary: 'Delivered', ON: 1335, QC: 3478, BC: 245, AB: 3345, CON: 345 }
    ],
    dsvLeadTime: [
        { Summary: 'Shipments Receoved Same Day as Manifest', ON: 132435, QC: 345678, BC: 12245, AB: 23345, CON: 1345 }
    ],
    attemptLeadTime: [
        { Summary: 'Average Days To Deliver', ON: 1.00, QC: 1.02, BC: 0.04, AB: 1.03, CON: 2.04 },
        { Summary: 'Percentage Delivered Next Day', ON: '96.90%', QC: '12.34%', BC: '45.56%', AB: '12.56%', CON: '56.47' }
    ]
};