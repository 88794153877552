import { createAsyncThunk } from '@reduxjs/toolkit'
import { VehicleService } from 'services/vehicleService'
const vehicleService = new VehicleService();

export const fetchVehicles = createAsyncThunk(
    'vehicles/fetchVehicles',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await vehicleService.getVehicles(formData)
            return { vehicles: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchVehicleDetails = createAsyncThunk(
    'vehicles/fetchVehicleDetails',
    async (id, { rejectWithValue }) => {
        try {
            const response = await vehicleService.getVehicleDetails(id)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchVehiclesWithoutDrivers = createAsyncThunk(
    'vehicles/fetchVehiclesWithoutDrivers',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await vehicleService.getVehiclesWithoutDrivers(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addVehicle = createAsyncThunk(
    'vehicles/addVehicle',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await vehicleService.addVehicle(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const updateVehicle = createAsyncThunk(
    'vehicles/updateVehicle',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await vehicleService.updateVehicle(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const deleteVehicle = createAsyncThunk(
    'vehicles/deleteVehicle',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await vehicleService.deleteVehicle(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)