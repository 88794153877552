import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width : ${props => props?.width || '100%'};
  border: 1px solid #ccc;
  padding: 1rem;
  background-color: white;
`;



const GraphContainer = ({ children, width }) => {
  return (
    <Container width={width}>
      {children}
    </Container>
  );
};

export default GraphContainer;
