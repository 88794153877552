import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { getProgressBarColor } from 'utils/liveTrackingHelper'
import { iconStyle, getDateWithTimeStamp, getPercent } from 'utils/common'
import { ProgressBar } from 'component/common/ProgressBar/ProgressBar'
import MyLocationTwoToneIcon from '@mui/icons-material/MyLocationTwoTone';
import { Link } from '@mui/material';
import './liveTracking.scss';
import { RouteStopTable } from './RouteStopTable'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {routeStatusEnum} from 'constants/routeStatus'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary sx={{ padding: 0 }}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const LiveRoute = ({ data, user, selectedRoute, showStops, routeStops, handleMapRouteClick, handleNavigation, showTimeLine }) => {
    const [expanded, setExpanded] = React.useState(null);

    const handleChange = (item) => (event, newExpanded) => {
        setExpanded(newExpanded ? item?.routeId : false);
        if (newExpanded) {
            handleMapRouteClick(item?.routeId, showStops)
        }
    };

    if (!data || data.length === 0) {
        return <div>No data available</div>;
    }

    return (
        data?.length ? data?.map((item, index) => {
            const { color, backgroundImage } = getProgressBarColor(item?.atRisk, item?.outOfSequence)
            const percent = getPercent(item?.completedStops, item?.totalStops);

            return (
                <Accordion key={`${index}-${item?.driverName}`} expanded={expanded === item?.routeId} onChange={handleChange(item)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <div key={`${index}-${item?.driverName}`} className='item'>
                            <div style={{ width: '40%' }}>
                                <div className='item-title'>
                                    <Link id='routetId' style={{
                                        cursor: 'pointer', color: item?.routeStatus === routeStatusEnum?.ROUTE_TERMINATED ? 'red' : 'inherit', // Conditional color
                                    }} sx={{ textDecoration: 'none' }} target="_blank"

                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent the default behavior of the link
                                            handleNavigation({ routeId: item?.routeId });
                                        }}>
                                        {item?.routeId} / {item?.routeName}</Link></div>
                                <div className='item-title'>{item?.driverId} / {item?.driverName}</div>
                                <div className='item-content'>{item?.address}</div>
                                <div className='item-content'>Zone : {item?.primaryFsaZone}</div>
                                <div className='item-content'>{getDateWithTimeStamp(item?.time, user)}</div>
                            </div>
                            <div style={{ width: '50%' }}>
                                <ProgressBar totalStops={item?.totalStops} completedStops={item?.completedStops} remainingStops={item?.remainingStops} timeLeft={item?.timeLeft} color={color} backgroundImage={backgroundImage} colorPercent={percent} backgroundImagePercent={percent} />
                            </div>
                            <div style={{ width: '5%' }} className='icon'><MyLocationTwoToneIcon
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent the default behavior of the link
                                    e.stopPropagation(); // Prevent the default behavior of the link
                                    handleMapRouteClick(item?.routeId, item?.routeId === selectedRoute && showStops ? false : true)
                                }}
                                style={item?.routeId === selectedRoute && showStops ? iconStyle : null} /></div>
                            <div style={{ width: '5%' }}>
                                {item?.highPriorityCount ? <div className='icon'><ErrorOutlineIcon style={{ color: 'orange' }} /></div> : null}
                            </div>
                        </div>
                    </AccordionSummary>
                    {item?.routeId === selectedRoute ? <AccordionDetails>
                        <RouteStopTable routeStops={routeStops} user={user} />
                    </AccordionDetails> : null}
                </Accordion>)
        }) : null
    )
}
