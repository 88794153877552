import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { updateReturnPackage } from 'features/packages/packagesAction'
import 'component/dailyRoaster/AddDuty.scss'
import { CustomForm } from 'component/common/Form/CustomForm'
import { formatDate } from 'utils/common'
import { packageStatusEnum , unscessfullDeliveryAttemptStatusByDriver } from 'constants/packageStatus'
import { resetError } from 'features/packages/packagesSlice'
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';
import { getPackageIds } from 'utils/packageHelper';


const formInitialState = {
    returnStatus: '',
    deliveryDate: '',
    remark: '',
    deliveryAttemptStatus: ''
}

export const RescheduleDelivery = ({ isModelOpen, handleClose, allowedStatuses, widget }) => {
    const dispatch = useDispatch();
    const { selectedPackages } = useSelector(
        (state) => state?.packages
    )


    const { isMobile } = useSelector(
        (state) => state?.viewport
    )

    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    useEffect(() => {
        return () => {
            // This code will run when the component unmounts
            dispatch(resetError())
        };
    }, [])

    const handleFormSubmit = () => {
        const notRequiredFields = ['remark']
        if (formValues?.returnStatus?.value !== packageStatusEnum?.RTERMINAL_FDD) {
            notRequiredFields.push('deliveryDate')
        }
        if (formValues?.returnStatus?.value !== packageStatusEnum?.RTERMINAL_ATT_INVALID) {
            notRequiredFields.push('deliveryAttemptStatus')
        }
        const errors = validateForm(formValues, notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            const data = {}
            setFormErrors({})
            const { remark, deliveryDate, returnStatus, deliveryAttemptStatus } = formValues
            data['remark'] = remark
            data['widget'] = widget
            data['trackingNumbers'] = getPackageIds(selectedPackages);
            if (returnStatus?.value === packageStatusEnum?.RTERMINAL_FDD) {
                data['deliveryDate'] = formatDate(deliveryDate)
            }
            if (returnStatus?.value === packageStatusEnum?.RTERMINAL_ATT_INVALID) {
                data['returnStatus'] = deliveryAttemptStatus?.value
            }
            else{
                data['returnStatus'] = returnStatus?.value
            }
            dispatch(updateReturnPackage(data))
            handleClose();
        }
    }

    const formProps = {
        fields: [
            {
                label: 'Return Status',
                name: 'returnStatus',
                required: true,
                type: 'select',
                options: allowedStatuses,
                value: formValues?.returnStatus,
                handleFieldChange: handleFieldChange,
                error: formError?.returnStatus,
                width: '19rem',
                menuPlacement: 'bottom',
                dataTestId: 'form-return-status'
            },
            formValues?.returnStatus?.value === packageStatusEnum?.RTERMINAL_ATT_INVALID && {
                label: 'Delivery Attempt Status',
                name: 'deliveryAttemptStatus',
                required: true,
                type: 'select',
                options: unscessfullDeliveryAttemptStatusByDriver,
                value: formValues?.deliveryAttemptStatus,
                handleFieldChange: handleFieldChange,
                error: formError?.deliveryAttemptStatus,
                width: '19rem',
                menuPlacement: 'bottom',
                dataTestId : 'form-delivery-attempt-status'
            },
            formValues?.returnStatus?.value === packageStatusEnum?.RTERMINAL_FDD && {
                label: 'Induction Date',
                value: formValues?.deliveryDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: '19rem',
                name: 'deliveryDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.deliveryDate,
                required: true,
                min: new Date()?.toISOString()?.split('T')?.[0],
                dataTestId : 'form-delivery-date'
            },
            {
                label: 'Internal Comments',
                name: 'remark',
                type: 'textarea',
                value: formValues?.remark || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                maxRows: 4,
                minRows: 4,
                dataTestId : 'form-remark'
            }],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        fieldGap: '0.5rem',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: isMobile ? 'center' : 'unset'
    }

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>Update package status</span></div>
                <CustomForm {...formProps} allowOverflow='unset' />
            </div>
        </CustomModal>
    )

}