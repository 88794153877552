import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DrawerHeader } from './Layout.jsx';
import SideMenu from '../../sideMenu/sideMenu.jsx';

const drawerWidth = 230;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
      }),
  }),
);


export const CustomDrawer = ({ isOpen, handleDrawerClose }) => {
  return (
      <Drawer variant="permanent" open={isOpen} ModalProps={{ keepMounted: true }}>
          <DrawerHeader>
              <img src="/static/images/k-logo.png" alt="logo" />
              <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
              </IconButton>
          </DrawerHeader>
          <SideMenu open={isOpen} />
      </Drawer>
  );
};
