import React from 'react';
import { useSelector } from 'react-redux';
import ClientDashboard from './ClientDashboard';
import AdminDashboard from './AdminDashboard';

export const DailyDashboard = () => {
    const { user } = useSelector(
        (state) => state?.auth
    )
    const isClient = user?.authorities?.includes('ROLE_CUSTOMER')
    return (
        isClient ? <ClientDashboard /> : <AdminDashboard />
    );
};