import React from 'react'
import { CustomFormWithBox } from 'component/common/Form/CustomFormWithBox'
import { ClientFormFields } from './clientFormFields';

export const AddClient = ({ handleFieldChange, formValues, formError, handleMultiSelectCheckboxClicked }) => {
    const clientFormProps = ClientFormFields({ handleFieldChange, formError, formValues, handleMultiSelectCheckboxClicked });
    const formProps = {
        ...clientFormProps,
        margin: '0px',
        padding: '1rem'
    }

    return (
        <>
            <CustomFormWithBox {...formProps} />
        </>
    )
}