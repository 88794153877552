import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { AddUser } from './AddUser';
import { AddDriver } from './AddDriver';
import { AddClient } from './AddClient';
import { Layout } from 'component/common/Layout/Layout';
import { CardDetail } from 'component/dashboard/cardDetail'
import { getDCObject, getFSAZones, getUserRole } from 'utils/common'
import { validateForm } from 'utils/formValidator'
import { updateProfile, fetchTenants, fetchProfile } from 'features/userManagement/userManagementAction'
import { addDriver } from 'features/driverManagement/driverManagementAction'
import { resetUserData } from 'features/userManagement/userManagementSlice'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { resetDriverData } from 'features/driverManagement/driverManagementSlice'
import { resetClientData } from 'features/clientManagement/clientManagementSlice'
import { Loader } from 'component/common/Loader/Loader'
import { styled } from 'styled-components';
import './UserAccount.scss'
import { updateClient, addClient } from 'features/clientManagement/clientManagementAction';
import { languages } from 'constants/languageConstants'
import { roles, roleConstantEnum } from 'constants/roleConstants'
import { fetchLoggedInUserDetails } from 'features/auth/authAction'

const userFormData = {
    firstName: '',
    lastName: '',
    email: '',
    login: '',
    langKey: '',
    dcName: '',
    tenantName: '',
    authorities: '',
    userPhoto: null,
    tempPassword: '',
    activated: true
}

const driverFormData = {
    licenseNumber: '',
    driverType: '',
    inServiceStatus: 'ACTIVE',
    ratePerHr: '',
    currencyUnit: '',
    vehicle: '',
    vehicleType: '',
    onBoardingDate: new Date(),
    workEmail: '',
    workPhone: '',
    homePhone: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    province: '',
    postalCode: ''
}

const clientFormData = {
    businessName: '',
    clientCode: '',
    companyCode: '',
    fsaZones: '',
    serviceDays: '',
    deliveryDateBuffer: '',
    eligibilityDay: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    province: '',
    postalCode: ''
}

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    height: 40px;
    align-self: flex-end;
    @media (min-width: 820px) {
        margin-left: auto;
    }
`

const FormContainer = styled.div`
margin: 1rem;
width: -webkit-fill-available;
height: 100%;
margin: 0.5rem 0.5rem 0rem 0.5rem;
display: flex;
flex-direction: column;
`

export const ProfileUpdateForm = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [userFormValues, setUserFormValues] = useState(userFormData)
    const [driverFormValues, setDriverFormValues] = useState(driverFormData)
    const [clientFormValues, setClientFormValues] = useState(clientFormData)
    const [modalProps, setModalProps] = useState('')
    const [formError, setFormErrors] = useState({});
    const { addUserSuccess, dataCenters, error, loading, tenants, profileDetails } = useSelector(
        (state) => state?.userManagement
    )

    const { addDriverSuccess, error: driverError, loading: driverLoading } = useSelector(
        (state) => state?.driverManagement
    )

    const { fsaZones } = useSelector(
        (state) => state?.fsaZones
    )

    const { addClientSuccess, error: clientError } = useSelector(
        (state) => state?.clientManagement
    )

    const { user: userDetails } = useSelector(
        (state) => state?.auth
    )
    const userRole = getUserRole(userDetails)

    useEffect(() => {
        if (userDetails && userRole === roleConstantEnum?.ROLE_CUSTOMER) {
            dispatch(fetchProfile(userDetails))
        }
        if (!tenants?.length) {
            dispatch(fetchTenants())
        }
        return () => {
            // This code will run when the component unmounts
            dispatch(resetClientData())
            dispatch(resetDriverData())
            dispatch(resetUserData())
        };
    }, [userDetails])

    useEffect(() => {
        if (profileDetails) {
            if (userDetails?.authorities[0] === roleConstantEnum?.ROLE_CUSTOMER) {
                const clientFsaZones = fsaZones?.filter(zone => profileDetails?.serviceFsaZones?.includes(zone?.fsaZoneName));
                setClientFormValues({
                    businessName: profileDetails?.businessName,
                    clientCode: profileDetails?.clientCode,
                    companyCode: profileDetails?.companyCode,
                    fsaZones: getFSAZones(clientFsaZones),
                    serviceDays: profileDetails?.serviceDays?.split(',')?.map(day => day.trim()),
                    deliveryDateBuffer: profileDetails?.deliveryDateBuffer,
                    eligibilityDay: profileDetails?.eligibilityDay,
                    addressOne: profileDetails?.addressOne,
                    addressTwo: profileDetails?.addressTwo,
                    city: profileDetails?.city,
                    province: profileDetails?.province,
                    postalCode: profileDetails?.postalCode,
                });
            }
        }
    }, [profileDetails, fsaZones]);

    useEffect(() => {
        if (userDetails) {
            const photo = userDetails?.userPhoto ? `data:image\/(png|jpeg|jpg);base64,${userDetails?.userPhoto}` : null
            const language = userDetails?.langKey ? languages?.find(lang => lang.value === userDetails?.langKey) : null
            const tenant = userDetails?.tenantName ? tenants?.find(tenant => tenant?.tenantName === userDetails?.tenantName) : null
            const dc = userDetails?.distributionCenterResponseVm ? dataCenters?.find(dc => dc.dcName === userDetails?.distributionCenterResponseVm?.dcName) : null
            const authorities = userDetails?.authorities ? roles?.find(role => role.value === userDetails?.authorities[0]) : null
            setUserFormValues({
                firstName: userDetails?.firstName,
                lastName: userDetails?.lastName,
                email: userDetails?.email,
                login: userDetails?.login,
                langKey: language,
                dcName: { label: dc?.dcName, value: dc?.dcName, ...dc },
                tenantName: { label: tenant?.tenantName, value: tenant?.tenantName },
                authorities: authorities,
                userPhoto: photo,
                tempPassword: '',
                activated: userDetails?.activated
            });
        }
    }, [userDetails, dataCenters, tenants]);

    const handleFieldChange = (event) => {
        if (event?.target?.name === 'activated') {
            const name = event?.target?.name
            const value = event?.target?.checked
            setUserFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
        else {
            const { name, value } = event.target;
            if (activeStep === 0) {
                setUserFormValues((prevValues) => ({
                    ...prevValues,
                    [name]: value,
                }));
            }
            else {
                if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_DRIVER) {
                    setDriverFormValues((prevValues) => ({
                        ...prevValues,
                        [name]: value,
                    }));
                }
                if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_CUSTOMER) {
                    setClientFormValues((prevValues) => ({
                        ...prevValues,
                        [name]: value,
                    }));
                }
            }
        }
    };

    const handleMultiSelectCheckboxClicked = (value) => {
        if (typeof value === 'object') {
            setClientFormValues((prevValues) => ({
                ...prevValues,
                serviceDays: value,
            }));
        }
        else {
            setClientFormValues((prevItems) => {
                // Check if the value is already in the list
                if (prevItems.serviceDays?.includes(value)) {
                    // If yes, remove it
                    return {
                        ...prevItems,
                        serviceDays: prevItems?.serviceDays?.filter((item) => item !== value),
                    };
                } else {
                    // If not, add it to the list
                    return {
                        ...prevItems,
                        serviceDays: [...(prevItems?.serviceDays || []), value],
                    };
                }
            });
        }
    }

    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result;
                setUserFormValues((prevValues) => ({
                    ...prevValues,
                    'userPhoto': base64Data
                }));
            };

            reader.readAsDataURL(file);
        }
    }

    useEffect(() => {
        // Proceed to the next step
        if (addUserSuccess && [roleConstantEnum?.ROLE_DRIVER, roleConstantEnum?.ROLE_CUSTOMER]?.includes(userFormValues?.authorities?.value)) {
            setActiveStep((prevStep) => prevStep + 1);
            dispatch(resetUserData())
        }
        else {
            if (addUserSuccess) {
                setModalProps(
                    {
                        title: 'Success',
                        message: 'Profile updated successfully',
                        open: true,
                        type: 'success'
                    }
                )
                const redirectTimer = setTimeout(() => {
                    setModalProps(null)
                    dispatch(resetUserData())
                    //This will be used to get updated info as we dont have profile api for CSR,SUPERVISOR etc
                    dispatch(fetchLoggedInUserDetails())

                    // navigate('/user-account')
                }, 2000);
                return () => {
                    clearTimeout(redirectTimer);
                }
            }
        }
    }, [addUserSuccess]);


    useEffect(() => {
        if (error || driverError || clientError) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error || driverError || clientError,
                    open: true,
                    type: error
                }
            )

        }
    }, [error, driverError || clientError]);

    useEffect(() => {
        if (addDriverSuccess || addClientSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Profile updated successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                setModalProps(null)
                dispatch(resetUserData())
                dispatch(resetDriverData())
                dispatch(resetClientData())
                dispatch(fetchLoggedInUserDetails())
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [addDriverSuccess || addClientSuccess]);


    const handleNext = () => {
        if (activeStep === 0) {
            // If it's the first step, call handleUserSubmit
            handleUserSubmit();
        } else if (activeStep === 1) {
            // If it's the second step, call handleDriverSubmit
            userFormValues?.authorities?.value === 'ROLE_DRIVER' ? handleDriverSubmit() : handleClientSubmit();
        }
    };

    const handleUserSubmit = () => {
        const notRequiredFields = ['userPhoto', 'activated', 'tempPassword']
        const errors = validateForm(userFormValues, notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const { authorities, userPhoto, langKey, tenantName, dcName, activated, ...data } = userFormValues
            data['authorities'] = [authorities?.value]
            data['langKey'] = langKey?.value
            data['tenantName'] = tenantName?.value
            if (dcName?.value) {
                data['distributionCenterResponseVm'] = getDCObject(dcName?.value, dataCenters)
            }
            data['activated'] = activated
            data['id'] = userDetails?.id
            if (userPhoto) {
                data['userPhoto'] = userPhoto?.split(',')?.[1];
            }
            dispatch(updateProfile(data))
        }
    };

    const handleDriverSubmit = () => {
        const notRequiredFields = ['vehicle', 'addressTwo']
        const errors = validateForm(driverFormValues, notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const { currencyUnit, driverType, vehicle, inServiceStatus, loginName, vehicleType, ...data } = driverFormValues
            data['currencyUnit'] = currencyUnit?.value
            data['driverType'] = driverType?.value
            data['loginName'] = loginName?.value
            data['vehicleType'] = vehicleType?.value
            data['inServiceStatus'] = inServiceStatus?.value
            if (vehicle) {
                data['vehicleId'] = vehicle?.value
            }
            data['loginName'] = userDetails?.login
            dispatch(addDriver(data))
        }
    };

    const handleClientSubmit = () => {
        const notRequiredFields = []
        const errors = validateForm(clientFormValues, notRequiredFields);
        if (clientFormValues?.deliveryDateBuffer < clientFormValues?.eligibilityDay) {
            errors['deliveryDateBuffer'] = 'Estimated Delivery Date Buffer should be greater than eligibility day'
        }
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const { fsaZones, ...data } = clientFormValues
            data['serviceFsaZones'] = fsaZones.map(item => item.label);
            data['loginName'] = profileDetails?.user?.login
            //This is to verfity is client was not created by admin create it else update
            if (profileDetails?.id !== undefined) {
                data['id'] = profileDetails?.id
                dispatch(updateClient(data))
            }
            else {
                data['loginName'] = userDetails?.login
                dispatch(addClient(data))
            }
        }
    };


    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <AddUser handleFieldChange={handleFieldChange} formValues={userFormValues} onSubmit={handleUserSubmit} handleImageUpload={handleImageUpload} formError={formError} isEdit={true} />;
            case 1:
                return userFormValues?.authorities?.value === 'ROLE_DRIVER' ? <AddDriver handleFieldChange={handleFieldChange} formValues={driverFormValues} onSubmit={handleDriverSubmit} formError={formError} loading={driverLoading} /> : userFormValues?.authorities?.value === 'ROLE_CUSTOMER' ? <AddClient handleFieldChange={handleFieldChange} handleMultiSelectCheckboxClicked={handleMultiSelectCheckboxClicked} formValues={clientFormValues} onSubmit={handleClientSubmit} formError={formError} /> : null;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Layout headerTitle={'Profile'} showBackArrow={false}>
            <CardDetail>
                <FormContainer>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        <Step>
                            <StepLabel>User Details</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Profile Details</StepLabel>
                        </Step>
                    </Stepper>
                    {getStepContent(activeStep)}
                    <ButtonContainer>
                        <Button disabled={activeStep === 0} onClick={handleBack}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleNext()}
                        >
                            {activeStep === 1 || !['ROLE_DRIVER', 'ROLE_CUSTOMER']?.includes(userFormValues?.authorities?.value) ? 'Finish' : 'Next'}
                        </Button>
                    </ButtonContainer>
                </FormContainer>
                {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : ''}
                {loading ? <Loader isLoading={loading} /> : null}
            </CardDetail>

        </Layout>
    );
}