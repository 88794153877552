export const fuelTypeEnum = {
    PETROL : 'PETROL',
    DIESEL: 'DIESEL',
    ELECTRIC: 'ELECTRIC',
    HYBRID: 'HYBRID'
}

export const fuelTypeConstants = [
    {
        label: 'Petrol',
        value: fuelTypeEnum?.PETROL
    },
    {
        label: 'Disel',
        value: fuelTypeEnum?.DIESEL
    },
    {
        label: 'Electric',
        value: fuelTypeEnum?.ELECTRIC
    },
    {
        label: 'Hybrid',
        value: fuelTypeEnum?.HYBRID
    },
]
