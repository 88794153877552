import axios from 'axios';
import { resetLocalStorage } from 'utils/authHelper'

const api = axios.create({
  // API configurations
});

// Interceptor for handling 401 errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    //If request is for login and get 401, then error message should be displayed for other api, it should redirect to login
    if (error.response && error.response.status === 401 && !error.config.url.includes('/api/authenticate')) {
      handleUnauthorized()
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  return config;
});

const handleUnauthorized = () => {
  //Reset token and login name
  resetLocalStorage()
  window.dispatchEvent(new CustomEvent('unauthorized', { detail: 401 }));
  // Redirect to the login page
  window.location.href = '/login';
};

export default api;
