import React from "react";
import styled from 'styled-components';
import './SelectInput.scss';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

export const SelectWrapper = styled.div`
  display: flex;
  height: 30px;
`

const InputField = styled.input`
border: 1px solid #999999;
border-radius: 0 7px 7px 0;
position: relative;
height: 30px;
&::placeholder {
  color: #999;
  font-size: 14px;
}
`
const SearchIconWrapper = styled.span`
  position: absolute;
  transform: translateY(-50%);
  top: 65%;
  right: 5px;
`;

const StyledInputWrapper = styled.div`
  position: relative;
`;

const ArrowIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow doesn't capture click events */
  /* Add your arrow styling here */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
`;


export const SelectInput = ({ searchKeyName, searchTextName, selectOptions, selectValue, inputValue, onInputChange, onSelectChange, onSearchClick, placeHolder, onClearClick }) => {
  return (
    <SelectWrapper>
      <div className='select-input-wrapper'>
        <select
          className='tracking-selectField'
          name={searchKeyName}
          onChange={onSelectChange}
          value={selectValue}
          data-testid='tracking-selectField'
        >
          {selectOptions?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <ArrowIcon />
      </div>
      <StyledInputWrapper>
        <InputField type="text" value={inputValue || ''} onChange={onInputChange} name={searchTextName} placeholder={placeHolder}/>
        <SearchIconWrapper><SearchIcon sx={{ height: 18, width: 18, cursor: 'pointer' }} onClick={onSearchClick} /> {inputValue && <ClearIcon sx={{ height: 18, width: 18, cursor: 'pointer' }} onClick={onClearClick} />}</SearchIconWrapper>
      </StyledInputWrapper>
    </SelectWrapper>
  );
};