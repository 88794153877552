import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Collapse,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const DraggableRow = ({
  row,
  index,
  moveRow,
  prepareRow,
  handleRowSelect,
  selectedRowIds,
  uniqueKey,
  allowSelect,
  isCollpsable,
  expandedRows,
  toggleRowExpansion,
  rowKey,
  fetchDataByNestedKey,
  ChildComponent,
}) => {
  const rowId = rowKey ? fetchDataByNestedKey(row?.original, rowKey) : null;
  const isExpanded = expandedRows.includes(rowId);

  const [{ isDragging }, drag] = useDrag({
    type: 'ROW',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'ROW',
    drop: (item) => {
      const sourceIndex = item.index;
      const targetIndex = index;
      if (sourceIndex !== targetIndex) {
        moveRow(sourceIndex, targetIndex);
      }
    },
  });

  prepareRow(row);

  const opacity = isDragging ? 0.5 : 1;
  const { key: rowKUniqueKey, ...restProps } = row.getRowProps();
  return (
    <React.Fragment>
      <TableRow ref={(node) => drag(drop(node))} style={{ opacity }} key={rowKUniqueKey} {...restProps}>
        {allowSelect && (
          <TableCell className="table-row-cell" style={{ maxWidth: '1rem' }}>
            <Checkbox
              checked={!!selectedRowIds[row?.original[uniqueKey]]}
              onChange={() => handleRowSelect(row?.original[uniqueKey])}
            />
          </TableCell>
        )}
        {row.cells.map((cell) => {
          const { key, ...restProps } = cell.getCellProps();
          return (<TableCell key={`${rowKUniqueKey}-${key}`} className="table-row-cell" data-testid={cell?.column?.id} {...restProps}>
            {cell.render('Cell')}
          </TableCell>)
        })}
        {isCollpsable && (
          <TableCell className="table-row-cell" align="center">
            <IconButton aria-label="expand row" size="small" onClick={() => toggleRowExpansion(row)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={row.cells.length + (isCollpsable ? 1 : 0)}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <ChildComponent id={fetchDataByNestedKey(row?.original, rowKey)} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default DraggableRow;
