import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import { splitRoute } from 'features/route/routeAction'
import 'component/dailyRoaster/AddDuty.scss'
import { CustomModal } from 'component/common/Modal/CustomModal'

export const SplitRoute = ({ isModelOpen, handleClose, route }) => {
    const dispatch = useDispatch();
    const [checkedTotes, setCheckedTotes] = useState([]);
    const totes = route?.routeWidgetVM?.assignedToteIds;

    const handleFormSubmit = () => {
        dispatch(splitRoute({
            routeId: route?.routeWidgetVM?.routeId,
            toteToKeepInOriginalRoute: checkedTotes,
            toteToRemoveFromRoute: totes?.filter(tote => !checkedTotes?.includes(tote)) // Tote IDs in assignedTotes but not in checkedTotes
        }))
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        const toteId = name;
        const currentIndex = totes.indexOf(toteId);

        if (checked) {
            // Add the checked toteId to the list
            setCheckedTotes(prevState => [...prevState, toteId]);
        } else {
            // Uncheck the current and all subsequent checkboxes
            setCheckedTotes(prevState => prevState.filter((tote, index) => index < currentIndex));
        }
    };

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>{'Select totes to keep in this route'}</span></div>
                <div>
                    <span>Route ID : {route?.routeWidgetVM?.routeId} </span>
                    <FormGroup>
                        {totes?.map((toteId, index) => {
                            const isChecked = checkedTotes.includes(toteId);
                            const isDisabled = index > 0 && !checkedTotes.includes(totes[index - 1]);

                            return (
                                <FormControlLabel
                                    key={toteId}
                                    control={
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            name={toteId}
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={toteId}
                                />
                            );
                        })}
                    </FormGroup>
                </div>
                <div className='button-container'>
                    <Button onClick={handleFormSubmit} disabled={!checkedTotes?.length} variant='outlined'>Save</Button>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                </div>
            </div>
        </CustomModal>
    )

}