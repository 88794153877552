import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const data = [{
    parent: 'Client',
    child: ['Client1', 'Client2']
},
{
    parent: 'DC',
    child: ['Missisuaga']
}]

export default function IndeterminateCheckbox() {
    const initialCheckedState = data.map((parent) =>
        parent.child.map(() => false)
    );

    const [checked, setChecked] = React.useState(initialCheckedState);

    const handleParentChange = (parentIndex, event) => {
        const newChecked = [...checked];
        const isChecked = event.target.checked;
        newChecked[parentIndex] = newChecked[parentIndex].map(() => isChecked);
        setChecked(newChecked);
    };

    const handleChildChange = (parentIndex, childIndex, event) => {
        const newChecked = [...checked];
        newChecked[parentIndex][childIndex] = event.target.checked;
        setChecked(newChecked);
    };

    return (
        <div>
            {data.map((parent, parentIndex) => {
                const allChecked = checked[parentIndex].every(Boolean);
                const someChecked = checked[parentIndex].some(Boolean) && !allChecked;

                return (
                    <>
                        <FormControlLabel
                            label={parent.parent}
                            control={
                                <Checkbox
                                    checked={allChecked}
                                    indeterminate={someChecked}
                                    onChange={(event) => handleParentChange(parentIndex, event)}
                                    sx={{p: '5px'}}
                                />
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            {parent.child.map((child, childIndex) => (
                                <FormControlLabel
                                    key={child}
                                    label={child}
                                    control={
                                        <Checkbox
                                        sx={{p: '5px'}}
                                        checked={checked[parentIndex][childIndex]}
                                            onChange={(event) =>
                                                handleChildChange(parentIndex, childIndex, event)
                                            }
                                        />
                                    }
                                />
                            ))}
                        </Box>
                        </>
                );
            })}
        </div>
    );
}
