import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from 'utils/urls'

const knownMapErrors = [
    "Script error.",
    "Failed to load resource"
];

function ErrorBoundary(props) {
    const navigate = useNavigate();

    const handleError = () => {
      // console.error(error);
      navigate(urls?.ERROR_PAGE_URL);
    };

    useEffect(() => {
        // Define a global error handler for the Here Map SDK
        function handleMapError(error) {
            if (error && error.target && knownMapErrors.includes(error.message)) {
                // Suppress errors originating from mapsjs-core.js
                console.log('Suppressed Map SDK Error:', error?.message);
                // Prevent the default Here Map error popup
                error.preventDefault();
            } else {
                // Handle other errors as needed
                console.error('Global Error:', error);
                error?.preventDefault();
                error?.preventDefault();
                handleError()
                // Optionally, navigate to the error page here if needed
            }
        }

        // Attach the custom error handler to the window's error event
        window.addEventListener('error', handleMapError);

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('error', handleMapError);
        };
    }, []);

    return props.children;
}

export default ErrorBoundary;
