export const toteStatuses = [
    {
        label: 'Assigned',
        value: 'ASSIGNED'
    },
    {
        label: 'Not Assigned',
        value: 'NOT_ASSIGNED'
    },
    {
        label: 'Scanned',
        value: 'SCANNED'
    },
    {
        label: 'Missing',
        value: 'MISSING'
    },
]