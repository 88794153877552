import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);


const DoughnutCharts = ({data}) => {
  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:'400px'}}>
      <Doughnut data={data}/>
    </div>
  )
}

export default DoughnutCharts;