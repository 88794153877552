import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { fetchRouteStopDetails } from 'features/route/routeAction'
import styled from 'styled-components';
import { ImageCarousel } from 'component/common/carousal/ImageCarousel';
import { fetchAssetImages } from 'features/packages/packagesAction'
import { getDistanceDiff } from 'utils/routeHelper'

const Text = styled.div`
font-family: 'degular';
color: #1A1A1A;
font-weight: 600;
`;

export const PopupWithImage = ({ open, onClose, routeStop, parcel, user }) => {
    const dispatch = useDispatch()
    const { routeStopDetails, loading } = useSelector(
        (state) => state?.route
    )
    const { assetImages } = useSelector(
        (state) => state?.packages
    )

    useEffect(() => {
        if (routeStop && open) {
            dispatch(fetchRouteStopDetails(routeStop?.routeStopId))
        }
    }, [routeStop])

    useEffect(() => {
        if (parcel && open) {
            dispatch(fetchAssetImages(parcel?.trackingNumber))
        }
    }, [parcel])

    const imageData = routeStopDetails?.routeStopImageVm?.[0] || null
    const stopImage = imageData ? `data:image\/(png|jpeg|jpg);base64,${imageData?.image}` : null
    let distancdDiff = null
    if (imageData) {
        try {
            distancdDiff = getDistanceDiff(routeStop?.lat, imageData?.imageLat, routeStop?.lon, imageData?.imageLon)
        }
        catch (e) {
            console.log('Exception', e)
        }
    }


    return (
        <Dialog open={open} onClose={onClose} sx={{ padding: 0 }}>
            <DialogContent sx={{ overflow: 'hidden' }}>
                {routeStop ? <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                    <Paper
                        square
                        elevation={0}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            bgcolor: 'background.default',
                            flexDirection: 'column'
                        }}
                    >
                        <Text>Arrived Location: {routeStop?.lat}, {routeStop?.lon}</Text>
                        <Text>Image Capture Location: {imageData ? `${imageData?.imageLat}, ${imageData?.imageLon}` : 'NA'}</Text>
                        <Text>Distance between delivery : {distancdDiff || 'NA'}</Text>
                        <Text>Delivery Time: {routeStopDetails?.stopCompletionTime}</Text>
                    </Paper>
                    <Box
                        sx={{
                            height: 200,
                            maxWidth: 200,
                            overflow: 'hidden',
                            width: '100%',
                            position: 'relative',
                            border: '1px solid gray'
                        }}
                    >
                        {stopImage ? (
                            <Box
                                component="img"
                                sx={{
                                    height: '100%',
                                    display: 'block',
                                    width: '100%',
                                    objectFit: 'contain'
                                }}
                                src={stopImage}
                                alt="Image"
                            />
                        ) : (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    color: 'grey'
                                }}
                            >
                                {loading ? 'Loading...' : 'No Image Available'}
                            </Box>
                        )}
                    </Box>
                </Box> :
                    parcel ? <ImageCarousel images={assetImages} loading={loading} user={user} /> : null}
            </DialogContent>
        </Dialog>
    );
};