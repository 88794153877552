import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { fetchClients } from 'features/clientManagement/clientManagementAction'
import { useNavigate } from "react-router-dom";
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { CardDetail } from 'component/dashboard/cardDetail'
import { TableHeader } from 'component/dataTable/tableHeader';
import { Audit } from 'component/audit/audit'
import { Tooltip } from '@mui/material';
import { checkPermission, getDateWithTimeStamp, handleApplyFilter, getUserDCOption } from 'utils/common'
import { TableFilter } from 'component/dataTable/tableFilter';
import { statuses } from 'constants/userStatusConstants'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';

import '../userAccount/UserAccount.scss'

const rowKey = "id"

const formInitialState = {
    businessName: '',
    clientCode: '',
    companyCode: '',
    activated: '',
    loginName: ''
}

export const Client = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { clients, error, loading, clientCount } = useSelector(
        (state) => state?.clientManagement
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const [page, setPage] = useState(0);
    const [modalProps, setModalProps] = useState(null)
    const [sorteData, setSortedData] = useState(clients);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [applyFilter, setApplyFilter] = useState(false);

    const hasEditPermission = checkPermission(user, 'CLIENT MANAGEMENT')

    useEffect(() => {
        const data = {}
        const {businessName, loginName, clientCode, companyCode, activated} = formValues
        if (activated?.value) {
            data['activated'] = activated?.value || null
        }
        if(businessName){
            data['businessName'] = businessName?.trim()
        }
        if(loginName){
            data['loginName'] = loginName?.trim()
        }
        if(clientCode){
            data['clientCode'] = clientCode?.trim()
        }
        if(companyCode){
            data['companyCode'] = companyCode?.trim()
        }
        dispatch(fetchClients({
            page: page,
            size: rowsPerPage,
            ...data,
            dcName: getUserDCOption(user)?.value
        }))
    }, [page, rowsPerPage, applyFilter])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
        else {
            // No error, close modal or reset modal props
            setModalProps({
                title: '',
                message: '',
                open: false,
                type: ''
            });
        }
    }, [error]);

    const columns = [
        {
            accessor: "id",
            title: "ID",
            width: "4rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.id || '-'
        },
        {
            accessor: "businessName",
            title: "Business Name",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.name || '-'
        },
        {
            accessor: "loginName",
            title: "User Name",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.loginName || '-'
        },
        {
            accessor: "clientCode",
            title: "Client Code",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.clientCode || '-'
        },
        {
            accessor: "companyCode",
            title: "Company Code",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.companyCode || '-'
        },
        {
            accessor: 'serviceFsaZones',
            title: "FSA Zones",
            width: "4rem",
            Cell: ({ cell: { value } }) => value?.length ? value?.join(',') : '--',
            render: (item) => item?.serviceFsaZones?.length ? item?.serviceFsaZones?.join(',') : '--'
        },
        {
            accessor: 'serviceDays',
            title: "Service Days",
            width: "4rem",
            Cell: ({ cell: { value } }) => value?.length ? value?.length === 7 ? 'All' : value?.join(',') : '--',
            render: (item) => item?.serviceDays?.length ? item?.serviceDays?.length === 7 ? 'All' :item?.serviceDays?.join(',') : '--'
        },
        {
            accessor: "deliveryDateBuffer",
            title: "Delivery Date Buffer",
            width: "6rem",
            Cell: ({ cell: { value } }) => value,
            render: (item) => item?.deliveryDateBuffer
        },
        {
            accessor: "eligibilityDay",
            title: "Eligibility Day",
            width: "6rem",
            Cell: ({ cell: { value } }) => value,
            render: (item) => item?.eligibilityDay
        },
        {
            accessor: 'createDate',
            title: "Created Date",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
            render: (item) => item?.createDate ? getDateWithTimeStamp(item?.createDate, user) : '--'
        },
        hasEditPermission && {
            accessor: 'action',
            title: "Action",
            width: "0.5rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action' ><Tooltip title="Edit"><img src="/static/images/edit-icon.svg" alt='edit' onClick={() => handleEditClientClick(original?.id)} /></Tooltip>
            </div>
        }
    ]?.filter(Boolean)

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleEditClientClick = (id) => {
        if (id) {
            navigate(`${urls?.EDIT_CLIENT_WIDGET_URL}?id=${id}`)
        }
    }

    const handleResetFilter = () => {
        setFormValues(formInitialState)
        setFormErrors({})
    }

    const handleApplyFilterClick = () => {
        handleApplyFilter(formValues, formInitialState, setFormErrors, setApplyFilter, setPage, applyFilter);
    }

    const tableFilterProps = {
        fields: [{
            label: 'Business Name',
            value: formValues?.businessName,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'businessName',
            error: formError?.businessName,
            placeholder: 'Business Name'
        },
        {
            label: 'User Name',
            value: formValues?.loginName,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'loginName',
            error: formError?.loginName,
            placeholder: 'User Name'
        },
        {
            label: 'Client Code',
            value: formValues?.clientCode,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'clientCode',
            error: formError?.clientCode,
            placeholder: 'Client Code'
        },
        {
            label: 'Company Code',
            value: formValues?.companyCode,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '12rem',
            name: 'companyCode',
            error: formError?.companyCode,
            placeholder: 'Company Code'
        },
        // {
        //     label: 'Created Date',
        //     value: formValues?.createdDate,
        //     handleFieldChange: handleFieldChange,
        //     type: 'date',
        //     width: isMobile ? '9rem' : '12rem',
        //     name: 'createdDate',
        //     placeholder: 'DD-MM-YYYY',
        //     error: formError?.createdDate
        // },
        {
            label: 'Active',
            value: formValues?.activated,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '12rem',
            options: statuses,
            name: 'activated'
        }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px',
    }


    return (
        <Layout headerTitle={'Clients'} showBackArrow={false} >
            <CardDetail>
                <div className='container'>
                    <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Clients' onRefreshClick={handleApplyFilterClick}/>
                    <TableFilter {...tableFilterProps} />
                    <div className='content'>
                        <DataTable columns={columns} data={clients || []} rowKey={rowKey} onSortChange={setSortedData} uniqueKey={'id'} setUpdatedColumns={setUpdatedColumns} showPagination={clients?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={clientCount} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
                    </div>
                </div>
            </CardDetail>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            <Audit />
        </Layout>
    )
}