export const vehicleTypeConstants = [
    {
        label: 'Sedan',
        value: 'SEDAN'
    },
    {
        label: 'SUV',
        value: 'SUV'
    },
    {
        label: 'Mini-Van',
        value: 'MINIVAN'
    },
    {
        label: 'Cargo',
        value: 'CARGO'
    },
    {
        label: 'EV',
        value: 'EV'
    }
]