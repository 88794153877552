import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Layout } from 'component/common/Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { CustomForm } from 'component/common/Form/CustomForm'
import { Loader } from 'component/common/Loader/Loader'
import { fetchVehicleDetails, addVehicle, updateVehicle } from 'features/vehicle/vehicleAction'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { validateForm } from 'utils/formValidator'
import { capacities } from 'constants/capacityConstant'
import { vehicleStatusConstant } from 'constants/vehicleStatusConstant'
import { materialTypes } from 'constants/materialTypeConstant'
import { volumeUnit } from 'constants/volumeUnitConstant'
import { weightUnit } from 'constants/weightUnitConstant'
import { runningRate } from 'constants/runningrateConstant'
import { fuelTypeConstants, fuelTypeEnum } from 'constants/fuelTypeConstant'
import { resetVehicleData } from 'features/vehicle/vehicleSlice'
import { vehicleTypeConstants } from 'constants/vehicleTypeConstants'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';

const formInitialState = {
    registrationNumber: '',
    vehicleModel: '',
    fuelCapacity: '',
    fuelCapacityUnit: '',
    fuelType: '',
    vehicleStatus: '',
    insuranceValidTill: '',
    materialType: '',
    maxCapacityVolume: '',
    maxCapacityWeight: '',
    offBoardingDate: '',
    onBoardingDate: '',
    runningRate: '',
    runningRateUnit: '',
    volumeUnit: '',
    weightUnit: '',
    vehicleType: ''
}

export const AddVehicle = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const isEdit = id ? true : false
    const [modalProps, setModalProps] = useState(null)
    const { success, error, loading, vehicleDetails } = useSelector(
        (state) => state?.vehicles
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    useEffect(() => {
        if (isEdit) {
            dispatch(fetchVehicleDetails(id))
        }
    }, [isEdit]);
    
    useEffect(() => {
        return () => {
            setModalProps(null)
            // This code will run when the component unmounts
            dispatch(resetVehicleData())
        };
    }, [])

    const handleFormSubmit = () => {
        const notRequiredFields = ['offBoardingDate']
        if(formValues?.fuelType?.value === fuelTypeEnum?.ELECTRIC){
            notRequiredFields?.push('fuelCapacity', 'fuelCapacityUnit')
        }
        const errors = validateForm(formValues, notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const { fuelCapacityUnit, fuelType, vehicleStatus, materialType, runningRateUnit, volumeUnit, weightUnit, vehicleType, ...data } = formValues
            data['fuelCapacityUnit'] = fuelCapacityUnit?.value
            data['fuelType'] = fuelType?.value
            data['vehicleRosterStatus'] = vehicleStatus?.value
            data['materialType'] = materialType?.value
            data['runningRateUnit'] = runningRateUnit?.value
            data['volumeUnit'] = volumeUnit?.value
            data['weightUnit'] = weightUnit?.value
            data['vehicleType'] = vehicleType?.value
            if(fuelType?.value === fuelTypeEnum?.ELECTRIC){
                data['fuelCapacity'] = null
                data['fuelCapacityUnit'] = null
            }
            if (isEdit) {
                data['id'] = id
                data['distributionCenter'] = vehicleDetails?.distributionCenter
            }
            dispatch(isEdit ? updateVehicle(data) : addVehicle(data))
        }
    }

    const handleCancel = () => {
        dispatch(resetVehicleData())
        navigate(urls?.VEHICLE_PROFILE_WIDGET_URL)
    }

    useEffect(() => {
        if (vehicleDetails) {
            setFormValues({
                registrationNumber: vehicleDetails?.registrationNumber,
                vehicleModel: vehicleDetails?.vehicleModel,
                fuelCapacity: vehicleDetails?.fuelCapacity,
                fuelCapacityUnit: vehicleDetails?.fuelCapacityUnit ? capacities.find(capacity => capacity.value === vehicleDetails?.fuelCapacityUnit) : null,
                vehicleStatus: vehicleDetails?.vehicleRosterStatus ? vehicleStatusConstant.find(service => service.value === vehicleDetails?.vehicleRosterStatus) : null,
                fuelType: vehicleDetails?.fuelType ? fuelTypeConstants.find(fuelType => fuelType.value === vehicleDetails?.fuelType) : null,
                materialType: vehicleDetails?.materialType ? materialTypes.find(materialType => materialType.value === vehicleDetails?.materialType) : null,
                maxCapacityWeight: vehicleDetails?.maxCapacityWeight,
                maxCapacityVolume: vehicleDetails?.maxCapacityVolume,
                volumeUnit: vehicleDetails?.volumeUnit ? volumeUnit.find(unit => unit.value === vehicleDetails?.volumeUnit) : null,
                weightUnit: vehicleDetails?.weightUnit ? weightUnit.find(unit => unit.value === vehicleDetails?.weightUnit) : null,
                runningRate: vehicleDetails?.runningRate,
                runningRateUnit: vehicleDetails?.runningRateUnit ? runningRate.find(runningRateUnit => runningRateUnit.value === vehicleDetails?.runningRateUnit) : null,
                onBoardingDate: vehicleDetails?.onBoardingDate,
                offBoardingDate: vehicleDetails?.offBoardingDate,
                insuranceValidTill: vehicleDetails?.insuranceValidTill,
                vehicleType: vehicleDetails?.vehicleType ? vehicleTypeConstants.find(type => type.value === vehicleDetails?.vehicleType) : null,
            });
        }
    }, [vehicleDetails]);

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (success) {
            setModalProps(
                {
                    title: 'Success',
                    message: isEdit ? 'Vehicle updated successfully' : 'Vehicle created successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                dispatch(resetVehicleData())
                navigate(urls?.VEHICLE_PROFILE_WIDGET_URL)
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [success]);

    const vehicleFormProps = {
        fields: [{
            label: 'Registration Number',
            name: 'registrationNumber',
            required: true,
            type: 'text',
            value: formValues?.registrationNumber || '',
            handleFieldChange: handleFieldChange,
            error: formError?.registrationNumber,
            maxLength: 50,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Vehicle Type',
            name: 'vehicleType',
            required: true,
            type: 'select',
            options: vehicleTypeConstants,
            value: formValues?.vehicleType || '',
            handleFieldChange: handleFieldChange,
            error: formError?.vehicleType,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Vehicle Model',
            name: 'vehicleModel',
            required: true,
            type: 'text',
            value: formValues?.vehicleModel || '',
            handleFieldChange: handleFieldChange,
            error: formError?.vehicleModel,
            maxLength: 50,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Vehicle Status',
            name: 'vehicleStatus',
            required: true,
            type: 'select',
            options: vehicleStatusConstant.filter(status => status.value === 'AVAILABLE_FOR_ASSIGNMENT' || status.value === 'STAND_BY'),
            value: formValues?.vehicleStatus || '',
            handleFieldChange: handleFieldChange,
            error: formError?.vehicleStatus,
            width: isMobile ? '17rem' : '12rem',
            isDisabled : formValues?.vehicleStatus?.value === 'ASSIGNED_TO_DRIVER'
        },
        {
            label: 'Fuel Type',
            name: 'fuelType',
            required: true,
            type: 'select',
            options: fuelTypeConstants,
            value: formValues?.fuelType || '',
            handleFieldChange: handleFieldChange,
            error: formError?.fuelType,
            width: isMobile ? '17rem' : '12rem'
        },
        formValues?.fuelType?.value && formValues?.fuelType?.value !== 'ELECTRIC' && {
            label: 'Fuel Capacity',
            name: 'fuelCapacity',
            required: true,
            type: 'text',
            value: formValues?.fuelCapacity || '',
            handleFieldChange: handleFieldChange,
            error: formError?.fuelCapacity,
            maxLength: 30,
            width: isMobile ? '17rem' : '12rem'
        },
        formValues?.fuelType?.value && formValues?.fuelType?.value !== 'ELECTRIC' && {
            label: 'Fuel Capacity Unit',
            name: 'fuelCapacityUnit',
            required: true,
            type: 'select',
            options: capacities,
            value: formValues?.fuelCapacityUnit || '',
            handleFieldChange: handleFieldChange,
            error: formError?.fuelCapacityUnit,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Max Capacity Volume',
            name: 'maxCapacityVolume',
            required: true,
            type: 'text',
            value: formValues?.maxCapacityVolume || '',
            handleFieldChange: handleFieldChange,
            error: formError?.maxCapacityVolume,
            maxLength: 30,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Volume Unit',
            name: 'volumeUnit',
            required: true,
            type: 'select',
            options: volumeUnit,
            value: formValues?.volumeUnit || '',
            handleFieldChange: handleFieldChange,
            error: formError?.volumeUnit,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Max Capacity Weight',
            name: 'maxCapacityWeight',
            required: true,
            type: 'text',
            value: formValues?.maxCapacityWeight || '',
            handleFieldChange: handleFieldChange,
            error: formError?.maxCapacityWeight,
            maxLength: 30,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Weight Unit',
            name: 'weightUnit',
            required: true,
            type: 'select',
            options: weightUnit,
            value: formValues?.weightUnit || '',
            handleFieldChange: handleFieldChange,
            error: formError?.weightUnit,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Material Type',
            name: 'materialType',
            required: true,
            type: 'select',
            options: materialTypes,
            value: formValues?.materialType || '',
            handleFieldChange: handleFieldChange,
            error: formError?.materialType,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Running Rate',
            name: 'runningRate',
            required: true,
            type: 'text',
            value: formValues?.runningRate || '',
            handleFieldChange: handleFieldChange,
            error: formError?.runningRate,
            maxLength: 30,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Running Rate Unit',
            name: 'runningRateUnit',
            required: true,
            type: 'select',
            options: runningRate,
            value: formValues?.runningRateUnit || '',
            handleFieldChange: handleFieldChange,
            error: formError?.runningRateUnit,
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'Insurance Valid Till',
            value: formValues?.insuranceValidTill,
            handleFieldChange: handleFieldChange,
            type: 'date',
            name: 'insuranceValidTill',
            placeholder: 'DD-MM-YYYY',
            error: formError?.insuranceValidTill,
            required: true,
            min: new Date()?.toISOString()?.split('T')?.[0],
            width: isMobile ? '17rem' : '12rem'
        },
        {
            label: 'OnBoarding Date',
            value: formValues?.onBoardingDate,
            handleFieldChange: handleFieldChange,
            type: 'date',
            name: 'onBoardingDate',
            placeholder: 'DD-MM-YYYY',
            error: formError?.onBoardingDate,
            required: true,
            min: new Date()?.toISOString()?.split('T')?.[0],
            max: formValues?.offBoardingDate,
            width: isMobile ? '17rem' : '12rem'
        },
        isEdit && {
            label: 'OffBoarding Date',
            value: formValues?.offBoardingDate,
            handleFieldChange: handleFieldChange,
            type: 'date',
            name: 'offBoardingDate',
            placeholder: 'DD-MM-YYYY',
            error: formError?.offBoardingDate,
            required: false,
            min: formValues?.onBoardingDate,
            width: isMobile ? '17rem' : '12rem'
        }],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleCancel,
                color: 'error'
            }
        ],
        loading: loading,
        margin: '0px',
        padding: '1rem',
        fieldAlignment: isMobile ? 'center' : 'unset'
    }

    return (
        <Layout headerTitle={isEdit ? 'Edit Vehicle' : 'Add Vehicle'}>
            <CustomForm {...vehicleFormProps} showFullHeight={true}/>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : ''}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}