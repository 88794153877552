import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ChartWrapper, getBarChartOptions } from './Common'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ data, title, link, icon }) => {
    const hasData = data && data?.datasets && data?.datasets?.length > 0;
    const options = getBarChartOptions(data)

    return <div>
        <ChartWrapper title={title} link={link} icon={icon} />
        {hasData ? (
            <Bar data={data} options={options} />
        ) : (
            <p>No data available</p>
        )}
    </div>;
};

export default BarChart;
