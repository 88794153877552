import { environment } from "../environments/environment.jsx";
import api from './api';
import { getHeader } from './authUtils.js'

const apiEndpoint = environment.webservices.endpoint;

export class BatchService {
    getBatches(queryParams) {
        const endpoint = `${apiEndpoint}/api/daily-routes/all/batch`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    getBatchPackageDetails(queryParams) {
        const endpoint = `${apiEndpoint}/api/daily-routes/batch/packages`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    createBatch(formData) {
        const { methodType, ...rest } = formData
        const endpoint = `${apiEndpoint}/api/daily-routes/batch/create`;
        if (methodType === 'put') {
            return api.put(endpoint, rest, {
                headers: getHeader()
            });
        }
        else {
            return api.post(endpoint, rest, {
                headers: getHeader()
            });

        }
    }

    movePackagesToBatch(formData) {
        const endpoint = `${apiEndpoint}/api/daily-routes/batch/move/${formData?.batchId}`;
        return api.put(endpoint, { trackingNumbers: formData?.trackingNumbers }, {
            headers: getHeader(),
        });
    }

    rejectBatch(formData) {
        const endpoint = `${apiEndpoint}/api/daily-routes/batch/reject`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    getFSAZonesForBatches(queryParams) {
        const endpoint = `${apiEndpoint}/api/fsa-zones/unbatched`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    generateRoute(formData) {
        const endpoint = `${apiEndpoint}/api/daily-routes/batch/generateroute`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    updateBatch(formData) {
        const { batchIds, status } = formData
        const endpoint = `${apiEndpoint}/api/daily-routes/status/${status}`;
        return api.put(endpoint, batchIds,
            {
                headers: getHeader(),
            });
    }

    destroyBatch(formData) {
        const endpoint = `${apiEndpoint}/api/daily-routes/batch/destroy`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    resequenceBatch(formData) {
        const endpoint = `${apiEndpoint}/api/daily-routes/retrysequencing`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

}