import React from 'react';

const ReturnProductIcon = (fill) => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 28.934" fill={fill}>
  <path id="return-product-icon" d="M21.974,1.746a.732.732,0,0,1,.471.089.72.72,0,0,1,.428.654L22.92,9.12H21.531l-.038-5.3L17.021,6.643v9.838a10.776,10.776,0,0,1-1.156.706l-.285-.428V6.74L9.746,6l-.214,6.243-2.314-1.58L4.9,11.975l.471-6.531-3.932-.5V18.325l9.3,1.033-.28,1.413L.685,19.69A.72.72,0,0,1,0,18.97V4.138a.725.725,0,0,1,.431-.673L8.19.042a.723.723,0,0,1,.384-.059ZM11.465,26.885l1.928-9.8,1.954,2.945c4.209-1.688,6.573-4.475,6.924-8.765,3.458,6.046,1.358,11.465-3.023,14.638l1.994,3ZM15.21,2.309l-4.238,2.4,5.137.654,3.866-2.434ZM6.516,4.143l4.11-2.437L8.588,1.44,3.354,3.75l3.162.4Z" transform="translate(0 0.022)" fillRule="evenodd"/>
</svg>

);

export default ReturnProductIcon;




