import React from "react";
import { ListComponent } from "component/common/List/List";
import Box from "@mui/material/Box";

const Fleets = () => {
  const data = [
    {
      subheader: "Fleet Availability",
      subList: [
        { key: "Active", value: 25 },
        { key: "Inactive", value: 30 },
        { key: "On-Call", value: 33 },
        { key: "Terminated", value: 11 },
        { key: "Quit", value: 5 },
        { key: "Vacation", value: 15 },
      ],
    },
    {
      subheader: "Driver Type",
      subList: [
        { key: "Employee", value: 24 },
        { key: "Owner Operator", value: 18 },
        { key: "Agency", value: 33 },
      ],
    },
  ];

  return (
      <Box display="flex" justifyContent="space-between" padding={0}>
        {data.map((category, index) => (
          <div key={index} style={{ width: "100%"}}>
            <ListComponent
              data={[category]}
              height="30px"
              flexDirection="column"
            />
          </div>
        ))}
    </Box>
  );
};

export default Fleets;
