import React, { useState } from 'react';
import styled from 'styled-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker as MUIDateTimePicker, TimePicker as MUITimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTimePicker as MUIDateTimePicker, TimePicker as MUITimePicker } from '@mui/x-date-pickers';
import { StyledLabel, ErrorMessage, FieldWrapper } from './SelectField'
import StarIcon from '@mui/icons-material/Star';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import TextField from '@mui/material/TextField';

const StyledDateTimePicker = styled(MUIDateTimePicker)`
.MuiInputBase-root {
    height: 40px;
  height: 40px;
  border-radius: 7px;
  color: ${props => props.color || '#000000'};
  cursor: pointer;
  font-size: 14px;
  font-family: 'Degular', sans-serif;

  &::placeholder {
    color: #999;
    font-size: 14px;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    cursor: pointer;
  }
  ${props => props.disabled && `
  background-color: hsl(0, 0%, 95%);
  color: hsl(0, 0%, 60%)
`}
}
`;

const StyledTimePicker = styled(MUITimePicker)`
  .MuiInputBase-root {
    height: 40px;
    border-radius: 7px;
    color: ${props => props.color || '#000000'};
    cursor: pointer;
    font-size: 14px;
    font-family: 'Degular', sans-serif;

    &::placeholder {
      color: #999;
      font-size: 14px;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      cursor: pointer;
    }

    ${props => props.disabled && `
      background-color: hsl(0, 0%, 95%);
      color: hsl(0, 0%, 60%);
    `}
  }
`;


const iconStyle = (color, fontSize, cursor) => {
  return { color: color, fontSize: fontSize, verticalAlign: 'middle', marginLeft: '5px', cursor: cursor }
}

export const DateTimePicker = ({ label, lableColor, error, handleFieldChange, info, placeholder, required, width, timeZone, showOnlyTime, ...rest }) => {
  const [open, setOpen] = useState(false);

  const disableDate = (event) => {
    if (event.target.type === 'date') {
      event.preventDefault(); // Prevent the default action of the event
      return false; // Return false to prevent typing
    }
  };

  return (
    <FieldWrapper width={width}>
      <StyledLabel lablecolor={lableColor} data-testid={`label-${rest?.name}`}>{label}
        {required && <StarIcon style={iconStyle('red', '8px', 'none')} />}
        {info && (
          <Tooltip title={info} placement="top">
            <InfoIcon style={iconStyle('blue', '16px', 'pointer')} />
          </Tooltip>
        )}
      </StyledLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {showOnlyTime ? (
          <StyledTimePicker
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            data-testid={`name-${rest?.name}`}
            width={width}
            onChange={handleFieldChange}
            placeholder={placeholder}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
            }}
            format="hh:mm a"
            timeZone={timeZone}
            disablePast
            sx={{
              height: open ? '200px' : 'auto',
            }}
          />
        ) : (
          <StyledDateTimePicker
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            data-testid={`name-${rest?.name}`}
            width={width}
            onChange={handleFieldChange}
            placeholder={placeholder}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
            }}
            format="DD/MM/YYYY hh:mm a"
            timeZone={timeZone}
            disablePast
            sx={{
              height: open ? '200px' : 'auto', // Set the height only when the calendar is open
            }}
            renderInput={(params) => (
              <TextField {...params} onKeyDown={disableDate} />
            )}
          />
        )}
      </LocalizationProvider>
      {error && <ErrorMessage width={width}>{error}</ErrorMessage>}
    </FieldWrapper>
  );
}