import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReportingServices } from "../../services/reportingServices.jsx";

const reportingServices = new ReportingServices();

export const getPackageSummary = createAsyncThunk(
    'reporting/getPackageSummary',
    async (formData) => {
        const response = await reportingServices.getPackageSummary(formData);
        return response.data;
    }
)
