import React from 'react';
import { Line } from 'react-chartjs-2'; // Import Line from react-chartjs-2
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, Legend, LineElement, PointElement, LineController } from 'chart.js'; // Import LineElement and PointElement for Line charts, and LineController to control line elements
import { ChartWrapper, getBarChartOptions } from './Common'; // Assuming you have a Common.js file for utility functions

ChartJS.register(CategoryScale, LinearScale, Title, Tooltip, Legend, LineElement, PointElement, LineController); // Register necessary elements for Line charts

const LineChart = ({ data, title, link, icon }) => {
    const options = getBarChartOptions(data); // Assuming you have a utility function to get Line chart options

    return (
        <div>
            <ChartWrapper title={title} link={link} icon={icon} />
            <Line data={data} options={options} />
        </div>
    );
};

export default LineChart;
