import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import {ChartWrapper,  getCombinedChartOptions } from './Common'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const CombineLineAndAreaChart = ({ data, title, link, icon }) => {
    const options = getCombinedChartOptions(data)

    return (<div>
        <ChartWrapper title={title} link={link} icon={icon} />
        <Line data={data} options={options} /></div>)
};

export default CombineLineAndAreaChart;
