import React, { useState } from 'react';
import './Input.scss';
import { useFormContext } from 'react-hook-form';
import Alert from '@mui/material/Alert'

export const SelectField = ({ name, label, options, placeholder, formErrors, ...rest }) => {
  const { register, control, formState: { errors } } = useFormContext();
  const [value, setValue] = useState(null)

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const error = formErrors ? formErrors.find(error => error.field === name) : null

  return (
    <div className='select-wrapper'>
      {label && <label>{label}</label>}
      <select
        className='selectField'
        name={name}
        onChange={handleChange} control={control}
        value={value}
        {...register(name, { required: true })}
        {...rest}
      >
        <option disabled selected value="">
          {placeholder}
        </option>

        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors?.[name] && <span><Alert severity="error">This field is required!</Alert></span>}
      {error && <span><Alert severity="error">{error?.message}</Alert></span>}
    </div>
  );
};

export default SelectField;
