import React from "react";
import { ListComponent } from "component/common/List/List";


const LiveTrackingTable = () => {
  const data = [
    {
      key: "Parcels On Road",
      value: "1210",
    },
    {
      key: "Vehicles In Transit",
      value: "23",
    },
    {
      key: "Successfully Delivered",
      value: "823",
    },
    {
      key: "Unsuccessful Delivery Attempt",
      value: "132",
    },
    {
      key: "Service Completion",
      value: "1210",
    },
    {
      key: "Routes at Risk",
      value: "23",
    },
    {
      key: "Missing",
      value: "14",
    },
    {
        key: "Failed Attempt Returns",
        value: "14",
    },
    {
        key: "Driver-reported Issues",
        value: "14",
    },
      
  ];
  return (
    <div>
      <ListComponent data={data} height={"40px"} />
    </div>
  );
};

export default LiveTrackingTable;
