import React from 'react';
import { Layout } from './common/Layout/Layout';

const PageNotFound = () => {
    return (
        <Layout>
            <h1>404 Page Not Found</h1>
            <p>The requested page does not exist.</p>
        </Layout>
    );
};

export default PageNotFound;
