import React from 'react';

function ErrorPage() {
  return (
    <div>
      <h1>Something went wrong</h1>
      <span className="reg-link">Please login again!   <a href='/login'>Login</a></span>
    </div>
  );
}

export default ErrorPage;
