import * as React from 'react';
import Popper from '@mui/material/Popper';
import FilterListIcon from '@mui/icons-material/FilterList';
import IndeterminateCheckbox from './IndeterminateCheckbox'
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from 'styled-components';

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 20px;
}`

export default function CustomFilter() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <FilterListIcon color="primary" onClick={handleClick} />
            <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 9999 }}>
                <Paper sx={{p: 1}}>
                    Filters
                    <IndeterminateCheckbox />
                    <ButtonContainer>
                        <Button variant='outlined' size='small'>Apply</Button>
                        <Button variant='outlined' size='small'>Clear</Button>
                    </ButtonContainer>
                </Paper>
            </Popper>
        </div>
    );
}