import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { SelectField } from 'component/common/Input/SelectField'
import { updateRoaster } from 'features/dailyRoaster/dailyRoasterAction'
import { getDriverList } from 'utils/common'
import { fetchDriversForRoaster } from 'features/driverManagement/driverManagementAction'

const formInitialState = {
    driver: '',
}

const DriverCellRenderer = ({ route }) => {
    const dispatch = useDispatch();
    const { driversForRoasters } = useSelector(
        (state) => state?.driverManagement
    )
    const [formValues, setFormValues] = useState(formInitialState);
    const routeType =  route?.routeWidgetVM?.routeName?.startsWith("RR") ? 'RescueRoute' : 'NormalRoute'


    const handleMenuOpen = () => {
        dispatch(fetchDriversForRoaster({
            routeId: route?.routeWidgetVM?.routeId,
            routeType: routeType
        }))
    };

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        const data = {}
        const driver = event.target?.value
        data['driverId'] = driver?.value || route?.routeWidgetVM?.driverId
        data['routeId'] = route?.routeWidgetVM?.routeId
        data['operation'] = driver?.value ? 'assign' : 'remove'
        data['routeType'] = routeType
        if (data?.driverId) {
            dispatch(updateRoaster(data))
        }
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (route?.routeWidgetVM?.driverId) {
            setFormValues({
                driver: { label: route?.routeWidgetVM?.driverName, value: route?.routeWidgetVM?.driverId },
            });
        }
        else {
            setFormValues({
                driver: '',
            });
        }
    }, [route?.routeWidgetVM?.driverId])

    return (
        <SelectField name='driver' key={route?.routeWidgetVM?.routeId} options={getDriverList(driversForRoasters)} width={'12rem'}
            value={formValues?.driver} handleFieldChange={handleFieldChange} isClearable={true} onFocus={handleMenuOpen} menuPosition={'fixed'} menuPlacement={'bottom'}/>
    );
};

export default DriverCellRenderer
