import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class RouteOperationService{
    getAllRoutes(formData){
        const endpoint = `${apiEndpoint}/api/daily-routes`; 
        return api.get(endpoint, {
            headers: getHeader(),
            params: formData
        });
    }
    
    updateRouteStatus(formData) {
        const endpoint = `${apiEndpoint}/api/daily-routes/${formData?.id}/status/${formData?.status}`;
        return api.put(endpoint, null, {
            headers: getHeader(),
        });
    }

    updatePackageStatus(formData) {
        const endpoint = `${apiEndpoint}/api/package-details/scan/${formData?.id}/${formData?.status}`;
        return api.put(endpoint, null, {
            headers: getHeader(),
        });
    }

    updateRouteStopStatus(formData) {
        const endpoint = `${apiEndpoint}/api/route-stops/${formData?.id}/status/${formData?.status}`;
        return api.put(endpoint, null, {
            headers: getHeader(),
        });
    }

}