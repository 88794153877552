export const getPackagesSummaryData = (data) => {
    if (data?.length) {
        const dataCopy = [...data];
        const sortedData = dataCopy?.sort((a, b) => new Date(a?.createDate) - new Date(b?.createDate));
        const labels = sortedData?.map(entry => entry?.createDate);
        const attemptedData = sortedData?.map(entry => entry?.attempted);
        const deliveredData = sortedData?.map(entry => entry?.delivered);

        return {
            labels,
            datasets: [
                {
                    label: 'Attempted',
                    data: attemptedData,
                    backgroundColor: 'rgb(96, 165, 250)',
                    borderColor: 'rgb(96, 165, 250)',
                    borderWidth: 1,
                    barPercentage: 0.3
                },
                {
                    label: 'Delivered',
                    data: deliveredData,
                    backgroundColor: 'rgb(99, 102, 241)',
                    borderColor: 'rgb(99, 102, 241)',
                    borderWidth: 1,
                    barPercentage: 0.3
                }
            ]
        };
    }
}


export const getLeadTimeData = (data) => {
    const labels = ['Same Day', 'Second Day', 'Third Day', 'Not Received'];
    return {
        labels,
        datasets: [
            {
                data: [data?.sameDay, data?.secondDay, data?.thirdDay, data?.notReceived],
                backgroundColor: [
                    'rgb(99, 102, 241)',
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                ],
                borderColor: [
                    'rgb(99, 102, 241)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(75, 192, 192, 1)',

                ],
                borderWidth: 1,
            },
        ]
    };
}

export const getAttemptLeadTimeData = (data) => {
    const labels = data?.map(entry => entry?.createDate);
    const firstAttempt = data?.map(entry => entry?.firstAttempt);
    const secondAttempt = data?.map(entry => entry?.secondAttempt);
    const thirdAttempt = data?.map(entry => entry?.thirdAttempt);

    return {
        labels,
        datasets: [
            {
                label: 'First Attempt Rate',
                data: firstAttempt,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
            },
            {
                label: 'Second Attempt Rate',
                data: secondAttempt,
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgb(54, 162, 235)',
                borderWidth: 2,
            },
            {
                label: 'Third Attempt Rate',
                data: thirdAttempt,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 2,
            }
        ]
    };
}

export const packageSummaryTableDataColumns = (data) => {
    if (data) {
        return ['summary', ...data?.map(item => item?.province)];
    }
    return []

}
export const packageSummaryTableData = (data) => {
    if (data) {
        return [
            {
                summary: 'Package Summary',
                ...data.reduce((acc, item) => ({ ...acc, [item?.province]: '' }), {})
            },
            {
                summary: 'Attempted',
                ...data.reduce((acc, item) => ({ ...acc, [item?.province]: item?.attempted }), {})
            },
            {
                summary: 'Delivered',
                ...data.reduce((acc, item) => ({ ...acc, [item?.province]: item?.delivered }), {})
            }
        ]
    }
    return []
}


export const getClienTableSummary = (response) => {
    // Extract province names from the response
    const getProvinces = (data) => {
        const provinces = new Set();
        data.forEach(item => {
            if (item.province) {
                provinces.add(item.province);
            }
        });
        return Array.from(provinces).sort(); // Convert Set to Array and sort if needed
    };

    // Get provinces
    const provinces = getProvinces(response.reportSummaryVM || []);
    const columns = ['Summary', ...provinces];

    // Initialize empty arrays for each section
    const packageSummary = [];
    const dsvLeadTime = [];
    const attemptLeadTime = [];
    const deliveryLeadTime = [];

    // Process `reportSummaryVM` for `packageSummary`
    const reportSummaryVM = response.reportSummaryVM || [];
    packageSummary.push(
        {
            Summary: 'Attempted',
            ...reportSummaryVM.reduce((acc, item) => ({ ...acc, [item.province]: item.attempted }), {})
        },
        {
            Summary: 'Delivered',
            ...reportSummaryVM.reduce((acc, item) => ({ ...acc, [item.province]: item.delivered }), {})
        }
    );

    // Process `leadTimeVM` for `dsvLeadTime`
    const leadTimeVM = response.leadTimeVM || [];
    dsvLeadTime.push(
        {
            Summary: 'Shipments Received Same Day as Manifest',
            ...leadTimeVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: `${item.sameDay?.toFixed(2)} %`
            }), {})
        },
        {
            Summary: 'Shipments Received Second Day',
            ...leadTimeVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: `${item.secondDay?.toFixed(2)} %`
            }), {})
        },
        {
            Summary: 'Shipments Received Third Day',
            ...leadTimeVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: `${item.thirdDay?.toFixed(2)} %`
            }), {})
        },
        {
            Summary: 'Not Received',
            ...leadTimeVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: `${item.notReceived?.toFixed(2)} %`
            }), {})
        }
    );

    // Process `reportSummaryVM` for `attemptLeadTime`
    attemptLeadTime.push(
        {
            Summary: 'First Attempt',
            ...reportSummaryVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: `${item.firstAttempt?.toFixed(2)} %`
            }), {})
        },
        {
            Summary: 'Second Attempt',
            ...reportSummaryVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: `${item.secondAttempt?.toFixed(2)} %`
            }), {})
        },
        {
            Summary: 'Third Attempt',
            ...reportSummaryVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: `${item.thirdAttempt?.toFixed(2)} %`
            }), {})
        }
    );

    // Process `reportSummaryVM` for `deliveryLeadTime`
    deliveryLeadTime.push(
        {
            Summary: 'First Day Delivery Lead Time',
            ...reportSummaryVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: item.firstAttemptDeliveryCount // Replace with the correct field if different
            }), {})
        },
        {
            Summary: 'Seconds Day Delivery Lead Time',
            ...reportSummaryVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: item.secondAttemptDeliveryCount // Replace with the correct field if different
            }), {})
        },
        {
            Summary: 'Third Day Delivery Lead Time',
            ...reportSummaryVM.reduce((acc, item) => ({
                ...acc,
                [item.province]: item.thirdAttemptDeliveryCount // Replace with the correct field if different
            }), {})
        }
    );

    // Return the transformed data
    return {
        columns,
        packageSummary,
        dsvLeadTime,
        attemptLeadTime,
        deliveryLeadTime
    };
};
