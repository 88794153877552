import React from 'react';

const DriverDashboardIcon = (fill) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 23.775" fill={fill}>
    <path id="categories-icon" d="M.977,0H9.648a.984.984,0,0,1,.979.977V9.609a.977.977,0,0,1-.977.977H.977A.977.977,0,0,1,.283,10.3l-.018-.021A.977.977,0,0,1,0,9.609V.977A.977.977,0,0,1,.977,0ZM14.356,13.192h8.667a.986.986,0,0,1,.977.977V22.8a.977.977,0,0,1-.977.977H14.356a.977.977,0,0,1-.682-.279l-.018-.021a.965.965,0,0,1-.27-.672V14.172a.977.977,0,0,1,.287-.693l.02-.02a.977.977,0,0,1,.672-.27Zm-13.379,0H9.648a.977.977,0,0,1,.69.287.965.965,0,0,1,.289.692V22.8a.977.977,0,0,1-.977.977H.977a.977.977,0,0,1-.693-.287l-.018-.021A.977.977,0,0,1,0,22.8V14.172A.977.977,0,0,1,.977,13.2ZM14.356,0h8.667A.977.977,0,0,1,24,.977V9.609a.977.977,0,0,1-.977.977H14.356a.977.977,0,0,1-.977-.977V.977a.977.977,0,0,1,.295-.69l.02-.018A.977.977,0,0,1,14.356,0Z" />
  </svg>

);

export default DriverDashboardIcon;







