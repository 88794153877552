
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BatchService } from "../../services/batchService";

const batchService = new BatchService();

export const fetchBatches = createAsyncThunk(
    'batch/getBatches',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await batchService.getBatches(formData)
            return { batches: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchBatchPackageDetails = createAsyncThunk(
    'batch/getBatchPackageDetails',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await batchService.getBatchPackageDetails(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const createBatch = createAsyncThunk(
    'batch/createBatch',
    async (formData, { rejectWithValue }) => {
        try {
            const {isBatchMapView, ...rest} = formData
            const response = await batchService.createBatch(rest)
            return { data: response?.data, methodType: rest?.methodType, isBatchMapView : isBatchMapView }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const movePackagesToBatch = createAsyncThunk(
    'batch/movePackagesToBatch',
    async (formData, { rejectWithValue }) => {
        try {
            const {isBatchMapView, ...rest} = formData
            const response = await batchService.movePackagesToBatch(rest)
            return { data: response?.data, isBatchMapView : isBatchMapView }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)


export const fetchFSAZonesForBatches = createAsyncThunk(
    'batch/fetchFSAZonesForBatches',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await batchService.getFSAZonesForBatches(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const generateRoute = createAsyncThunk(
    'batch/generateRoute',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await batchService.generateRoute(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const updateBatch = createAsyncThunk(
    'batch/updateBatch',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await batchService.updateBatch(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const destroyBatch = createAsyncThunk(
    'batch/destroyBatch',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await batchService.destroyBatch(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const resequenceBatch = createAsyncThunk(
    'packages/resequenceBatch',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await batchService.resequenceBatch(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)
