import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class RouteService{
    getRouteById(id){
        const endpoint = `${apiEndpoint}/api/daily-routes/${id}`; 
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getRouteHistoricData(id){
        const endpoint = `${apiEndpoint}/api/package-details/historicparcel/${id}`; 
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getCurrentLocation(id){
        const endpoint = `${apiEndpoint}/api/driveEventsRepo/${id}`; 
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getAllRoutes(queryParams){
        const {fetchRouteDetail, ...rest} = queryParams
        const endpoint = fetchRouteDetail ? `${apiEndpoint}/api/daily-routes/${rest?.routeId}` : `${apiEndpoint}/api/daily-routes`; 
        return api.get(endpoint, {
            headers: getHeader(),
            params: rest
        });
    }

    getRouteSummary(queryParams){
        const endpoint = `${apiEndpoint}/api/daily-routes/summary`; 
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }
    
    getReturnTerminalData(formData){
        const endpoint = `${apiEndpoint}/api/daily-routes/returnterminal`
        return api.get(endpoint, {
            headers: getHeader(),
            params: formData
        });
    }
    
    createBatch(formData){
        const endpoint = `${apiEndpoint}/api/create-batch`; 
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    movePackagesToBatch(formData){
        const endpoint = `${apiEndpoint}/api/move-batch`; 
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    getLiveTrackingRoutes(queryParams){
        // To do - Fix this with virtual list
        const endpoint = `${apiEndpoint}/api/daily-routes?page=0&size=500`; 
        // const endpoint = `${apiEndpoint}/api/daily-routes/livetracking?routeStatus=ROUTE_STARTED,ROUTE_COMPLETE`; 
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }
    
    rejectRoute(routeId) {
        const endpoint = `${apiEndpoint}/api/daily-routes/reject/${routeId}`;
        return api.put(endpoint, null, {
            headers: getHeader(),
        });
    }

    removeRoute(routeId) {
        const endpoint = `${apiEndpoint}/api/daily-routes/remove-shorts`;
        return api.put(endpoint, routeId, {
            headers: getHeader(),
        });
    }

    getLiveTrackingSummary(formData){
        const endpoint = `${apiEndpoint}/api/daily-routes/livestats`; 
        return api.get(endpoint, {
            headers: getHeader(),
            params: formData
        });
    }

    rescueRoute(formData) {
        const endpoint = `${apiEndpoint}/api/daily-routes/createRescueRoute`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    sendSOSAcceptance(payload) {
        const endpoint = `${apiEndpoint}/api/notification/sendSOSNotification/${payload?.routeId}?operation=SOS_Response&isRequestAccept=${payload?.action}`;
        return api.post(endpoint, null, {
            headers: getHeader(),
        });
    }


    rescheduleRoute(formData) {
        const {routeId, zoneDateTime, tabName, sla=false} = formData
        const endpoint = `${apiEndpoint}/api/daily-routes/rescheduleRoute/${routeId}/${zoneDateTime}?tabName=${tabName}&isReasonSLA=${sla}`;
        return api.put(endpoint, null, {
            headers: getHeader(),
        });
    }

    markedMissingTotes(formData) {
        const endpoint = `${apiEndpoint}/api/totes/missingtotes`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    getRouteStopDetails(id){
        const endpoint = `${apiEndpoint}/api/route-stops/stop-summary/${id}`; 
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    routeTerminate(formData) {
        const {routeId, status, isSLA} = formData
        const endpoint = `${apiEndpoint}/api/daily-routes/terminate-route/${routeId}?isSLA=${isSLA}`;
        return api.put(endpoint, status, {
            headers: getHeader(),
        });
    }

    splitRoute(formData){
        const endpoint = `${apiEndpoint}/api/daily-routes/split-route`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        })
    }
    
    breakDownRoute(formData){
        const endpoint = `${apiEndpoint}/api/daily-routes/${formData?.routeId}/status/${formData?.status}`;
        return api.put(endpoint, null, {
            headers: getHeader(),
        })
    }
    
}