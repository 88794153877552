import React from 'react';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import './List.scss'

export const ListComponent = ({ data, showDivider = false, height, flexDirection = "column", fieldsPerRow = 3 }) => {
    const defaultHeight = height || '40px';

    const renderList = (listData) => {
        return flexDirection === "row" ? (
            listData.reduce((rows, item, index) => {
                const rowIndex = Math.floor(index / fieldsPerRow);
                rows[rowIndex] = rows[rowIndex] || [];
                rows[rowIndex].push(
                    <ListItem className='listComponent'
                        key={item?.key}
                        secondaryAction={item?.value ? item?.value : '0'}
                        sx={{ height: defaultHeight, width: `${100 / fieldsPerRow}%` }}
                    >
                        <ListItemText 
                            primary={item?.subheader ? <Typography component={'h6'}>{item.subheader} </Typography> : item?.key}
                        />
                    </ListItem>
                );

                return rows;
            }, []).map((row, index) => (
                <List disablePadding sx={{ display: 'flex', flexDirection }} key={index}>
                    {row}
                </List>
            ))
        ) : (
            <List disablePadding sx={{ display: 'flex', flexDirection }}>
                {listData.map((item, index) => (
                    <React.Fragment key={index}>
                        <ListItem className='listComponent'
                            key={item?.key}
                            secondaryAction={item?.value ? item?.value : '0'}
                            sx={{ height: defaultHeight }}
                        >
                            <ListItemText 
                                primary={item?.subheader ? <Typography variant="h6" sx={{lineHeight: '1.2rem', marginBottom: '1rem'}}>{item.subheader} </Typography> : item?.key}
                            />
                        </ListItem>
                        <div>
                            {item?.subList && renderList(item.subList)}
                            {showDivider && <Divider component="li" />}
                        </div>
                    </React.Fragment>
                ))}
            </List>
        );
    };

    return (
        data && data?.length ? renderList(data) :
            <Typography variant='h5' color={'secondary'}>Data not available!</Typography>
    );
};
