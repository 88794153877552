import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserManagementService } from 'services/userManagementService'
const userManagementService = new UserManagementService();

export const fetchUsers = createAsyncThunk(
    'userManagement/fetchUsers',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await userManagementService.getUsers(formData)
            return {users: response?.data, totalCount: response.headers.get('X-Total-Count')}
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addUser = createAsyncThunk(
    'userManagement/addUser',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await userManagementService.addUser(formData)
            return response?.data
        } catch (error) {
            if (error.response && error.response.data?.fieldErrors) {
                const fieldErrorMessage = error.response.data.fieldErrors[0]?.message;
                return rejectWithValue(fieldErrorMessage);
            } else {
                return rejectWithValue(error?.response?.data?.title);
            }   
        }
    }
)

export const fetchUserDetails = createAsyncThunk(
    'userManagement/fetchUserDetails',
    async (userName, { rejectWithValue }) => {
        try {
            const response = await userManagementService.getUserDetails(userName)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const updateUser = createAsyncThunk(
    'userManagement/updateUser',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await userManagementService.updateUser(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchDataCenters = createAsyncThunk(
    'userManagement/fetchDataCenters',
    async (_, { rejectWithValue }) => {
        try {
            const response = await userManagementService.getDataCenters()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const fetchTenants = createAsyncThunk(
    'userManagement/fetchTenants',
    async (_, { rejectWithValue }) => {
        try {
            const response = await userManagementService.getTenants()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updateProfile = createAsyncThunk(
    'userManagement/updateProfile',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await userManagementService.updateProfile(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchProfile = createAsyncThunk(
    'userManagement/fetchProfile',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await userManagementService.getProfileDetails(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)