import React from "react";
import ConveyorRow from "./ConveyorRow";
import styled from 'styled-components';

const ShelfRow = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        !['flexDirection'].includes(prop),
})`
display: flex;
justify-content: space-between;
overflow-x : scroll;
flex-direction: ${(props) => props?.flexDirection || 'row'};
`;

const Shelf = ({ row }) => {
    return <ShelfRow flexDirection={row?.flexDirection}> <ConveyorRow
        row={row}
    /></ShelfRow>;
}

export default Shelf;
