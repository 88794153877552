import React from 'react';
import styled from 'styled-components';
import { Button, Typography, Box } from '@mui/material';
import { ImageUpload } from 'component/common/ImageUpload/ImageUpload'
import { FieldRenderer } from './FieldRenderer'
import './CustomFormWithBox.scss'
import { FormWrapper, FieldContainer } from './CustomForm';

const BoxContainer = styled(Box)`
border: 1px solid  #D5D5D5;
`

const Title = styled(Typography)`
    font-family: "degular", sans-serif;
    letter-spacing: 0px;
    color: $black-color;
    opacity: 1;
    font-size:16px !important;
    font-weight: 700 !important;
    display: flex;
    align-items: center;
    gap: 0.2rem;
`

export const CustomFormWithBox = ({ fieldList, buttons, showImageUploader, handleImageUpload, image, margin, borderRadius, padding, backgroundColor, lableColor, isMobile, fieldGap }) => {
    return (
        <>
            <FormWrapper className='form-container-box' margin={margin} padding={padding} backgroundcolor={backgroundColor} borderradius={borderRadius}>
                <div className='form-wrapper'>
                    {showImageUploader ? <div className='image-container'>
                        <ImageUpload image={image} handleImageUpload={handleImageUpload} />
                    </div>
                        : null}
                    {fieldList?.map((list) => {
                        return (
                            <BoxContainer key={list?.title} component="fieldset">
                                <legend><Title>{list?.title}</Title></legend>
                                <FieldContainer fieldGap={fieldGap}>
                                    {list?.fields?.map((field, index) => {
                                        return (<FieldRenderer key={field?.name} field={field} lableColor={lableColor} isMobile={isMobile} />)
                                    })}

                                </FieldContainer>
                            </BoxContainer>)
                    })}
                    {buttons?.length ?
                        <div className='button-container'>
                            {buttons?.map((button) => {
                                return (
                                    <Button key={button?.label} onClick={button?.onClick} variant={button?.variant || 'outlined'}
                                        sx={{ color: button?.color, backgroundColor: button?.backgroundColor }}>{button?.label}</Button>
                                )
                            })}
                        </div> : null}
                </div>
            </FormWrapper>
        </>)
}