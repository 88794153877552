import { createSlice } from '@reduxjs/toolkit';
import { fetchVehicles, fetchVehiclesWithoutDrivers, fetchVehicleDetails, addVehicle, updateVehicle, deleteVehicle } from './vehicleAction'
import { logout } from 'features/auth/authAction'

const initialState =  {
    vehicles: [],
    loading: false,
    error: null,
    vehiclesWithoutDrivers: [],
    vehicleCount: 0,
    vehicleDetails: null,
    success: false,
    deleteSuccess: false
}

// Redux slice for vehicles
const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        resetVehicleData: (state) => {
            state.success = false;
            state.vehicleDetails = null;
            state.error = null
            state.deleteSuccess = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVehicles.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchVehicles.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.vehicles = action?.payload?.vehicles
                state.vehicleCount = action?.payload?.totalCount
            })
            .addCase(fetchVehicles.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Vehicle Details
            .addCase(fetchVehicleDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchVehicleDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.vehicleDetails = action?.payload
            })
            .addCase(fetchVehicleDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })
            //Vehicles without drivers
            .addCase(fetchVehiclesWithoutDrivers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchVehiclesWithoutDrivers.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.vehiclesWithoutDrivers = action?.payload
            })
            .addCase(fetchVehiclesWithoutDrivers.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // add vehicle Slice
            .addCase(addVehicle.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addVehicle.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(addVehicle.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // update vehicle Slice
            .addCase(updateVehicle.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateVehicle.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(updateVehicle.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Delete vehicle
            .addCase(deleteVehicle.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteVehicle.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.deleteSuccess = true
            })
            .addCase(deleteVehicle.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })
    }
});

export const { resetVehicleData } = vehicleSlice?.actions;
export default vehicleSlice.reducer;
