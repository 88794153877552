import React from "react";
import { useSelector } from 'react-redux';
import { ListComponent } from "component/common/List/List";


const MetricTable = () => {
  const { isMobile } = useSelector(
    (state) => state?.viewport
  )
  const data = [
    {
      key: "Parcels on Road",
      value: "1210",
    },
    {
      key: "Total Vehicles",
      value: "10",
    },
    {
      key: "Not Attempted",
      value: "823",
    },
    {
      key: "In Transit",
      value: "7",
    },
    {
      key: "Missing",
      value: "8",
    },
    {
      key: "Successful Attempt",
      value: "70%",
    },
    {
      key: "Not Departed",
      value: "7",
    },
    {
      key: "Damaged",
      value: "5",
    },
    {
      key: "Unsuccessful Attempt",
      value: "5%",
    },
    {
      key: "Route Completed",
      value: "7",
    },
    {
      key: "Returned to Terminal",
      value: "15%",
    },
    {
      key: "At Risk",
      value: "03"
    }
  ];
  return (
    <div>
      <ListComponent data={data} height={"30px"} flexDirection={isMobile ? "column" : "row"} fieldsPerRow={3} />
    </div>
  );
};

export default MetricTable;


