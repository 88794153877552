import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class WarehouseOperationService {
    getRollerCages() {
        const endpoint = `${apiEndpoint}/api/vehicles`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getTotesData(queryparams) {
        const endpoint = `${apiEndpoint}/api/totes`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryparams
        });
    }

    addTote(formData) {
        const endpoint = `${apiEndpoint}/api/totes`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });

    }

    updateTote(formData) {
        const {id, ...rest}  = formData
        const endpoint = `${apiEndpoint}/api/totes/${id}`;
        return api.put(endpoint, rest, {
            headers: getHeader(),
        });

    }

    getWarehouseData() {
        const endpoint = `${apiEndpoint}/api/totes/getlocationmap`;
        return api.get(endpoint, {
            headers: getHeader(),
        });

    }
    
}