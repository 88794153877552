import React, { useState } from 'react';
import './ImageUpload.scss'

export const ImageUpload = ({ handleImageUpload, image }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [dragOver, setDragOver] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        const file = e.dataTransfer.files[0];
        setSelectedImage(file);
        handleImageUpload(file)
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
        handleImageUpload(file)
    };

    return (
        <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`image-upload-container ${dragOver ? 'drag-over' : ''}`}
        >
            <div className="image-preview-container">
                {selectedImage || image ? <img className="preview-image"
                    src={selectedImage ? URL.createObjectURL(selectedImage) : image}
                    alt='profile-pic'
                /> : null}
            </div>
            <span>Drag and drop or choose a file</span>
            <input className="placeholder" type="file" onChange={handleImageChange} />
        </div>
    );
}