export const materialTypeEnum = {
    FOOD: 'FOOD',
    COLD: 'COLD',
    BEVERAGES: 'BEVERAGES',
    HAZARDOUS: 'HAZARDOUS',
    ALL_BUT_HAZARDOUS: 'ALL_BUT_HAZARDOUS',
    ALL_BUT_COLD: 'ALL_BUT_COLD',
}

export const materialTypes = [
    {
        label: 'Food',
        value: materialTypeEnum?.FOOD
    },
    {
        label: 'Cold',
        value: materialTypeEnum?.COLD
    },
    {
        label: 'Beverages',
        value: materialTypeEnum?.BEVERAGES
    },
    {
        label: 'Hazardous',
        value: materialTypeEnum?.HAZARDOUS
    },
    {
        label: 'All But Hazardous',
        value: materialTypeEnum?.ALL_BUT_HAZARDOUS
    },
    {
        label: 'All But Cold',
        value: materialTypeEnum?.ALL_BUT_COLD
    },
]