import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class FSAZoneService {
    // FSA Zone APIs
    getFSAZones(queryParams) {
        const endpoint = `${apiEndpoint}/api/fsa-zones`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    addFSAZone(formData) {
        const endpoint = `${apiEndpoint}/api/fsa-zones`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    updateFSAZone(formData) {
        const endpoint = `${apiEndpoint}/api/fsa-zones`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    // fsa sub zones
    getFSASubZones(queryParams) {
        const endpoint = `${apiEndpoint}/api/fsa-sub-zones`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    updateFSASubZonePriority(formData) {
        const endpoint = `${apiEndpoint}/api/fsa-sub-zones`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    addFSASubZone(formData) {
        const endpoint = `${apiEndpoint}/api/fsa-sub-zones`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    updateFSASubZone(formData) {
        const endpoint = `${apiEndpoint}/api/fsa-sub-zones`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    
}