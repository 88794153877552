import { createSlice } from '@reduxjs/toolkit';

const viewportSlice = createSlice({
  name: 'viewport',
  initialState: {
    isMobile: false,
    isIpad: false
  },
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setIsIpad: (state, action) => {
      state.isIpad = action.payload;
    },
  },
});

export const { setIsMobile, setIsIpad } = viewportSlice.actions;
export default viewportSlice.reducer;
