import { createAsyncThunk } from '@reduxjs/toolkit'
import { ManifestService } from 'services/manifestServices'
const manifestService = new ManifestService();

export const fetchManifests = createAsyncThunk(
    'manifestManagement/fetchManifests',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await manifestService.getAllManifest(formData)
            return { manifests: response?.data, totalCount: response.headers.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const addManifest = createAsyncThunk(
    'manifestManagement/addManifest',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await manifestService.uploadManifest(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)

export const fetchBusinessDetails = createAsyncThunk(
    'manifestManagement/fetchBusinessDetails',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await manifestService.getBusinessDetails(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data?.detail);
        }
    }
)

export const updateManifestOperationDate = createAsyncThunk(
    'manifestManagement/updateManifestOperationDate',
    async (formData, {rejectWithValue}) => {
        try {
            const response = await manifestService.updateOperationDate(formData)
            return response?.data
        }catch(error){
            return rejectWithValue(error?.response?.data?.detail)
        }
    }
 )
             
export const markOnHoldManifest = createAsyncThunk(
    'manifestManagement/markOnHoldManifest',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await manifestService.markManifestOnHold(formData);
            return response?.data
        }
        catch (error) {
            return rejectWithValue(error?.response?.data?.title);
        }
    }
)