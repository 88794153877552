import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import './AuthLayout.scss'

export const AuthLayout = ({ children }) => {
  return (
    <div className='auth-container'>
      <CssBaseline />
      <Container className='containerLogin'>
				<Grid container spacing={0}>
					<Grid item xs={6} className='loginBanner login-lr-module'>
						<div className='logo-login'>
							<img src='static/images/logo-w.svg' alt="Koorier logo" />
						</div>
					</Grid>
          {children}
				</Grid>
			</Container>
    </div>
  );
};  