import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { addTote, updateTote } from 'features/warehouseOperation/warehouseOperationAction'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { resetData } from 'features/warehouseOperation/warehouseOperationSlice'
import { Loader } from 'component/common/Loader/Loader'
import { CustomForm } from 'component/common/Form/CustomForm'
import { capacities } from 'constants/capacityConstant'
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';
import 'component/dailyRoaster/AddDuty.scss'

const formInitialState = {
    toteCapacity: '',
    toteCapacityUnit: '',
}

export const AddTote = ({ isModelOpen, handleClose, selectedTote }) => {
    const dispatch = useDispatch();
    const isEdit = selectedTote ? true : false
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [modalProps, setModalProps] = useState(null)

    const { loading, addSuccess } = useSelector(
        (state) => state?.warehouseOperation
    )

    useEffect(() => {
        return () => {
            // This code will run when the component unmounts
            dispatch(resetData())
        };
    }, [])

    useEffect(() => {
        if (selectedTote) {
            const capacity = selectedTote?.toteCapacityUnit ? capacities.find(capacity=> capacity.value === selectedTote?.toteCapacityUnit) : null
            
            handleFieldChange({
                target: {
                    name: 'toteCapacity',
                    value: selectedTote?.toteCapacity,
                }
            });
            handleFieldChange({
                target: {
                    name: 'toteCapacityUnit',
                    value: capacity,
                }
            });
            // setFormValues({
            //     toteCapacity: selectedTote?.toteCapacity,
            //     toteCapacityUnit: capacity
            // })
        }
    }, [selectedTote])

    useEffect(() => {
        if (addSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: isEdit ? 'Tote updated successfully' :  'Tote created successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                handleClose()
                dispatch(resetData())
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [addSuccess]);

    const handleFormSubmit = () => {
        const errors = validateForm(formValues);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const data = {}
            data['toteCapacity'] = formValues?.toteCapacity
            data['toteCapacityUnit'] = formValues?.toteCapacityUnit?.value
            if (isEdit) {
                data['id'] = selectedTote?.id
            }
            isEdit ? dispatch(updateTote(data)) : dispatch(addTote(data))
        }
    }

    const handleCancel = () => {
        setFormValues(formInitialState)
        dispatch(resetData())
        handleClose()
    }

    const formProps = {
        fields: [
            {
                label: 'Capacity',
                name: 'toteCapacity',
                required: true,
                type: 'text',
                value: formValues?.toteCapacity || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.toteCapacity,
            },
            {
                label: 'Capacity Unit',
                name: 'toteCapacityUnit',
                required: true,
                type: 'select',
                options: capacities,
                value: formValues?.toteCapacityUnit || '',
                handleFieldChange: handleFieldChange,
                error: formError?.toteCapacityUnit,
                width: '19rem',
            }],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem'
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleCancel}
            >
                <div className='add-duty-modal'>
                    <span className='title'>{isEdit ? 'Edit Tote' : 'Add Tote'}</span>
                    <CustomForm {...formProps} />
                    {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
                    {loading ? <Loader isLoading={true} /> : null}
                </div>
            </CustomModal>
        </>

    )

}