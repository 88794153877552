import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  border: 1px solid #ccc;
  padding: 1rem;
  background-color: white;
  border-radius: 20px;
`;

const Title = styled.span`
font-size: 20px;
color: #5D48FF;
font-family:  'Degular', sans-serif;
text-transform: uppercase;
font-weight: 700;
`

const GraphContainer = ({ title, icon, link, children }) => {
  return (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {title ? <Title>{title}</Title> : null}
        {link ? (
          <a href={link} style={{ textDecoration: 'none', color: '#5D48FF' }}>
            {icon}
          </a>
        ) : (
          icon
        )}
      </div>
      {children}
    </Container>
  );
};

export default GraphContainer;
