import React, { useState } from 'react';
import { Popover, RadioGroup, FormControlLabel, Radio, FormControl } from '@mui/material';
import { PrimaryButton } from 'component/common/Button/Button'
import './createBatchModal.scss'

const radioOptions = {
    ACCEPT_BATCH: [
        { value: 'reject', label: 'Reject' },
        { value: 'finalise', label: 'Finalize' },
        { value: 'merge', label: 'Merge' },
        { value: 'move', label: 'Move' },
    ],
    REJECT_BATCH: [
        { value: 'accept', label: 'Accept' },
        { value: 'destroy', label: 'Destroy' },
    ],
    FINALISE_BATCH: [
        { value: 'reject', label: 'Reject' },
        { value: 'route', label: 'Route a batch' },
    ],
    REVERTED_ROUTE: [
        { value: 'accept', label: 'Accept' },
        { value: 'reject', label: 'Reject' },
        { value: 'create-route', label: 'Route a batch' }
    ],
    INPROGRESS_BATCH: [
        { value: 'resequence', label: 'Re Sequence' },
    ]
};

export const UpdateBatchStatus = ({ selectedTab, disabled, handleDestroyBatch, handleCreateRoute, handleFinaliseBatchClick, handleAcceptBatchClick, handleRejectBatch, handleGenerateRoute, handleRequenceBatchClick, handleBatchCreate, selectedBatchIds }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleApplyClick = () => {
        if(selectedValue === 'reject'){
            handleRejectBatch()
        }
        if(selectedValue === 'accept'){
            handleAcceptBatchClick()
        }
        if(selectedValue === 'route'){
            handleGenerateRoute()
        }
        if(selectedValue === 'destroy'){
            handleDestroyBatch()
        }
        if(selectedValue === 'finalise'){
            handleFinaliseBatchClick()
        }
        if(selectedValue === 'create-route'){
            handleCreateRoute()
        }
        if(selectedValue === 'resequence'){
            handleRequenceBatchClick()
        }
        if(selectedValue === 'merge'){
            handleBatchCreate('merge')
        }
        if(selectedValue === 'move'){
            handleBatchCreate('move')
        }
        setSelectedValue('')
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <PrimaryButton height={'30px'} padding={null} disabled={disabled} label={'Update'} onClick={handleClick} />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                dataTestId='batchStatusUpdatePopover'
            >
                <FormControl dataTestId='statusRadioButton' component="fieldset" sx={{ p: 2 }}>
                    <RadioGroup value={selectedValue} onChange={handleRadioChange}>
                        {radioOptions[selectedTab]?.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                                dataTestId={`radio-${option.value}`}
                                className="checkbox-label"
                                disabled={(option.value === 'merge' || option.value === 'move') && selectedBatchIds.length <= 1}
                            />
                        ))}
                    </RadioGroup>
                    <PrimaryButton type="button" dataTestId="ApplyTabButton" label={'Apply'} height={'30px'} padding={null} onClick={handleApplyClick} />
                </FormControl>
            </Popover>
        </>
    );
};